<template>
  <label class="switch">
    <input type="checkbox" v-model="inputValue">
    <span class="slider round"></span>
</label>
</template>

<script>
export default {
  name: "toggleSwitch",
  props: ["value"],
  data(){
    return{
      inputValue: this.$props.value
    }
  },
}
</script>

<style lang="scss" scoped>
  .switch {
    position: relative;
    display: inline-block;
    width: 37px;
    height: 18px;
    input { 
        opacity: 0;
        width: 0;
        height: 0;
      }
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #23D400;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #23D400;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(17px);
    -ms-transform: translateX(17px);
    transform: translateX(17px);
  }
  /* Rounded sliders */
.slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
</style>