<template>
<transition>
  <form @submit.prevent="onsubmit" v-if="!verifyEmail.status && !verifyPhone.status">
    <h3 class="heading-3">{{ locale.WELCOME_TO_TOUCH }}</h3>
    <h4 class="heading-4">{{ locale.CREATE_ACCOUNT }}</h4>
    <p class="paragraph text-center">
      {{ locale.CREATE_ACCOUNT_SUBTITLE }}
    </p>

    <MDBCol col="12" md="10" class="m-auto mb-6">
      <MDBRow>
        <MDBCol col="12" md="6" class="pl-0">
          <label for="fname" class="form-label"> {{ locale.EXFILTERNAME }} </label>
          <MDBInput 
            id="fname" name="fname" inputGroup :formOutline="false"
            v-model="formGroup.firstName.value"
            :class="{'invalid': formGroup.firstName.hasError}"/>

          <transition name="invalid">
            <div v-if="formGroup.firstName.hasError" class=invalid-note>
              {{ locale.NAME_REQUIRED }}
            </div>
          </transition>
        </MDBCol>

        <MDBCol col="12" md="6" class="pr-0">
          <label for="lname" class="form-label">
            {{ locale.EXFILTERLASTNAME }}
          </label>
          <MDBInput
            id="lname" name="lname" inputGroup :formOutline="false"
            v-model="formGroup.surname.value"
            :class="{'invalid': formGroup.surname.hasError}"
          />

          <transition name="invalid">
            <div v-if="formGroup.surname.hasError" class=invalid-note>
              {{ locale.LASTNAME_REQUIRED }}
            </div>
          </transition>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol class="p-0">
          <label for="email" class="form-label">{{ locale.EMAIL }}</label>
          <MDBInput
            id="email" inputGroup :formOutline="false"
            v-model="formGroup.email.value"
            :class="{'invalid': formGroup.email.hasError || wrong_email_format || email_exist_error }"
            @blur="handleEmail"
          />
          <transition name="invalid">
            <div v-if="formGroup.email.hasError && wrong_email_format==false" class=invalid-note>
              {{ locale.EMAIL_REQUIRED }}
            </div>
          </transition>
          <transition name="invalid">
            <div v-if="wrong_email_format" class=invalid-note > 
              {{ locale.INVALID_EMAIL_FORMAT }}
            </div>
          </transition>
          <transition name="invalid">
            <div v-if="email_exist_error" class=invalid-note > 
              {{ locale.emailExist }}
            </div>
          </transition>

          <div class="input-note">
            {{ locale.VERIFY_EMAIL_NEXT_STEP }}
          </div>
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol class="p-0">
          <inputPhoneNumber 
            :locale="locale" :value="formGroup.phone.value" :hasError="formGroup.phone.hasError" 
            useFor="update" @inputPhoneNumber="inputPhoneNumber"
          />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol col="12" md="6" class="pl-0">
          <label for="password" class="form-label"> {{ locale.PASSWORD }} </label>
          <MDBInput
            id="password" inputGroup :formOutline="false"
            type="password" name="password" autocomplete="new-password"
            v-model="formGroup.password.value"
            :class="{'invalid': formGroup.password.hasError}"
          />

          <transition name="invalid">
            <div v-if="formGroup.password.hasError" class=invalid-note>
              {{ locale.PASSWORD_REQUIRED }}
            </div>
          </transition>
        </MDBCol>
        <MDBCol col="12" md="6" class="pr-0">
          <label for="confirm_password" class="form-label"> {{ locale.CONFIRM_PASSWORD }} </label>
          <MDBInput
            type="password" inputGroup :formOutline="false"
            id="confirm_password" name="confirm-password" autocomplete="new-password"
            v-model="formGroup.confirm_password.value"
            :class="{'invalid': formGroup.confirm_password.hasError || password_dont_match }"
          />
          <transition name="invalid">
            <div v-if="formGroup.confirm_password.hasError" class="invalid-note" >
              {{ locale.CONFIRM_PASSWORD }}
            </div>
          </transition>
          <transition name="invalid">
            <div v-if="password_dont_match" class=invalid-note > 
              {{ locale.PASSWORD_DONT_MATCH }}
            </div>
          </transition>
        </MDBCol>
      </MDBRow>

      <MDBBtn class="btn-submit btn-primary w-full" type="submit">
        {{ locale.NEXT }}
      </MDBBtn>

      <div class="or-separator">{{ locale.OR }}</div>
      <div>
        <MDBBtn v-if="redirectGoogleLogin" @click="redirectLoginWithGoogle" class="google-login-btn btn-submit btn-light w-full mt-0 shadow-none">
          <img :src="require('@/assets/icons/google.png')" alt="" width="20" class="inline-block"> 
          {{ locale.SIGNUP_WITH_GOOGLE }}</MDBBtn
          >
        <div v-else id="googleSignInBtn"></div>
        <MDBBtn @click="checkFBLoginState" class="facebok-login-btn btn-submit btn-light w-full shadow-none">
          <i class="fa-brands fa-facebook-f"></i> 
          {{ locale.SIGNUP_WITH_FACEBOOK }}
        </MDBBtn>
        <p class="text-center sign-link mt-3">
          {{ locale.HAVE_ACCOUNT }}
          <div class="link inline-block" @click="goToLogin()"><b>{{ locale.LOGIN_HERE }}</b></div>
        </p>
      </div>
    </MDBCol>
  </form>
</transition>
<transition>
  <verify-email
    v-if="verifyEmail.status" 
    :parentData="$data"
    :actionName="'verify email'"
    @back="backToSignup"
    @verifyEmail="verifyEmailCode()"
  ></verify-email>
</transition>
<transition>
  <verify-phone
    v-if="verifyPhone.status" 
    :parentData="$data"
    :actionName="'verify phone'"
    @back="backToSignup"
    @verifyPhone="verifyPhoneCode"
  ></verify-phone>
</transition>

<div v-if="verifyEmail.status || verifyPhone.status" class="flex justify-between mt-auto">
  <div class="link" v-if="!verifyPhone.status" @click="verifyEmail.status=false;">
    {{locale.GO_BACK}}
  </div>
  <div v-if="verifyPhone.status" class="link ml-auto" 
    @click="handleSkip()">
    {{locale.SKIP}}
  </div>
</div>
</template>

<script>
import verifyEmail from "./verify-email.vue";
import verifyPhone from "./verify-phone.vue";
export default {
  props: ["parentData"],
  components: {
    verifyEmail,
    verifyPhone,
  },
  data() {
    return {
      signupURLOptions: localStorage.getItem("signupOptions"),
      formGroup: {
        firstName: { value: "", required: true, hasError: false },
        surname: { value: "", required: true, hasError: false },
        email: { value: "", required: true, hasError: false },
        phone: { value: "", required: true, hasError: false },
        password: { value: "", required: true, hasError: false },
        confirm_password: { value: "", required: true, hasError: false },
      },
      emailVerificationCode: {
        value: "",
        hasError: false,
        invalidCode: false,
        resend: false,
        resendError: false,
      },
      phoneVerificationCode: {
        value: "",
        hasError: false,
        invalidCode: false,
        resend: false,
        resendError: false,
      },
      wrong_email_format: false,
      email_exist_error: false,
      password_dont_match: false,
      redirectGoogleLogin: false,
      ...this.$props["parentData"],
    };
  },
  mounted() {
    if (cookie.getCookie("TOBE_ACCEPT_PRIVACY") != "true") {
      this.navigation.currentScreen = "tegsoft-privacy";
    }
    this.initializeGoogleSignIn();
  },
  watch: {
    "emailVerificationCode.value": function () {
      this.emailVerificationCode.hasError = false;
      this.emailVerificationCode.invalidCode = false;
    },
    formGroup: {
      deep: true,
      handler(form) {
        for (let key in form) {
          if (
            this.formGroup[key].required &&
            this.formGroup[key].value !== ""
          ) {
            this.formGroup[key].hasError = false;
          }
        }
      },
    },
    "formGroup.password.value": function () {
      this.password_dont_match = false;
    },
    "formGroup.confirm_password.value": function () {
      this.password_dont_match = false;
    },
    "formGroup.email.value": function () {
      this.email_exist_error = false;
      this.wrong_email_format = false;
    },
    "phoneVerificationCode.value": function () {
      this.phoneVerificationCode.hasError = false;
      this.phoneVerificationCode.invalidCode = false;
    },
    currentScreen: function (newValue) {
      global.util.currentScreen = newValue;
    },
  },
  methods: {
    handleSkip() {
      this.verifyPhone.status = false;
      if (global.util.isNotNull(this.signupURLOptions)) {
        global.util.goToMainPage(this.signupURLOptions, "signupOptions");
        return;
      } else {
        this.navigation.currentScreen = "business-info";
      }
    },
    goToLogin() {
      if (global.util.isNotNull(this.signupURLOptions)) {
        global.util.goToMainPage(this.signupURLOptions, "signupOptions");
      }
      global.util.user = undefined;
      this.$router.push("/login");
      return;
    },
    inputPhoneNumber(inputValue) {
      this.formGroup.phone.value = inputValue;
    },
    async handleEmail() {
      if (this.formGroup.email.value == "") {
        this.formGroup.email.hasError = true;
        return;
      }
      if (global.util.isValidEmail(this.formGroup.email.value) == false) {
        this.formGroup.email.hasError = true;
        this.wrong_email_format = true;
        return;
      }
      await global.util
        .put(
          this.$tegsoftConfig.TouchSignup +
            `?email=${this.formGroup.email.value}`
        )
        .then((response) => {
          if (response.data.success == false) {
            this.formGroup.email.hasError = true;
            this.email_exist_error = true;
          }
        });
    },

    /** GOOGLE login */
    initializeGoogleSignIn() {
      if (this.signupURLOptions?.includes("callbackurl")) {
        this.redirectGoogleLogin = true;
        return;
      }
      google.accounts.id.initialize({
        client_id: global.util.decode(
          this.$tegsoftConfig.VUE_APP_GOOGLE_CLIENT_ID
        ),
        callback: this.handleCredentialResponse,
        locale: global.util.language,
      });
      google.accounts.id.renderButton(
        document.getElementById("googleSignInBtn"),
        {
          theme: "outline",
          size: "large",
          locale: global.util.language,
        }
      );
      google.accounts.id.prompt();
    },
    handleCredentialResponse(response) {
      this.loginGoogleToken(response.credential);
    },

    redirectLoginWithGoogle() {
      global.util.goToMainPage(
        this.signupURLOptions,
        "signupOptions",
        "googleLogin=true"
      );
      global.util.user = undefined;
    },
    loginGoogleToken(token) {
      this.signup.showLoader = true;
      global.util
        .post(this.$tegsoftConfig.LoginGoogle, { access_token: token })
        .then((response) => {
          this.signup.showLoader = false;
          if (response.data && response.data.success) {
            global.util.token = response.data.result.token.accesstoken;
            if (response.data.success) {
              global.util.user = response.data.result;
              global.util.token = response.data.result.token.accesstoken;
              if (response.data.result.CompanyDetails) {
                this.$router.push("/userconsole");
              } else {
                global.util.loginServiceName = response.data.serviceName;
                this.navigation.currentScreen = "business-info";
              }
            }
          }
        })
        .catch((ex) => {
          this.showLoader = false;
          console.error("loginGoogleToken() ", ex);
        });
    },
    /** GOOGLE login End */
    /** FACEBOOK login start*/
    checkFBLoginState() {
      if (this.signupURLOptions?.includes("callbackurl")) {
        global.util.goToMainPage(
          this.signupURLOptions,
          "signupOptions",
          "facebookLogin=true"
        );
        global.util.user = undefined;
        return;
      }
      let vm = this;
      // this.facebookLoginAuthError = false;

      FB.getLoginStatus(function (response) {
        if (response.authResponse !== null) {
          //Already logged in
          FB.api("/me", function (response) {});
          vm.loginFBToken(
            response.authResponse.userID,
            response.authResponse.accessToken
          );
        } else {
          vm.doFBlogin();
        }
      });
    },
    doFBlogin() {
      let vm = this;
      FB.login(function (response) {
        if (response.authResponse) {
          vm.loginFBToken(
            response.authResponse.userID,
            response.authResponse.accessToken
          );
        } else {
          vm.$router.push({
            name: "Login",
            params: { facebookLoginAuthError: "1" },
          });
        }
      });
    },
    loginFBToken(facebookdId, token) {
      this.showLoader = true;
      global.util
        .post(this.$tegsoftConfig.LoginFacebook, {
          facebookdId: facebookdId,
          access_token: token,
        })
        .then((response) => {
          this.showLoader = false;
          if (response.data && response.data.success) {
            global.util.token = response.data.result.token.accesstoken;
            const tokenDecodablePart = global.util.token.split(".")[1];
            global.util.user = response.data.result;
            if (response.data.result && response.data.result.CompanyDetails) {
              this.$router.push("/userconsole");
            } else {
              global.util.loginServiceName = response.data.serviceName;
              this.navigation.currentScreen = "business-info";
            }
          } else if (response.data.errorCode == "Auth/EX002") {
            this.$router.push({
              name: "Login",
              params: { facebookLoginAuthError: "1" },
            });
          }
        })
        .catch((ex) => {
          this.showLoader = false;
          console.error("loginFBToken() ", ex);
        });
    },
    /** FACEBOOK login end */

    verifyEmailCode() {
      this.signup.showLoader = true;
      global.util
        .get(
          this.$tegsoftConfig.TouchSignupVerifyEmailCode +
            this.emailVerificationCode.value +
            `&UID=${global.util.user.user_id}`
        )
        .then((response) => {
          this.signup.showLoader = false;
          if (response.data.success) {
            this.verifyEmail.status = false;
            this.verifyPhone.status = true;
            this.navigation.currentScreen = "verify-phone";
          } else {
            this.emailVerificationCode.hasError = true;
            this.emailVerificationCode.invalidCode = true;
          }
        });
    },
    verifyPhoneCode() {
      this.signup.showLoader = true;
      global.util
        .get(
          this.$tegsoftConfig.TouchSignupVerifyPhoneCode +
            this.phoneVerificationCode.value +
            `&phoneNumber=${encodeURIComponent(
              global.util.user.phoneNumber
            )}&UID=${global.util.user.user_id}`
        )
        .then((response) => {
          this.signup.showLoader = false;
          if (response.data.success) {
            if (global.util.isNotNull(this.signupURLOptions)) {
              global.util.goToMainPage(this.signupURLOptions, "signupOptions");
              return;
            } else {
              this.navigation.currentScreen = "business-info";
              verifyPhone.status = false;
            }
          } else {
            this.phoneVerificationCode.hasError = true;
            this.phoneVerificationCode.invalidCode = true;
          }
        });
    },

    backToSignup() {
      this.verifyEmail.status = false;
      this.navigation.currentScreen = "owner-info";
    },
    onsubmit() {
      let formIsValid = true;
      const formGroupToArray = Object.keys(this.formGroup);
      for (let key in this.formGroup) {
        //Check for empty input fields
        if (this.formGroup[key].required && this.formGroup[key].value == "") {
          this.formGroup[key].hasError = true;
          formIsValid = false;
        }
        if (
          formGroupToArray.indexOf(key) + 1 == formGroupToArray.length &&
          formIsValid
        ) {
          //Check password and confirm password
          if (
            this.formGroup.password.value !==
            this.formGroup.confirm_password.value
          ) {
            this.formGroup["confirm_password"].hasError = true;
            this.password_dont_match = true;
            formIsValid = false;
          }
          //Check email format
          if (global.util.isValidEmail(this.formGroup.email.value) == false) {
            this.formGroup.email.hasError = true;
            this.wrong_email_format = true;
            formIsValid = false;
          }
          //Check phone format
          if (!global.util.isValidPhoneNumber(this.formGroup.phone.value)) {
            this.formGroup.phone.hasError = true;
            this.wrong_phone_format = true;
            formIsValid = false;
          }
          if (formIsValid == false) {
            return false;
          }
        }
      }
      if (this.email_exist_error) {
        formIsValid = false;
      }
      if (formIsValid) {
        const jsonData = {
          name: this.formGroup.firstName.value,
          surname: this.formGroup.surname.value,
          email: this.formGroup.email.value,
          password: this.formGroup.password.value,
          phone: this.formGroup.phone.value,
        };
        this.signup.showLoader = true;

        global.util
          .post(this.$tegsoftConfig.TouchSignup, jsonData)
          .then((response) => {
            this.signup.showLoader = false;
            global.util.user = response.data.result;
            global.util.token = response.data.result.token.accesstoken;
            global.util.verifiyEmail = true;
            this.navigation.currentScreen = "verify-email";
            this.verifyEmail.status = true;
          })
          .catch((ex) => {
            console.error("DEBUG onsubmit error", ex);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>