<template>
  <div style="height: 50px; min-height: 50px">
    <MDBNavbar id="tegsoftNavbar" class="tegsoftNavbar" expand="lg" container>
      <MDBNavbarBrand class="logo" href="#">
        <img
          src="~@/assets/icons/tegsoft-touch.svg"
          alt="Tegsot touch"
          type="image/svg+xml"
        />
      </MDBNavbarBrand>
      <MDBNavbarToggler
        @click="collapseMainNav = !collapseMainNav"
        target="#navbarSupportedContent"
      ></MDBNavbarToggler>
      <MDBCollapse v-model="collapseMainNav" id="navbarSupportedContent">
        <MDBNavbarNav class="tegsoftNavbar__left mb-2 mb-lg-0">
          <MDBNavbarItem to="/dashboard">
            {{ locale.DASHBOARD }}
          </MDBNavbarItem>
          <MDBNavbarItem to="/catalog">
            {{ locale.CATALOG }}
          </MDBNavbarItem>
          <MDBNavbarItem>
            <MDBNavbarItem>
              <MDBDropdown class="nav-item" v-model="showManageAction">
                <MDBDropdownToggle
                  class="nav-link"
                  @click="showManageAction = !showManageAction"
                >
                  {{ locale.MANAGE }}
                </MDBDropdownToggle>
                <MDBDropdownMenu aria-labelledby="dropdownUserAction">
                  <MDBDropdownItem to="/salespipeline">{{
                    locale.SALESPIPELINE
                  }}</MDBDropdownItem>
                  <MDBDropdownItem to="#">{{
                    locale.CREATE_SERVER
                  }}</MDBDropdownItem>
                  <MDBDropdownItem to="#">{{
                    locale.MANAGE_LICENSE
                  }}</MDBDropdownItem>
                  <MDBDropdownItem to="#">{{
                    locale.TICKET_MANAGEMENT
                  }}</MDBDropdownItem>
                  <MDBDropdownItem to="#">{{
                    locale.VIEW_PAYMENTS
                  }}</MDBDropdownItem>
                  <MDBDropdownItem to="#">{{
                    locale.UPDATE_PAYMENTS
                  }}</MDBDropdownItem>
                  <MDBDropdownItem to="#">{{
                    locale.UPDATE_OWN_DETAILS
                  }}</MDBDropdownItem>
                  <MDBDropdownItem to="#">{{ locale.REPORTS }}</MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavbarItem>
          </MDBNavbarItem>
        </MDBNavbarNav>
        <MDBNavbarNav right class="tegsoftNavbar__right mb-2 mb-lg-0">
          <MDBNavbarItem to="#" :disabled="true">
            {{ locale.CHOOSEBUSINESS }}
          </MDBNavbarItem>
          <MDBNavbarItem to="#">
            <MDBDropdown class="nav-item" v-model="showCompnayList">
              <MDBDropdownToggle
                class="nav-link left-nav__toggle company-name"
                @click="showCompnayList = !showCompnayList"
              >
                {{ currentCompany().NAME }}
              </MDBDropdownToggle>
              <MDBDropdownMenu
                aria-labelledby="dropdownUserAction"
                class="left-nav__dropdown"
              >
                <MDBDropdownItem
                  to="#"
                  v-for="company in companyList()"
                  :key="company.COMPID"
                >
                  <div @click="selectCompany(company)">{{ company.NAME }}</div>
                </MDBDropdownItem>
                <div
                  v-if="displayCompanySearch()"
                  class="mt-4 flex justify-around"
                >
                  <MDBInput
                    inputGroup
                    :formOutline="false"
                    class="rounded search-addon"
                    v-model="searchCompany"
                    aria-describedby="search-addon"
                    aria-label="Search"
                    :placeholder="locale.SEARCH"
                  >
                  </MDBInput>
                </div>
              </MDBDropdownMenu>
            </MDBDropdown>
          </MDBNavbarItem>
          <MDBNavbarItem>
            <MDBDropdown class="nav-item" v-model="showUserAction">
              <MDBDropdownToggle
                class="nav-link left-nav__toggle usernames"
                @click="showUserAction = !showUserAction"
              >
                <div class="inline-block">
                  <img
                    ref="userImgeRef"
                    :src="user.img_path"
                    @error="displayDefaultImg"
                    class="profile-image inline-block"
                    alt=""
                  />
                </div>
                {{ user.name + " " + user.surname }}
              </MDBDropdownToggle>

              <MDBDropdownMenu
                aria-labelledby="dropdownUserAction"
                class="left-nav__toggle userActions"
              >
                <MDBDropdownItem>
                  <div
                    @click="
                      goTo({ name: 'Profile', params: { pageId: 'profile' } })
                    "
                    class="link dropdown-item"
                  >
                    {{ locale.PROFILE }}
                  </div>
                </MDBDropdownItem>
                <MDBDropdownItem>
                  <div
                    @click="
                      goTo({ name: 'Profile', params: { pageId: 'security' } })
                    "
                    class="link dropdown-item"
                  >
                    {{ locale.SECURITY }}
                  </div>
                </MDBDropdownItem>
                <MDBDropdownItem>
                  <div
                    @click="
                      goTo({
                        name: 'Profile',
                        params: { pageId: 'notification' },
                      })
                    "
                    class="link dropdown-item"
                  >
                    {{ locale.NOTIFICATIONS }}
                  </div>
                </MDBDropdownItem>
                <MDBDropdownItem>
                  <div
                    @click="
                      goTo({ name: 'Profile', params: { pageId: 'payments' } })
                    "
                    class="link dropdown-item"
                  >
                    {{ locale.PAYMENT_METHODS }}
                  </div>
                </MDBDropdownItem>
                <MDBDropdownItem>
                  <div @click="logout" class="link dropdown-item">
                    {{ locale.LOGOUT }}
                  </div>
                </MDBDropdownItem>
              </MDBDropdownMenu>
            </MDBDropdown>
          </MDBNavbarItem>
        </MDBNavbarNav>
      </MDBCollapse>
    </MDBNavbar>
  </div>
</template>

<script>
export default {
  props: ["parentData"],
  data() {
    return {
      collapseMainNav: false,
      showCompnayList: false,
      showManageAction: false,
      showUserAction: false,
      searchCompany: "",
      imageCorrected: false,
      companyListArraySize: 10,
      ...this.$props.parentData,
    };
  },
  methods: {
    currentCompany() {
      if (!global.util?.currentCompany) {
        return {};
      }
      return global.util.currentCompany;
    },
    companyList() {
      if (!global.util?.companyList) {
        return [];
      }

      if (global.util.isNull(this.searchCompany)) {
        return global.util.companyList.slice(0, this.companyListArraySize);
      }

      return global.util.companyList
        .filter((company) =>
          company.NAME.toLowerCase().includes(this.searchCompany.toLowerCase())
        )
        .slice(0, this.companyListArraySize);
    },
    displayCompanySearch() {
      if (!global.util?.companyList) {
        return false;
      }
      return global.util.companyList.length > this.companyListArraySize;
    },
    selectCompany(company) {
      global.util.currentCompany = company;
      this.closeDropdown();
      this.goTo("/dashboard");
    },
    logout() {
      global.util.touchLogout();
    },
    closeDropdown() {
      document.getElementById("tegsoftNavbar").click();
      this.searchCompany = "";
    },
    goTo(routeName) {
      this.closeDropdown();
      this.updatePageTabsIfRequired(routeName);
      if (routeName == "" || routeName == undefined) {
        return;
      }
      this.$router.push(routeName);

      return;
    },
    updatePageTabsIfRequired(routeName) {
      if (typeof routeName === "object" && routeName.name === "Profile") {
        if (routeName.name === this.$route.name) {
          this.profile.activeTabId = routeName.params.pageId;
        }
      }
    },
    displayDefaultImg(event) {
      if (!this.imageCorrected) {
        this.$refs.userImgeRef.src = require("@/assets/icons/blank.jpg");
        this.imageCorrected = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.usernames {
  text-transform: capitalize;
}
.profile-image {
  margin-right: 0.5rem;
  min-width: 20px;
  min-height: 20px;
  max-width: 40px;
  max-height: 40px;
  clip-path: circle();
}
.tegsoftNavbar {
  padding: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  background-color: var(--dark-bg);
  color: var(--color-white);
  height: 50px;
  min-height: 50px;
  a {
    color: var(--color-white);
  }
}
.left-nav {
  &__toggle {
    min-width: 200px;
  }
  &__dropdown {
    min-width: 200px;
  }
}
</style>
