<template>
  <div>
    <div class="spinningLoader-wrap h-full flex justify-center items-center">
        <div class="spinningLoader"></div>
    </div>
  </div>
</template>

<script>
export default {
    name:'spinningLoader' 
}
</script>

<style lang="css" scoped>
.spinningLoader {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
}
@-webkit-keyframes load5 {
  0%,
  100% {
    box-shadow: 0rem -2.6rem 0rem 0rem #666666, 1.8rem -1.8rem 0 0rem #fafafa, 2.5rem 0rem 0 0rem #fafafa, 1.75rem 1.75rem 0 0rem #fafafa, 0rem 2.5rem 0 0rem #fafafa, -1.8rem 1.8rem 0 0rem #fafafa, -2.6rem 0rem 0 0rem #cdcdcd, -1.8rem -1.8rem 0 0rem #9a9a9a;
  }
  12.5% {
    box-shadow: 0rem -2.6rem 0rem 0rem #9a9a9a, 1.8rem -1.8rem 0 0rem #666666, 2.5rem 0rem 0 0rem #fafafa, 1.75rem 1.75rem 0 0rem #fafafa, 0rem 2.5rem 0 0rem #fafafa, -1.8rem 1.8rem 0 0rem #fafafa, -2.6rem 0rem 0 0rem #fafafa, -1.8rem -1.8rem 0 0rem #cdcdcd;
  }
  25% {
    box-shadow: 0rem -2.6rem 0rem 0rem #cdcdcd, 1.8rem -1.8rem 0 0rem #9a9a9a, 2.5rem 0rem 0 0rem #666666, 1.75rem 1.75rem 0 0rem #fafafa, 0rem 2.5rem 0 0rem #fafafa, -1.8rem 1.8rem 0 0rem #fafafa, -2.6rem 0rem 0 0rem #fafafa, -1.8rem -1.8rem 0 0rem #fafafa;
  }
  37.5% {
    box-shadow: 0rem -2.6rem 0rem 0rem #fafafa, 1.8rem -1.8rem 0 0rem #cdcdcd, 2.5rem 0rem 0 0rem #9a9a9a, 1.75rem 1.75rem 0 0rem #666666, 0rem 2.5rem 0 0rem #fafafa, -1.8rem 1.8rem 0 0rem #fafafa, -2.6rem 0rem 0 0rem #fafafa, -1.8rem -1.8rem 0 0rem #fafafa;
  }
  50% {
    box-shadow: 0rem -2.6rem 0rem 0rem #fafafa, 1.8rem -1.8rem 0 0rem #fafafa, 2.5rem 0rem 0 0rem #cdcdcd, 1.75rem 1.75rem 0 0rem #9a9a9a, 0rem 2.5rem 0 0rem #666666, -1.8rem 1.8rem 0 0rem #fafafa, -2.6rem 0rem 0 0rem #fafafa, -1.8rem -1.8rem 0 0rem #fafafa;
  }
  62.5% {
    box-shadow: 0rem -2.6rem 0rem 0rem #fafafa, 1.8rem -1.8rem 0 0rem #fafafa, 2.5rem 0rem 0 0rem #fafafa, 1.75rem 1.75rem 0 0rem #cdcdcd, 0rem 2.5rem 0 0rem #9a9a9a, -1.8rem 1.8rem 0 0rem #666666, -2.6rem 0rem 0 0rem #fafafa, -1.8rem -1.8rem 0 0rem #fafafa;
  }
  75% {
    box-shadow: 0rem -2.6rem 0rem 0rem #fafafa, 1.8rem -1.8rem 0 0rem #fafafa, 2.5rem 0rem 0 0rem #fafafa, 1.75rem 1.75rem 0 0rem #fafafa, 0rem 2.5rem 0 0rem #cdcdcd, -1.8rem 1.8rem 0 0rem #9a9a9a, -2.6rem 0rem 0 0rem #666666, -1.8rem -1.8rem 0 0rem #fafafa;
  }
  87.5% {
    box-shadow: 0rem -2.6rem 0rem 0rem #fafafa, 1.8rem -1.8rem 0 0rem #fafafa, 2.5rem 0rem 0 0rem #fafafa, 1.75rem 1.75rem 0 0rem #fafafa, 0rem 2.5rem 0 0rem #fafafa, -1.8rem 1.8rem 0 0rem #cdcdcd, -2.6rem 0rem 0 0rem #9a9a9a, -1.8rem -1.8rem 0 0rem #666666;
  }
}
@keyframes load5 {
  0%,
  100% {
    box-shadow: 0rem -2.6rem 0rem 0rem #666666, 1.8rem -1.8rem 0 0rem #fafafa, 2.5rem 0rem 0 0rem #fafafa, 1.75rem 1.75rem 0 0rem #fafafa, 0rem 2.5rem 0 0rem #fafafa, -1.8rem 1.8rem 0 0rem #fafafa, -2.6rem 0rem 0 0rem #cdcdcd, -1.8rem -1.8rem 0 0rem #9a9a9a;
  }
  12.5% {
    box-shadow: 0rem -2.6rem 0rem 0rem #9a9a9a, 1.8rem -1.8rem 0 0rem #666666, 2.5rem 0rem 0 0rem #fafafa, 1.75rem 1.75rem 0 0rem #fafafa, 0rem 2.5rem 0 0rem #fafafa, -1.8rem 1.8rem 0 0rem #fafafa, -2.6rem 0rem 0 0rem #fafafa, -1.8rem -1.8rem 0 0rem #cdcdcd;
  }
  25% {
    box-shadow: 0rem -2.6rem 0rem 0rem #cdcdcd, 1.8rem -1.8rem 0 0rem #9a9a9a, 2.5rem 0rem 0 0rem #666666, 1.75rem 1.75rem 0 0rem #fafafa, 0rem 2.5rem 0 0rem #fafafa, -1.8rem 1.8rem 0 0rem #fafafa, -2.6rem 0rem 0 0rem #fafafa, -1.8rem -1.8rem 0 0rem #fafafa;
  }
  37.5% {
    box-shadow: 0rem -2.6rem 0rem 0rem #fafafa, 1.8rem -1.8rem 0 0rem #cdcdcd, 2.5rem 0rem 0 0rem #9a9a9a, 1.75rem 1.75rem 0 0rem #666666, 0rem 2.5rem 0 0rem #fafafa, -1.8rem 1.8rem 0 0rem #fafafa, -2.6rem 0rem 0 0rem #fafafa, -1.8rem -1.8rem 0 0rem #fafafa;
  }
  50% {
    box-shadow: 0rem -2.6rem 0rem 0rem #fafafa, 1.8rem -1.8rem 0 0rem #fafafa, 2.5rem 0rem 0 0rem #cdcdcd, 1.75rem 1.75rem 0 0rem #9a9a9a, 0rem 2.5rem 0 0rem #666666, -1.8rem 1.8rem 0 0rem #fafafa, -2.6rem 0rem 0 0rem #fafafa, -1.8rem -1.8rem 0 0rem #fafafa;
  }
  62.5% {
    box-shadow: 0rem -2.6rem 0rem 0rem #fafafa, 1.8rem -1.8rem 0 0rem #fafafa, 2.5rem 0rem 0 0rem #fafafa, 1.75rem 1.75rem 0 0rem #cdcdcd, 0rem 2.5rem 0 0rem #9a9a9a, -1.8rem 1.8rem 0 0rem #666666, -2.6rem 0rem 0 0rem #fafafa, -1.8rem -1.8rem 0 0rem #fafafa;
  }
  75% {
    box-shadow: 0rem -2.6rem 0rem 0rem #fafafa, 1.8rem -1.8rem 0 0rem #fafafa, 2.5rem 0rem 0 0rem #fafafa, 1.75rem 1.75rem 0 0rem #fafafa, 0rem 2.5rem 0 0rem #cdcdcd, -1.8rem 1.8rem 0 0rem #9a9a9a, -2.6rem 0rem 0 0rem #666666, -1.8rem -1.8rem 0 0rem #fafafa;
  }
  87.5% {
    box-shadow: 0rem -2.6rem 0rem 0rem #fafafa, 1.8rem -1.8rem 0 0rem #fafafa, 2.5rem 0rem 0 0rem #fafafa, 1.75rem 1.75rem 0 0rem #fafafa, 0rem 2.5rem 0 0rem #fafafa, -1.8rem 1.8rem 0 0rem #cdcdcd, -2.6rem 0rem 0 0rem #9a9a9a, -1.8rem -1.8rem 0 0rem #666666;
  }
}
</style>