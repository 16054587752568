<template>
  <div class="dashboard__wrapper flex flex-col justify-between">
    <MDBContainer class="dashboard">
      <div class="container tegsoft-grid">
        <MDBRow>
          <MDBCol col="12" md="6" class="tegsoft-grid__col">
            <company-channels :parentData="parentData" />
            <company-complaints :parentData="parentData" />
            <div class="tegsoft-grid-item border mt-8 p-3">
              <h4 class="font-bold">
                Easy way your customers call your business
              </h4>
              <div class="pb-3 flex justify-between items-center">
                <div>
                  Talk to your customers on your website and reach the highest
                  contact capabilities.
                </div>
                <div>
                  <img
                    :src="require('@/assets/icons/userconsole/call.png')"
                    width="40"
                  />
                </div>
              </div>
              <div class="pt-4 text-right">
                <MDBBtn class="btn-secondary">Share your business</MDBBtn>
              </div>
            </div>
            <div class="tegsoft-grid-item border p-3">
              <h4 class="font-bold">Let your customers chat with you</h4>
              <div class="pb-3 flex justify-between items-center">
                <div>
                  Talk to your customers on your website and reach the highest
                  contact capabilities.
                </div>
                <div>
                  <img
                    :src="require('@/assets/icons/userconsole/temp3.png')"
                    width="50"
                  />
                </div>
              </div>
              <div class="mt-4 flex justify-end">
                <MDBBtn class="btn-light mx-3">Learn More</MDBBtn>
                <MDBBtn class="btn-primary">Activate Web Chat</MDBBtn>
              </div>
            </div>
            <div class="tegsoft-grid-item border p-3">
              <h4 class="font-bold">Reports</h4>
              <div class="flex justify-between items-center">
                <div>
                  You can access the report details that how your customers
                  reach your business.
                </div>
                <div>
                  <img
                    :src="require('@/assets/icons/userconsole/reports.png')"
                    width="50"
                  />
                </div>
              </div>
              <div class="flex justify-end mt-4">
                <MDBBtn class="btn-secondary">Complete your profile</MDBBtn>
              </div>
            </div>
            <div class="tegsoft-grid-item border p-3">
              <h4 class="font-bold">Device states</h4>
              <div class="flex justify-between">
                <div>
                  You can access the report details that how your customers
                  reach your business.
                </div>
                <div>
                  <img
                    :src="require('@/assets/icons/userconsole/devices.png')"
                    width="50"
                  />
                </div>
              </div>
              <div class="border-bottom pb-3">
                <h4 class="pt-4 m-0 font-bold">Device 1</h4>
                <div class="flex justify-between">
                  <div>
                    <div>null.tegsoftcloud.com</div>
                    <div class="small-text">06:1f:5d:2b:7c:b9</div>
                    <div class="small-text">CLOUD</div>
                  </div>
                  <div>
                    <MDBBtn class="btn-green">PARTNERLICENSE</MDBBtn>
                  </div>
                </div>
              </div>
              <div class="border-bottom pb-3">
                <h4 class="pt-4 m-0 font-bold">Device 1</h4>
                <div class="flex justify-between">
                  <div>
                    <div>null.tegsoftcloud.com</div>
                    <div class="small-text">06:1f:5d:2b:7c:b9</div>
                    <div class="small-text">CLOUD</div>
                  </div>
                  <div>
                    <MDBBtn class="btn-green">PARTNERLICENSE</MDBBtn>
                  </div>
                </div>
              </div>
              <div class="border-bottom pb-3">
                <h4 class="pt-4 m-0 font-bold">Device 1</h4>
                <div class="flex justify-between">
                  <div>
                    <div>null.tegsoftcloud.com</div>
                    <div class="small-text">06:1f:5d:2b:7c:b9</div>
                    <div class="small-text">CLOUD</div>
                  </div>
                  <div>
                    <MDBBtn class="btn-green">PARTNERLICENSE</MDBBtn>
                  </div>
                </div>
              </div>
              <div>
                <MDBBtn class="btn-secondary">See all</MDBBtn>
              </div>
            </div>
          </MDBCol>
          <MDBCol col="12" md="6" class="tegsoft-grid__col">
            <company-reviews :parentData="parentData" />
            <div class="tegsoft-grid-item border p-3">
              <h4 class="font-bold">Reach more customers</h4>
              <div class="pb-3 flex justify-between items-center">
                <div>Share your business and get more review and touch</div>
                <div>
                  <img
                    :src="require('@/assets/icons/userconsole/temp1.png')"
                    width="40"
                  />
                </div>
              </div>
              <div class="pt-4 text-right">
                <MDBBtn class="btn-secondary">Share your business</MDBBtn>
              </div>
            </div>
            <div class="tegsoft-grid-item border p-3">
              <h4 class="font-bold">Tegsoft Touch App</h4>
              <div class="flex justify-between items-center pb-3">
                <div>
                  No more make your customers wait on phone for hours. Make your
                  visual IVR with Tegsfot Touch App
                </div>
                <div>
                  <img
                    :src="require('@/assets/icons/userconsole/temp2.png')"
                    width="50"
                  />
                </div>
              </div>
              <div class="smaller-text mt-4">Download from</div>
              <div class="flex justify-between">
                <div class="flex">
                  <div class="icon">
                    <img
                      :src="require('@/assets/icons/userconsole/playstore.png')"
                      width="50"
                    />
                    <span class="px-2">Google Play</span>
                  </div>
                  <div class="icon px-4">
                    <img
                      :src="require('@/assets/icons/userconsole/playstore.png')"
                      width="50"
                    />
                    <span class="px-2">App Store</span>
                  </div>
                </div>
                <div class="flex">
                  <MDBBtn class="btn-light mx-3">Learn More</MDBBtn>
                  <MDBBtn class="btn-primary">Start</MDBBtn>
                </div>
              </div>
            </div>
            <div class="tegsoft-grid-item border p-3">
              <h4 class="font-bold">Complete your Business Profile</h4>
              <div class="flex justify-between items-center">
                <div>
                  Get noticed by more customers in your area with a complete
                  profile.
                </div>
                <div>icon</div>
              </div>
              <div class="operations__small-text flex justify-between mt-4">
                <div>
                  <img
                    :src="require('@/assets/icons/userconsole/description.png')"
                    width="20"
                  />
                  <span>Add description</span>
                </div>
                <div>
                  <img
                    :src="require('@/assets/icons/userconsole/time.png')"
                    width="20"
                  />
                  <span>Add working hours</span>
                </div>
                <div>
                  <img
                    :src="require('@/assets/icons/userconsole/image.png')"
                    width="20"
                  />
                  <span>Add logo</span>
                </div>
                <MDBBtn class="btn-secondary">Complete your profile</MDBBtn>
              </div>
            </div>
            <div class="tegsoft-grid-item border p-3" style="height: 30rem">
              <h4 class="font-bold">
                We are glad to help you with your Tegsoft journey.
              </h4>
              <div>
                You either start your experience with our assistant or skip it.
                It&rsquo;s completly up to you.
              </div>
              <!-- <div><img :src="require('@/assets/icons/userconsole/image.png')" width="50"></div> -->
            </div>
            <div class="tegsoft-grid-item border p-3">
              <h4 class="font-bold">Payment information</h4>
              <div class="border-bottom flex justify-between">
                <div>
                  You either start your experience with our assistant or skip
                  it. It&rsquo;s completly up to you.
                </div>
                <div>icon</div>
              </div>
              <h4 class="p-3 font-bold">Device 1</h4>
              <div class="flex justify-between">
                <div>null.tegsoftcloud.com</div>
                <div>null.tegsoftcloud.com</div>
              </div>
              <div>
                <MDBBtn class="btn-secondary">See all</MDBBtn>
              </div>
            </div>
          </MDBCol>
        </MDBRow>
      </div>
    </MDBContainer>
  </div>
</template>

<script>
import companyReviews from "./companyReviews.vue";
import companyChannels from "./companyChannels.vue";
import CompanyComplaints from "./companyComplaints.vue";
export default {
  components: { companyReviews, companyChannels, CompanyComplaints },
  props: ["parentData"],
  data() {
    return {
      chatsSectionActive: true,
      ...this.$props.parentData,
    };
  },
  mounted() {},
  methods: {
    getCompanyReviews() {
      global.util
        .get(this.$tegsoftConfig.TouchCompanyReviewDetails)
        .then((response) => {
          console.log("Realtime reviewData res:", response);
          this.reviewData = response.data;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard__wrapper {
  height: 100%;
  overflow-y: auto;
}
</style>
