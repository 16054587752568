<template>
  <router-view />
</template>

<script>
export default {
  mounted() {
    window.addEventListener("beforeunload", this.deleteLocalData());
  },
  methods: {
    deleteLocalData() {
      localStorage.removeItem("signupOptions");
    },
  },
};
</script>

<style lang="scss" scoped>
</style>