import firebase from 'firebase/app'
import 'firebase/firebase-messaging'

var firebaseConfig = {
    apiKey: "AIzaSyB1sMuzKVRmoqiDC3Yae0ru5OtsgpMVoeA",
    authDomain: "tegsofttouch.firebaseapp.com",
    databaseURL: "https://tegsofttouch.firebaseio.com",
    projectId: "tegsofttouch",
    storageBucket: "tegsofttouch.appspot.com",
    messagingSenderId: "974152358637",
    appId: "1:974152358637:web:611bea724ec1e5a0448ae4",
    measurementId: "G-VJ341BJ1G3"
}

firebase.initializeApp(firebaseConfig)

export default firebase.messaging()