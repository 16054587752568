<template>
  <div class="tegsoft-privacy">
    <h1>{{ locale.PRIVACY_AND_POLICY }}</h1>
    <iframe
      id="privacyIframe"
      src="https://tegsoft.com/tegsoft-5/policy-privacy-38/"
    ></iframe>
    <div v-if="showBtns" class="flex justify-center p-4 pb-2">
      <MDBBtn
        @click="goToLogin()"
        class="btn-clean mx-3"
        style="font-size: 1.2rem"
      >
        {{ locale.DENY }}</MDBBtn
      >
      <MDBBtn @click="acceptPrivacy" class="btn-primary mx-3">
        {{ locale.ACCEPT }}</MDBBtn
      >
    </div>
  </div>
</template>

<script>
export default {
  props: ["parentData"],
  data() {
    return {
      showBtns: false,
      ...this.$props["parentData"],
    };
  },
  mounted() {
    let vm = this;
    document.getElementById("privacyIframe").onload = function () {
      vm.showBtns = true;
    };
  },
  methods: {
    goToLogin() {
      const signupURLOptions = localStorage.getItem("signupOptions");
      if (global.util.isNotNull(signupURLOptions)) {
        global.util.goToMainPage(signupURLOptions, "signupOptions");
      }
      global.util.user = undefined;
      this.$router.push("/login");
      return;
    },
    
    acceptPrivacy() {
      cookie.setCookie("TOBE_ACCEPT_PRIVACY", true);
      this.navigation.currentScreen = "owner-info";
    },
  },
};
</script>

<style lang="scss" scoped >
iframe {
  width: 100%;
  height: 68vh;
  min-height: 400px;
  @media (max-width: 767px) {
    width: 100%;
    // height:700px;
    min-height: unset;
  }
}
</style>