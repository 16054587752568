<template>
  <MDBContainer fluid class="signing-form">
    <h2 class="heading-2 heading-tegsoft-touch">
      <img
        alt="tegsoft touch"
        :src="require('@/assets/icons/tegsoft-touch.svg')"
      />
    </h2>
    <MDBRow class="signing-form__inner-box">
      <slot />
    </MDBRow>
  </MDBContainer>
</template>

<script>
export default {
  name: "signing",
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>