export const tegsoftConfig = {
    baseURL: "/",
    ServerStatus: "/Tobe/view/ServerStatus",
    PublicAuth: "/Tobe/view/Authorize",
    LoginServlet: "/Tobe/view/Login?fileName=",
    LoginServlet2: "/Tobe/view/Login?",
    LocaleServlet: "/Tobe/view/LocaleData?fileName=",
    RealTimeCampaignData: "/Tobe/view/RealTimeCampaignData",
    RealTimeAgentsData: "/Tobe/view/RealTimeAgentsData",
    RealTimeSkillsData: "/Tobe/view/RealTimeSkillsData",
    RealTimeWebchatData: "/Tobe/view/RealTimeWebchatData",
    ProjectsData: "/Tobe/view/ProjectsData?service=",
    IVRDesginer: "/Tobe/view/IVRData?IVRID=",
    Interlock: "/Tobe/view/Interlock?service=",
    ServicesServlet: "/Tobe/view/ServicesGateway?service=",
    LicenseServlet: "/Tobe/view/License?",
    DbFileServlet: "/Tobe/view/FileData?fileName=",
    ReportServlet: "/Tobe/view/Reports?fileName=",
    DMSServlet: "/Tobe/view/DMS?service=",
    PresenceServlet: "/Tobe/view/Presence?fileName=userconsole",
    UpdateDatasetServlet: "/Tobe/view/UpdateData?fileName=",
    DestinationDatasetServlet: "/Tobe/view/DestinationData?fileName=",
    RunServerMethodServlet: "/Tobe/view/RunServerMethod?fileName=",
    ExecuteCommandServlet: "/Tobe/view/ExecuteCommand?fileName=",
    DataSourceServlet: "/Tobe/view/DataSource?fileName=",
    // DataSourceServlet: "/Tobe/view/RefreshData?fileName=",
    AgentHangupWithVariables:
        "/Tobe/view/ServicesGateway?service=ContactCenterServices&method=agentHangupWithVariables",
    AgentHangupCampaignWithSurvey:
        "/Tobe/view/ServicesGateway?service=ContactCenterServices&method=agentHangupCampaignWithSurvey",
    AgentLogout:
        "/Tobe/view/ServicesGateway?service=ContactCenterServices&method=agentLogout",
    AgentCallWithVariables:
        "/Tobe/view/ServicesGateway?service=ContactCenterServices&method=agentCallWithVariables",
    AgentTransfer:
        "/Tobe/view/ServicesGateway?service=ContactCenterServices&method=agentTransfer",
    CampaignWaitingContacts: "/Tobe/view/Campaigns?service=campWaitingContacts",
    CampaignDetails: "/Tobe/view/Campaigns?service=campaignDetails",
    AddContactToCampaign:
        "/Tobe/view/Campaigns?service=AddContactToCampaign&CAMPAIGNID=",
    RealTimeEndPointData: "/Tobe/view/RealTimeEndPointData",
    RealTimeInfrastructureData: "/Tobe/view/RealTimeInfrastructureData",
    WebchatAddMessage: "/Tobe/view/Webchat?service=addWebchatMessage",
    resumeChat: "/Tobe/view/Webchat?service=resumeChat",
    createWebchatSession:
        "/Tobe/view/Webchat?service=createWebchatSession&ENGINETYPE=WEBUI&",
    createVideoSession:
        "/Tobe/view/Touch?service=createVideoSession&ENGINETYPE=WEBUI&",
    AgentSkipContact: "/Tobe/view/Webchat?service=agentSkipContact",
    checkLicenseWebChat:
        "/Tobe/view/License?service=checkLicense&licenseNames=TegsoftCC_WebChat",
    checkLicense: "/Tobe/view/License?service=checkLicense&licenseNames=",
    webchatGetLastUpdateDate:
        "/Tobe/view/Webchat?service=getLastUpdateDate&CHATID=",
    WebchatAddDocument: "/Tobe/view/Webchat?service=addDocument",
    WebchatAgentAnswer: "/Tobe/view/Webchat?service=agentAnswer&",
    WebchatAgentComplete: "/Tobe/view/Webchat?service=agentComplete&",
    WebchatAgentReject: "/Tobe/view/Webchat?service=agentReject&",
    WebchatCloseSession: "/Tobe/view/Webchat?service=closeWebchatSession",
    WebchatDownloadChatHistory: "/Tobe/view/Webchat?service=downloadChatHistory&",
    WebchatGetChatDetails: "/Tobe/view/Webchat?service=getWebchatDetails&CHATID=",
    WebchatGetAgentWritingStatus:
        "/Tobe/view/Webchat?service=getAgentWritingStatus&",
    WebchatGetContactWritingStatus:
        "/Tobe/view/Webchat?service=getContactWritingStatus&",
    WebchatGetSkills: "/Tobe/view/Webchat?service=getWebchatSkills&",
    WebchatUpdateAgentWritingStatus:
        "/Tobe/view/Webchat?service=updateAgentWritingStatus&",
    WebchatUpdateContactWritingStatus:
        "/Tobe/view/Webchat?service=updateContactWritingStatus&",
    WebchatMonitoring: "/Tobe/view/Webchat?service=getMonitorData&",
    WebchatTemplates: "/Tobe/view/Webchat?service=getTemplateMessages&",
    WebchatActiveList: "/Tobe/view/Webchat?service=getActiveWebchatList&UID=",
    AudioFileNames: "/Tobe/view/Recordings?service=getAudioFileNames&",
    DownloadAudioFile: "/Tobe/view/Recordings?service=downloadAudioFile&",
    DownloadImageFile:
        "/Tobe/view/Recordings?service=downloadSpeechAnalysisImage&",
    DownloadVideoFile:
        "/Tobe/view/Recordings?service=downloadScreenRecordingFile&",
    CRMServlet: "/Tobe/view/CRM?fileName=",
    UploadContactPhoto: "/Tobe/view/CRM?service=uploadContactImage&CONTID=",
    AccessManager: "/Tobe/view/Access?fileName=",
    MakeAgentReady: "/Tobe/view/Login?service=makeAgentReady&",
    MakeAgentNotReady: "/Tobe/view/Login?service=makeAgentNotReady&",
    MakeAgentLogout: "/Tobe/view/Login?service=makeAgentLogout&",
    Announces: "/Tobe/view/Announces?service=",
    Requirements: "/Tobe/view/Requirement?fileName=",
    RealTimeActivityData: "/Tobe/view/RealTimeActivityData?channel=ALL",
    SupportedLanguages: "/Tobe/view/Webchat?service=getSupportedLanguages",
    DetectLanguage: "/Tobe/view/Webchat?service=detectLanguage",
    Translate: "/Tobe/view/Webchat?service=translate",
    InternalCommunication: "/Tobe/view/InternalCommunication",
    SMSServlet: "/Tobe/view/SMSServices",
    Recordings: "/Tobe/view/Recordings",
    Survey: "/Tobe/view/Survey",
    ConferenceManager: "/Tobe/view/AgentConferenceManager",
    Webchat: "/Tobe/view/Webchat",
    Campaigns: "/Tobe/view/Campaigns",
    User: "/Tobe/view/User",
    DynamicReports: "/Tobe/view/DynamicReports",

    //TEGSOFT TOUCH WEB
    TouchPublicAuth:
        "https://api.tegsoft.com/TegsoftTouch/v1/touch/AuthenticationServicesPublicTokenGenerator",
    TouchLocale: "https://api.tegsoft.com/TegsoftTouch/v1/touch/Locale",
    TouchLogin: "https://api.tegsoft.com/TegsoftTouch/v1/touch/Login",
    LoginGoogle: "https://api.tegsoft.com/TegsoftTouch/v1/touch/LoginGoogle",
    LoginFacebook: "https://api.tegsoft.com/TegsoftTouch/v1/touch/LoginFacebook",
    TouchForgotPassword:
        "https://api.tegsoft.com/TegsoftTouch/v1/touch/ForgotPassword",
    TouchSignup: "https://api.tegsoft.com/TegsoftTouch/v1/touch/Signup",
    TouchLanguages: "https://api.tegsoft.com/TegsoftTouch/v1/touch/Languages",
    TouchResetPassword:
        "https://api.tegsoft.com/TegsoftTouch/v1/touch/ResetPassword",
    TouchChangePassword:
        "https://api.tegsoft.com/TegsoftTouch/v1/touch/ChangePassword",
    TouchSignupVerifyEmailCode:
        "https://api.tegsoft.com/TegsoftTouch/v1/touch/Verify?service=verifyEmailCode&code=",
    TouchSignupVerifyEmail:
        "https://api.tegsoft.com/TegsoftTouch/v1/touch/Verify?service=verifyEmail&",
    TouchSignupResendEmailCode:
        "https://api.tegsoft.com/TegsoftTouch/v1/touch/Verify?service=verifyEmail&resend=true&UID=",
    TouchSignupVerifyPhoneCode:
        "https://api.tegsoft.com/TegsoftTouch/v1/touch/Verify?service=verifyPhoneNumber&code=",
    TouchSignupResendPhoneCode:
        "https://api.tegsoft.com/TegsoftTouch/v1/touch/Verify?service=verifyPhoneNumber&resend=true&phoneNumber=",
    TouchCreateBusiness:
        "https://companyserviceapi.tegsoft.com/TegsoftTouch/v1/touch/Business",
    TouchBusinessVerification:
        "https://companyserviceapi.tegsoft.com/TegsoftTouch/v1/touch/BusinessVerification?service=",
    TouchGenerateVerificationKeyDNS:
        "https://companyserviceapi.tegsoft.com/TegsoftTouch/v1/touch/BusinessVerification?service=generateVerificationKeyDNS&domainName=",
    TouchGenerateVerificationKeyHTML:
        "https://companyserviceapi.tegsoft.com/TegsoftTouch/v1/touch/BusinessVerification?service=generateVerificationKeyHTML&domainName=",
    TouchGenerateVerificationKeyEMAIL:
        "https://companyserviceapi.tegsoft.com/TegsoftTouch/v1/touch/BusinessVerification?service=generateVerificationKeyEMAIL&domainName=",
    TouchVerifyDNS:
        "https://companyserviceapi.tegsoft.com/TegsoftTouch/v1/touch/BusinessVerification?service=verifyDNS&domainName=",
    TouchVerifyHTML:
        "https://companyserviceapi.tegsoft.com/TegsoftTouch/v1/touch/BusinessVerification?service=verifyHTML&domainName=",
    TouchVerifyEMAIL:
        "https://companyserviceapi.tegsoft.com/TegsoftTouch/v1/touch/BusinessVerification?service=verifyEmail&domainName=",
    TouchCheckDomainName:
        "https://companyserviceapi.tegsoft.com/TegsoftTouch/v1/touch/Business?service=checkDomainName&websiteURL=",
    TouchCheckBusinessName:
        "https://companyserviceapi.tegsoft.com/TegsoftTouch/v1/touch/Business?service=checkCompanyName&companyName=",
    TouchCompanyCategories:
        "https://companyserviceapi.tegsoft.com/TegsoftTouch/v1/touch/Categories",
    TouchSearhCompanyName:
        "https://companyserviceapi.tegsoft.com/TegsoftTouch/v1/touch/Companies?search=",
    TouchCompanyList:
        "https://companyserviceapi.tegsoft.com/TegsoftTouch/v1/touch/Companies",
    TouchCompanyReviews:
        "https://companyserviceapi.tegsoft.com/TegsoftTouch/v1/touch/CompanyReviewDetails?COMPID=",
    TouchCompanyLatestReviews:
        "https://companyserviceapi.tegsoft.com/TegsoftTouch/v1/touch/Reviews?userId=",
    TouchCompanyChannels:
        "https://companyserviceapi.tegsoft.com/TegsoftTouch/v1/touch/RealTimeData?service=WebchatData&COMPID=",
    TouchNotificationPreference:
        "https://userserviceapi.tegsoft.com/TegsoftTouch/v1/touch/NotificationService?service=queryNotificationPreference",
    TouchUpdateNotificationPreference:
        "https://userserviceapi.tegsoft.com/TegsoftTouch/v1/touch/NotificationService?service=updateNotificationPreference&COMPID=",
    TouchRegisterFCMToken:
        "https://userserviceapi.tegsoft.com/TegsoftTouch/v1/touch/NotificationService?service=register",
    TouchUser:
        "https://userserviceapi.tegsoft.com/TegsoftTouch/v1/touch/User?userId=",
    TouchUpadateUser:
        "https://userserviceapi.tegsoft.com/TegsoftTouch/v1/touch/User",
    TouchProfilePhoto:
        "https://userserviceapi.tegsoft.com/TegsoftTouch/v1/touch/ProfilePhoto?userId=",
    TouchTelescopeServers:
        "https://userserviceapi.tegsoft.com/TegsoftTouch/v1/touch/Telescope",
    proxyHostUrl:
        "https://ivrserviceapi.tegsoft.com/TegsoftTouch/v1/touch/ProxyServices?url=",
    TouchCompCreateUser:
        "https://userserviceapi.tegsoft.com/TegsoftTouch/v1/touch/UserManagement?service=createUser&currentCOMPID=",
    TouchCompListUsers:
        "https://userserviceapi.tegsoft.com/TegsoftTouch/v1/touch/UserManagement?service=listUsers&currentCOMPID=",
    TouchCompUpdateUser:
        "https://userserviceapi.tegsoft.com/TegsoftTouch/v1/touch/UserManagement?service=updateUser&currentCOMPID=",
    TouchCompGetUser:
        "https://userserviceapi.tegsoft.com/TegsoftTouch/v1/touch/UserManagement?service=getUser&currentCOMPID=",
    TouchCompDeleteUser:
        "https://userserviceapi.tegsoft.com/TegsoftTouch/v1/touch/UserManagement?service=deleteUser&currentCOMPID=",
    TouchCreatePlatform:
        "https://userserviceapi.tegsoft.com/TegsoftTouch/v1/touch/PlatformManagement?service=createPlatform&userCOMPID=",
    TouchGetPlatform:
        "https://userserviceapi.tegsoft.com/TegsoftTouch/v1/touch/PlatformManagement?service=getPlatform&serverId=",
    TouchPlatformList:
        "https://userserviceapi.tegsoft.com/TegsoftTouch/v1/touch/PlatformManagement?service=listPlatforms&currentCOMPID=",
    TouchUpdatePlatform:
        "https://userserviceapi.tegsoft.com/TegsoftTouch/v1/touch/PlatformManagement?service=updatePlatform&serverId=",
    TouchDeletePlatform:
        "https://userserviceapi.tegsoft.com/TegsoftTouch/v1/touch/PlatformManagement?service=deletePlatform&serverId=",
    TouchGetPlatformTypes:
        "https://userserviceapi.tegsoft.com/TegsoftTouch/v1/touch/PlatformManagement?service=getPlatformTypes",
    TouchGetServerTypes:
        "https://userserviceapi.tegsoft.com/TegsoftTouch/v1/touch/PlatformManagement?service=getServerTypes",
    TouchGetPartnerList:
        "https://userserviceapi.tegsoft.com/TegsoftTouch/v1/touch/PlatformManagement?service=getPartnerList&COMPID=",
    TouchGetSalesPartnerList:
        "https://userserviceapi.tegsoft.com/TegsoftTouch/v1/touch/PlatformManagement?service=getSalesPartnerList&COMPID=",
    TouchCreateLicense:
        "https://userserviceapi.tegsoft.com/TegsoftTouch/v1/touch/PlatformManagement?service=addLicence&serverId=",
    TouchGetLicenseList:
        "https://userserviceapi.tegsoft.com/TegsoftTouch/v1/touch/PlatformManagement?service=listLicences&userCOMPID=",
    TouchDeleteLicense:
        "https://userserviceapi.tegsoft.com/TegsoftTouch/v1/touch/PlatformManagement?service=deleteLicence&serverId=",
    TouchUpdateLicense:
        "https://userserviceapi.tegsoft.com/TegsoftTouch/v1/touch/PlatformManagement?service=updateLicence&serverId=",
    TouchTelescopeServerList:
        "https://userserviceapi.tegsoft.com/TegsoftTouch/v1/touch/Telescope",
    TouchSalesPipelineListDeals:
        "https://companyserviceapi.tegsoft.com/TegsoftTouch/v1/touch/SalesPipeline?service=listDeals",
    TouchSalesPipelineSaveLead:
        "https://companyserviceapi.tegsoft.com/TegsoftTouch/v1/touch/SalesPipeline?service=saveLead",
    TouchSalesPipelineListPartners:
        "https://companyserviceapi.tegsoft.com/TegsoftTouch/v1/touch/SalesPipeline?service=listPartners",
    TouchSalesPipelineListProducts:
        "https://companyserviceapi.tegsoft.com/TegsoftTouch/v1/touch/SalesPipeline?service=listProducts",
    TouchSalesPipelineListCompanies:
        "https://companyserviceapi.tegsoft.com/TegsoftTouch/v1/touch/SalesPipeline?service=listCompanies",
    TouchSalesPipelineListDealOwners:
        "https://companyserviceapi.tegsoft.com/TegsoftTouch/v1/touch/SalesPipeline?service=listDealOwners",
    TouchSalesPipelineListCustomers:
        "https://companyserviceapi.tegsoft.com/TegsoftTouch/v1/touch/SalesPipeline?service=listCustomers",
    TouchSalesPipelineListContacts:
        "https://companyserviceapi.tegsoft.com/TegsoftTouch/v1/touch/SalesPipeline?service=listContacts",
    TouchSalesPipelineListLeads:
        "https://companyserviceapi.tegsoft.com/TegsoftTouch/v1/touch/SalesPipeline?service=listLeads",
    TouchSalesPipelineGetDealDetails:
        "https://companyserviceapi.tegsoft.com/TegsoftTouch/v1/touch/SalesPipeline?service=getDealDetails&DEALID=",
    TouchSalesPipelineRegisterDeal:
        "https://companyserviceapi.tegsoft.com/TegsoftTouch/v1/touch/SalesPipeline?DEALID=",
    TouchSalesPipelineUpdateDeal:
        "https://companyserviceapi.tegsoft.com/TegsoftTouch/v1/touch/SalesPipeline?service=updateDeal&DEALID=",
    TouchSalesPipelineDeleteDeal:
        "https://companyserviceapi.tegsoft.com/TegsoftTouch/v1/touch/SalesPipeline?DEALID=",
    TouchSalesPipelineListActiveRegistrations:
        "https://companyserviceapi.tegsoft.com/TegsoftTouch/v1/touch/SalesPipeline?service=listActiveRegistrations",
    TouchSalesPipelineService:
        "https://companyserviceapi.tegsoft.com/TegsoftTouch/v1/touch/SalesPipeline?service=",
    TouchSalesPipelineGetCompanyDetails:
        "https://companyserviceapi.tegsoft.com/TegsoftTouch/v1/touch/CompanyDetails?service=getCompanyDetails&COMPID=",
    TouchSalesPipelineUpdateCompanyDetails:
        "https://companyserviceapi.tegsoft.com/TegsoftTouch/v1/touch/CompanyDetails?service=updateCompanyDetails&COMPID=",
    TouchCompanyLogo:
        "https://companyserviceapi.tegsoft.com/TegsoftTouch/v1/touch/CompanyLogo",
    TouchCompanyFiles:
        "https://companyserviceapi.tegsoft.com/TegsoftTouch/v1/touch/CompanyFiles",
    TouchCompanyActivitiesGetActivityList:
        "https://companyserviceapi.tegsoft.com/TegsoftTouch/v1/touch/CompanyActivities?service=getActivityList",
    TouchCompanyActivitiesGetActivityDetails:
        "https://companyserviceapi.tegsoft.com/TegsoftTouch/v1/touch/CompanyActivities?service=getActivityDetails&COMPID=",
    TouchCompanyActivitiesGetActivityTypeList:
        "https://companyserviceapi.tegsoft.com/TegsoftTouch/v1/touch/CompanyActivities?service=getActivityTypeList&GROUPID=",
    TouchCompanyActivitiesGetActivityGroupList:
        "https://companyserviceapi.tegsoft.com/TegsoftTouch/v1/touch/CompanyActivities?service=getActivityGroupList&TOPGROUPID=",
    TouchCompanyActivitiesGetActivityTopGroupList:
        "https://companyserviceapi.tegsoft.com/TegsoftTouch/v1/touch/CompanyActivities?service=getActivityTopGroupList",

    TouchCompanyActivitiesUpdateActivity:
        "https://companyserviceapi.tegsoft.com/TegsoftTouch/v1/touch/CompanyActivities?service=updateActivity&ACTID=",
    TouchCompanyActivitiesUpdateActivityType:
        "https://companyserviceapi.tegsoft.com/TegsoftTouch/v1/touch/CompanyActivities?service=updateActivityType&TYPEID=",
    TouchCompanyActivitiesUpdateActivityGroup:
        "https://companyserviceapi.tegsoft.com/TegsoftTouch/v1/touch/CompanyActivities?service=updateActivityGroup&GROUPID=",
    TouchCompanyActivitiesUpdateActivityTopGroup:
        "https://companyserviceapi.tegsoft.com/TegsoftTouch/v1/touch/CompanyActivities?service=updateActivityTopGroup&TOPGROUPID=",

    TouchCompanyActivitiesRegisterActivity:
        "https://companyserviceapi.tegsoft.com/TegsoftTouch/v1/touch/CompanyActivities?service=registerActivity",
    TouchCompanyActivitiesRegisterActivityType:
        "https://companyserviceapi.tegsoft.com/TegsoftTouch/v1/touch/CompanyActivities?service=registerActivityType",
    TouchCompanyActivitiesRegisterActivityGroup:
        "https://companyserviceapi.tegsoft.com/TegsoftTouch/v1/touch/CompanyActivities?service=registerActivityGroup",
    TouchCompanyActivitiesRegisterActivityTopGroup:
        "https://companyserviceapi.tegsoft.com/TegsoftTouch/v1/touch/CompanyActivities?service=registerActivityTopGroup",

    TEGSOFT_THEME: "/Tobe/image/themes/flat2/",
    TEGSOFT_VUE_USERAGENT:
        "Tegsoft VUE Client " + process.env.npm_package_version,
    TEGSOFT_VUE_CLIENTKEY:
        "VUEClientKey" + navigator.userAgent + " " + navigator.language,
    TEGSOFT_VUE_APPLICATIONKEY: "TegsoftAPPVUE20211112",

    TEGSOFT_TOUCH_USERAGENT:
        "TegsoftTouchMobile/1.0 (IOS; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) ",
    TEGSOFT_TOUCH_CLIENTKEY: "myuuid",
    TEGSOFT_TOUCH_APPLICATIONKEY: "12e12d122312sdsd123",

    TEGSOFT_FIREBASE_APIKEY:
        "QUl6YVN5QjFzTXV6S1ZSbW9xaURDM1lhZTBydTVPdHNncE1Wb2VB",
    TEGSOFT_FIREBASE_APPID:
        "MTo5NzQxNTIzNTg2Mzc6d2ViOjYxMWJlYTcyNGVjMWU1YTA0NDhhZTQ=",
    TEGSOFT_FIREBASE_MESSAGING_SENDER_ID: "OTc0MTUyMzU4NjM3",
    TEGSOFT_FIREBASE_MEASUREMENT_ID: "Ry1WSjM0MUJKMUcz",

    VUE_APP_FACEBOOK_APP_ID: "Njc4MDU0OTEzMDMxMzY5",
    VUE_APP_GOOGLE_CLIENT_ID:
        "OTk4ODc5MTgwMzY2LTZndTUwdWd2M2YxOWdwdDlwNDNiZm1qZTZsdDVtanV1LmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29t",
    VUE_APP_FIREBASE_VAPIDKEY:
        "aHR0cHM6Ly9jb25zb2xlLmZpcmViYXNlLmdvb2dsZS5jb20vdS8wL3Byb2plY3QvdGVnc29mdHRvdWNoL3NldHRpbmdzL2dlbmVyYWwvd2ViOk9ESmlOVEpsTnpBdE1qVTNOaTAwTm1Fd0xUaGpPV1F0TTJNNU5UVTRabVk1TkdNMw==" /**Encoded */,
};
export default tegsoftConfig;
