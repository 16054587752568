<template>
  <MDBSideNav
    class="tegsoftSidebarMenu"
    v-model="sidenavSlim"
    :slim="collapseSidenav"
    :slimCollapsed="true"
    :backdrop="false"
    id="sidenavSlim"
  >
    <MDBSideNavMenu accordion>
      <MDBSideNavItem class="mt-2 mb-0">
        <MDBBtn
          @click.prevent="onCollapseButtonClicked"
          class="w-100"
          style="text-align: left"
        >
          <span class="text-truncate w-100">
            <MDBIcon fas icon="bars" />
          </span>
        </MDBBtn>
      </MDBSideNavItem>
    </MDBSideNavMenu>
    <hr class="mt-2 mb-0" />
    <MDBSideNavMenu accordion>
      <MDBSideNavItem>
        <MDBSideNavLink :ripple="{ color: '#F6911E' }">
          <img
            src="~@/assets/icons/sidebar/phone.svg"
            alt="phone"
            type="image/svg+xml"
          />
          <span class="sidenav-non-slim">{{ locale.TEGSOFTTOUCHAPP }}</span>
        </MDBSideNavLink>
      </MDBSideNavItem>
      <MDBSideNavItem>
        <MDBSideNavLink :ripple="{ color: '#F6911E' }">
          <img
            src="~@/assets/icons/sidebar/phone-num.svg"
            alt=""
            type="image/svg+xml"
          />
          <span class="sidenav-non-slim"> {{ locale.NUMBERS }} </span>
        </MDBSideNavLink>
      </MDBSideNavItem>
      <MDBSideNavItem>
        <MDBSideNavLink :ripple="{ color: '#F6911E' }">
          <img
            src="~@/assets/icons/sidebar/customers.png"
            alt=""
            type="image/png"
          />
          <span class="sidenav-non-slim"> {{ locale.CUSTOMERS }} </span>
        </MDBSideNavLink>
      </MDBSideNavItem>
      <MDBSideNavItem>
        <MDBSideNavLink :ripple="{ color: '#F6911E' }">
          <img
            src="~@/assets/icons/sidebar/webchat.svg"
            alt=""
            type="image/svg+xml"
          />
          <span class="sidenav-non-slim"> {{ locale.WEBCHAT }} </span>
        </MDBSideNavLink>
      </MDBSideNavItem>
      <MDBSideNavItem>
        <MDBSideNavLink :ripple="{ color: '#F6911E' }">
          <img
            src="~@/assets/icons/sidebar/fb.svg"
            alt=""
            type="image/svg+xml"
          />
          <span class="sidenav-non-slim"> Facebook </span>
        </MDBSideNavLink>
      </MDBSideNavItem>
      <MDBSideNavItem>
        <MDBSideNavLink :ripple="{ color: '#F6911E' }">
          <img
            src="~@/assets/icons/sidebar/instagram.svg"
            alt=""
            type="image/svg+xml"
          />
          <span class="sidenav-non-slim"> Instagram </span>
        </MDBSideNavLink>
      </MDBSideNavItem>
      <MDBSideNavItem @click="openPage('whatsapp')">
        <MDBSideNavLink :ripple="{ color: '#F6911E' }">
          <img
            src="~@/assets/icons/sidebar/whatsapp.svg"
            alt=""
            type="image/svg+xml"
          />
          <span class="sidenav-non-slim"> Whatsapp </span>
        </MDBSideNavLink>
      </MDBSideNavItem>
      <MDBSideNavItem>
        <MDBSideNavLink :ripple="{ color: '#F6911E' }">
          <img
            src="~@/assets/icons/sidebar/chatbot.svg"
            alt=""
            type="image/svg+xml"
          />
          <span class="sidenav-non-slim"> Chatbot </span>
        </MDBSideNavLink>
      </MDBSideNavItem>
      <MDBSideNavItem>
        <MDBSideNavLink :ripple="{ color: '#F6911E' }">
          <img
            src="~@/assets/icons/sidebar/complaints.svg"
            alt=""
            type="image/svg+xml"
          />
          <span class="sidenav-non-slim"> {{ locale.MANAGECOMPLAINTS }} </span>
        </MDBSideNavLink>
      </MDBSideNavItem>
      <MDBSideNavItem>
        <MDBSideNavLink :ripple="{ color: '#F6911E' }">
          <img
            src="~@/assets/icons/sidebar/form.svg"
            alt=""
            type="image/svg+xml"
          />
          <span class="sidenav-non-slim"> {{ locale.CONTACTFORM }} </span>
        </MDBSideNavLink>
      </MDBSideNavItem>
      <MDBSideNavItem>
        <MDBSideNavLink :ripple="{ color: '#F6911E' }">
          <img
            src="~@/assets/icons/sidebar/form.svg"
            alt=""
            type="image/svg+xml"
          />
          <span class="sidenav-non-slim"> {{ locale.OPTIONS }} </span>
        </MDBSideNavLink>
      </MDBSideNavItem>
      <MDBSideNavItem>
        <MDBSideNavLink :ripple="{ color: '#F6911E' }">
          <img
            src="~@/assets/icons/sidebar/form.svg"
            alt=""
            type="image/svg+xml"
          />
          <span class="sidenav-non-slim"> {{ locale.KNOWLEDGEBASE }} </span>
        </MDBSideNavLink>
      </MDBSideNavItem>
    </MDBSideNavMenu>
    <hr />
    <MDBSideNavMenu accordion>
      <MDBSideNavItem>
        <MDBSideNavLink
          :to="{ path: 'platforms' }"
          :ripple="{ color: '#F6911E' }"
          @click="listPlatforms()"
        >
          <img src="~@/assets/icons/sidebar/platform.png" alt="" />
          <span class="sidenav-non-slim"> {{ locale.PLATFORMS }}</span>
        </MDBSideNavLink>
      </MDBSideNavItem>
      <MDBSideNavItem>
        <MDBSideNavLink :ripple="{ color: '#F6911E' }">
          <img
            src="~@/assets/icons/sidebar/business.svg"
            alt=""
            type="image/svg+xml"
          />
          <span class="sidenav-non-slim"> {{ locale.BUSINESSES }}</span>
        </MDBSideNavLink>
      </MDBSideNavItem>
      <MDBSideNavItem>
        <MDBSideNavLink
          :to="{ path: 'users' }"
          :ripple="{ color: '#F6911E' }"
          @click="listUsers()"
        >
          <img src="~@/assets/icons/sidebar/users-24px.png" alt="" />
          <span class="sidenav-non-slim"> {{ locale.USERS }}</span>
        </MDBSideNavLink>
      </MDBSideNavItem>
    </MDBSideNavMenu>
    <hr />
    <MDBSideNavMenu accordion>
      <MDBSideNavItem>
        <MDBSideNavLink :ripple="{ color: '#F6911E' }">
          <img
            src="~@/assets/icons/sidebar/settings.svg"
            alt=""
            type="image/svg+xml"
          />
          <span class="sidenav-non-slim"> {{ locale.SETTINGS }}</span>
        </MDBSideNavLink>
      </MDBSideNavItem>
      <MDBSideNavItem>
        <MDBSideNavLink :ripple="{ color: '#F6911E' }">
          <img
            src="~@/assets/icons/sidebar/support.svg"
            alt=""
            type="image/svg+xml"
          />
          <span class="sidenav-non-slim"> {{ locale.SUPPORT }}</span>
        </MDBSideNavLink>
      </MDBSideNavItem>
    </MDBSideNavMenu>
  </MDBSideNav>
</template>

<script>
import { ref, computed } from "vue";
export default {
  name: "tegsoftSidebarMenu",
  props: ["parentData"],
  components: {},

  data() {
    return {
      ...this.$props.parentData,
      screenWidth: window.innerWidth,
      slim1: true,
      sidenavSlim: true,
      collapseSidenav: true,
      cookieInstance: null,
    };
  },

  computed: {
    currentCompany() {
      return global.util.currentCompany ?? {};
    },
  },

  methods: {
    listPlatforms() {
      if (global.util.platformList?.platformActions?.actionName) {
        global.util.platformList.platformActions.actionName = "list";
      }
    },
    listUsers() {
      if (global.util.userForm?.usersProperty) {
        global.util.userForm.usersProperty.layoutType = "list";
      }
    },
    openPage(pageName) {
      this.$router.push("/" + pageName);
    },
    onResize(e) {
      switch (window.orientation) {
        case -90 || 90:
          // landscape
          this.mobile = false;
          break;
        default:
          // portrait
          this.mobile = true;
          break;
      }
    },
    onCollapseButtonClicked() {
      this.collapseSidenav = !this.collapseSidenav;

      this.cookieInstance.setCookie(
        "CONSOLE_SIDENAV_STATE",
        this.collapseSidenav
      );
    },
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);

      if (cookie != undefined) this.cookieInstance = cookie;

      this.collapseSidenav =
        this.cookieInstance.getCookie("CONSOLE_SIDENAV_STATE") == "true";
    });
  },
};
</script>

<style lang="scss">
.tegsoftSidebarMenu {
  background-color: #ffffff;
  height: 100% !important;

  transition: all 0.15s ease-in-out !important;

  position: relative !important;

  z-index: 99 !important;

  .ps {
    height: 100% !important;
  }

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &.sidenav-slim-collapsed {
    width: 46px !important;
    min-width: 46px;

    overflow-y: hidden;
  }

  img {
    margin-right: 1.3rem;
  }
}
</style>
