<template>
  <div class="tegsoft-grid-item border">
    <h4 class="p-3 font-bold">{{ locale.NPSSCORE_AND_REVIEWS }}</h4>
    <div
      v-if="companyReviews && companyReviews.length > 0 && latestReviewData"
      class="my-3 p-2 px-4"
    >
      <div class="flex">
        <div class="user-image">
          <img :src="latestReviewData.user_img_path" />
        </div>
        <div class="user-comment flex-1 px-4">
          <h5>{{ latestReviewData.by_who }}</h5>
          <div>
            {{ latestReviewData.review }}
          </div>
        </div>
        <div class="score-fraction px-3">
          {{ latestReviewData.rating }} / 10
        </div>
      </div>
      <div class="user-action-time text-right px-4">
        {{ latestReviewData.timeAgo.time }}
        {{ locale[latestReviewData.timeAgo.timeName] }}
      </div>
      <div class="flex justify-end px-2">
        <MDBBtn class="btn-light py-1 mx-2">{{ locale.REPLY }}</MDBBtn>
        <MDBBtn color="primary py-1 mx-2">{{ locale.SEE_ALL }}</MDBBtn>
      </div>
    </div>
    <div v-else>
      <div class="p-3">
        {{ locale.NO_COMMENT_YET }}
      </div>
    </div>
    <MDBRow class="border-top mt-4 p-4 flex items-center">
      <MDBCol col="3">
        <h5>{{ locale.NPSSCORE }}</h5>
        <div class="score-percent">0%</div>
        <div>0 {{ locale.REVIEWS }}</div>
      </MDBCol>
      <MDBCol col="9">
        <div class="flex items-center">
          <div class="text-left" style="width: 95px">{{ locale.PROMOTER }}</div>
          <div class="flex-1 mx-3 relative">
            <div class="score-rate-bar"></div>
          </div>
          <div>0</div>
        </div>
        <div class="flex items-center">
          <div class="text-left" style="width: 95px">{{ locale.PASSIVE }}</div>
          <div class="flex-1 mx-3 relative">
            <div class="score-rate-bar"></div>
          </div>
          <div>0</div>
        </div>
        <div class="flex items-center">
          <div class="text-left" style="width: 95px">
            {{ locale.DETRACTOR }}
          </div>
          <div class="flex-1 mx-3 relative">
            <div class="score-rate-bar"></div>
          </div>
          <div>0</div>
        </div>
      </MDBCol>
    </MDBRow>
  </div>
</template>

<script>
export default {
  name: "company-reviews",
  props: ["parentData"],
  data() {
    return {
      companyReviews: null,
      latestReviewData: null,
      ...this.$props.parentData,
    };
  },
  mounted() {
    this.getCompanyReviews();
  },
  methods: {
    promoter() {
      this.latestReviewData.rating;
    },
    passive() {},
    detractor() {},
    sortReviewsAscending() {
      const sortResults = this.companyReviews.sort(function (a, b) {
        let dateA = new Date(a.comment_date).getTime();
        let dateB = new Date(b.comment_date).getTime();
        return dateA < dateB ? 1 : dateA > dateB ? -1 : 0; // ? -1 : 1 for ascending/increasing order
      });
      this.companyReviews = sortResults;
    },
    getCompanyReviews() {
      if (global.util.isNull(global.util.currentCompany)) {
        return;
      }
      global.util
        .get(
          this.$tegsoftConfig.TouchCompanyReviews +
            global.util.currentCompany.COMPID
        )
        .then((response) => {
          if (response.data.result) {
            const results = response.data.result.reviews;
            this.companyReviews = results.map((review) => {
              review.timeAgo = global.util.relativeTime(review.comment_date);
              return review;
            });
            this.sortReviewsAscending();
            this.latestReviewData = this.companyReviews[6];
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>