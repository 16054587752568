<template>
  <div class="signup-language">
    <h4 class="heading-4">{{ locale.LANGUAGE_TITLE }}</h4>
    <p class="paragraph">{{ locale.LANGUAGE_SUBTITLE }}</p>
    <MDBCol md="7" class="m-auto mb-8">
      <div>
        <label for="language" class="form-label">
          {{ locale.LANGUAGE_OPTION }}
        </label>
        <combobox
          v-model="selectedLanguage"
          :options="languageOptions"
          value-field="LOCALE"
          text-field="LOCALENAME"
          :clearButton="false"
          class="error"
        />
        <input-error-message
          v-if="showError"
          :message="locale.LANGUAGE_OPTION"
        />
      </div>
      <div class="text-right mt-4">
        <!-- <MDBBtn :disabled="disableNextButton" color="primary" 
              @click="navigation.currentScreen='owner-info'">{{ locale.NEXT }}</MDBBtn> -->
        <MDBBtn
          :disabled="disableNextButton"
          color="primary"
          @click="navigation.currentScreen = 'tegsoft-privacy'"
          >{{ locale.NEXT }}</MDBBtn
        >
      </div>
    </MDBCol>
    <MDBCol md="7" class="m-auto text-left">
      <a @click="goToLogin()" class="text-link">
        {{ locale.BACK_TO_LOGIN }}
      </a>
    </MDBCol>
  </div>
</template>

<script>
export default {
  props: ["parentData"],
  emits: ["changeScreen"],
  data() {
    return {
      showError: false,
      disableNextButton: false,
      selectedLanguage: "",
      // languageOptions: [
      //   { CODE: "en", VALUE: "English" },
      //   { CODE: "tr", VALUE: "Türkçe" },
      // ],
      languageOptions: [],
      ...this.$props["parentData"],
    };
  },
  mounted() {
    this.selectedLanguage = global.util.language;
    this.setLanguages();
  },
  watch: {
    selectedLanguage: function () {
      this.showError = false;
      this.setLocale(this);
    },
  },
  methods: {
    goToLogin() {
      const signupURLOptions = localStorage.getItem("signupOptions");
      if (global.util.isNotNull(signupURLOptions)) {
        global.util.goToMainPage(signupURLOptions, "signupOptions");
      }
      global.util.user = undefined;
      this.$router.push("/login");
      return;
    },
    setLanguages() {
      this.$nextTick(() => {
        if (typeof global.util.publicToken != "undefined") {
          this.getLanguages();
        } else {
          let timeRuns = 0;
          let waitForToken = setInterval(() => {
            if (this.languageOptions.length > 0) {
              clearInterval(waitForToken);
              return;
            } else {
              this.getLanguages();
            }
            if (timeRuns === 10) {
              clearInterval(waitForToken);
              console.error(
                "Server took too long to load. Can't load languages"
              );
            }
          }, 3000);
        }
      });
    },
    getLanguages() {
      global.util.get(this.$tegsoftConfig.TouchLanguages).then((response) => {
        this.disableNextButton = false;
        this.languageOptions = response.data.LocaleArray;
      });
    },
    setLocale(currentForm) {
      this.disableNextButton = true;
      global.util.language = this.selectedLanguage;
      global.util
        .get(
          this.$tegsoftConfig.TouchLocale + `?locale=${this.selectedLanguage}`
        )
        .then((response) => {
          this.disableNextButton = false;
          cookie.setCookie("TOBE_LOCALE", this.selectedLanguage);
          this.$tegsoftForm.executeLocale(currentForm);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>