import { createMemoryHistory, createRouter } from "vue-router";
import tegsoftRoutes from '@/router/tegsoftRoutes'

let checkLoginStatusResponse;

const routes = [
  ...tegsoftRoutes,
  {
    path: "/dummyPerformance",
    name: "dummyPerformance",
    component: () => {
      checkLoginStatusResponse = null;
      return import("@/forms/TegsoftTouchWeb/login/login");
    },
  },
];

const router = createRouter({
  history: createMemoryHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name == "Login") {
    next();
    return;
  }
  if (to.name == "Signup") {
    next();
    return;
  }
  if (typeof global.util.token == "undefined" ||
    global.util.token == null ||
    global.util.token == "") {
    next({ name: "Login" });
    return;
  }

  next();
  return;
});

export default router;
