import tegsoftConfig from "./tegsoftConfig";
import DataSource from "./tegsoftDataSource";

export const tegsoftForm = {
  onFormLoad(currentForm) {
    if (currentForm.name == null || currentForm.name == undefined) {
      if (currentForm.$options) {
        currentForm.name = currentForm.$options.name;
      }
    }

    let licenseCheckNeeded = false;
    if (currentForm.name == "rm_main") {
      licenseCheckNeeded = true;

      global.util
        .get(
          tegsoftConfig.LicenseServlet +
            "service=checkLicense&licenseNames=TegsoftCRM_Ticketing&displayError=true&UID=" +
            global.util.user?.UID
        )
        .then((response) => {
          currentForm.tegsoftFormData.dataPopulated = false;
          if (response) {
            if (response.data) {
              if (response.data.allowedLicenseCount) {
                if (response.data.allowedLicenseCount > 0) {
                  this.processOnFormLoad(currentForm);
                }
              }
            }
          }
        });
    }

    if (!licenseCheckNeeded) {
      this.processOnFormLoad(currentForm);
    }
  },

  processOnFormLoad(currentForm) {
    this.parseFormParameters(currentForm);
    if (global.util.isTouchLoging()) {
      //If Public auth is not yet defined.
      if (typeof global.util.publicToken == "undefined") {
        global.util.get(tegsoftConfig.TouchPublicAuth).then((response) => {
          global.util.publicToken = response.data.token;
          this.executeLocale(currentForm);
        });
      } else {
        this.executeLocale(currentForm);
      }
    } else if (global.util.useTouchServices()) {
      //If Public auth is not yet defined.
      if (typeof global.util.publicToken == "undefined") {
        global.util.get(tegsoftConfig.TouchPublicAuth).then((response) => {
          global.util.publicToken = response.data.token;
          this.executeLocale(currentForm);
        });
      } else {
        this.executeLocale(currentForm);
      }
    } else {
      this.executeLocale(currentForm);
    }
    this.queryData(currentForm);

    if (currentForm.$tegsoftForm) {
      currentForm.processActions = currentForm.$tegsoftForm.processActions;
    }
    if (currentForm.name) {
      global.util[currentForm.name] = currentForm;
    }
  },

  parseFormParameters(currentForm) {
    var queryParameters = {};
    global.util.parseQueryParameters(queryParameters);

    if (global.util.isNull(queryParameters.seat)) {
      if (!global.util.applicationParameters) {
        return;
      }
    }

    if (global.util.isNotNull(queryParameters.seat)) {
      if (!global.util.applicationParameters) {
        global.util.applicationParameters = "seat=" + queryParameters.seat;
      } else {
        global.util.applicationParameters += ",seat=" + queryParameters.seat;
      }
    }

    currentForm.formParameters = global.util.applicationParameters;
    global.util.applicationParameters = undefined;

    if (currentForm.formParameters.split) {
      const formParametersArray = currentForm.formParameters.split(",");
      for (const applicationParameter of formParametersArray) {
        if (!applicationParameter) {
          continue;
        }
        if (applicationParameter.indexOf("=") < 0) {
          continue;
        }

        const applicationParameterArray = applicationParameter.split("=");
        currentForm[applicationParameterArray[0]] =
          applicationParameterArray[1];
      }
    } else if (typeof currentForm.formParameters == "object") {
      for (const applicationParameterName in currentForm.formParameters) {
        if (!applicationParameterName) {
          continue;
        }

        currentForm[applicationParameterName] =
          currentForm.formParameters[applicationParameterName];
      }
    }
  },
  getLangaugeQuery(currentForm) {
    // window.isLoading++;

    var formParameters = "";
    if (currentForm.formParameters) {
      formParameters = encodeURIComponent(currentForm.formParameters);
    }

    var localePackageNames = "";
    if (typeof currentForm.localePackageNames != "undefined") {
      localePackageNames = currentForm.localePackageNames;
    }
    var localeFileNames = "";
    if (typeof currentForm.localeFileNames != "undefined") {
      localeFileNames = currentForm.localeFileNames;
    }

    localeFileNames += ",reportBase,formBase01,";

    var language = "en";
    if (typeof global.util.language != "undefined") {
      language = global.util.language;
    }

    if (global.util.useTouchServices()) {
      return tegsoftConfig.TouchLocale + "?locale=" + language;
    }
    return (
      tegsoftConfig.LocaleServlet +
      currentForm.name +
      "&locale=" +
      language +
      "&packageNames=" +
      localePackageNames +
      "&fileNames=" +
      localeFileNames +
      "&formParameters=" +
      formParameters
    );
  },
  executeLocale(currentForm) {
    if (currentForm.locale) {
      if (
        !currentForm.locale.currentFormName ||
        currentForm?.locale?.currentFormName == "currentFormName"
      ) {
        currentForm.locale.currentFormName = currentForm.name;
      }
    }
    global.util
      .get(this.getLangaugeQuery(currentForm), currentForm)
      .then((response) => {
        if (!response?.data?.locale) {
          return;
        }

        for (const key in response.data.locale) {
          Object.assign(currentForm.locale, {
            [key]: response.data.locale[key],
          });
        }
        currentForm.tegsoftFormData.localeDataPopupulated = true;
        this.updateDataPopuplated(currentForm);
      });
  },
  updateDataPopuplated(currentForm) {
    if (currentForm.tegsoftFormData.dataPopulated) {
      return;
    }
    if (!currentForm.tegsoftFormData.localeDataPopupulated) {
      return;
    }

    if (currentForm.tegsoftFormData.popupulatedDataRequired == -1) {
      return;
    }

    if (
      currentForm.tegsoftFormData.popupulatedDataRequired >
      currentForm.tegsoftFormData.popupulatedDataCount
    ) {
      return;
    }

    currentForm.tegsoftFormData.dataPopulated = true;
    if (global?.util?.userconsoleForm?.hidePleaseWait) {
      global?.util?.userconsoleForm?.hidePleaseWait();
    }
    if (currentForm.onLocaleComplete) {
      currentForm.onLocaleComplete();
    }
  },
  queryData(currentForm) {
    if (global.util.useTouchServices()) {
      currentForm.tegsoftFormData.popupulatedDataRequired = 0;
      if (currentForm.onInitialDataPopuplated) {
        currentForm.onInitialDataPopuplated();
      }
      return;
    }

    var formParameters = "";
    if (currentForm.formParameters) {
      formParameters = encodeURIComponent(currentForm.formParameters);
    }

    global.util
      .get(
        tegsoftConfig.DbFileServlet +
          currentForm.name +
          "&locale=" +
          (global.util.language ? global.util.language : "en") +
          "&formParameters=" +
          formParameters,
        currentForm
      )
      .then((response) => {
        let predefinedDataSourceCount = 0;
        if (currentForm.db == undefined) {
          currentForm.db = {};
        } else {
          predefinedDataSourceCount = Object.keys(currentForm.db).length;
        }
        // currentForm.tegsoftFormData.popupulatedDataRequired = Object.keys(response.data.db).length - predefinedDataSourceCount;
        currentForm.tegsoftFormData.popupulatedDataRequired = 0;

        for (const dataSource in response.data.db) {
          currentForm.db[dataSource] = new DataSource(
            currentForm,
            response.data.db[dataSource]
          );
          let refresh = true;
          if (currentForm.db[dataSource]) {
            if (currentForm.db[dataSource].rows) {
              if (currentForm.db[dataSource].rows.length > 0) {
                refresh = false;
              } else {
                if (response.data.db[dataSource].fetchedRowCount == 0) {
                  refresh = false;
                }
              }
            }
          }

          if (refresh) {
            currentForm.tegsoftFormData.popupulatedDataRequired++;
          }
        }

        for (const dataSource in response.data.db) {
          let refresh = true;
          if (currentForm.db[dataSource]) {
            if (currentForm.db[dataSource].rows) {
              if (currentForm.db[dataSource].rows.length > 0) {
                refresh = false;
              } else {
                if (response.data.db[dataSource].fetchedRowCount == 0) {
                  refresh = false;
                }
              }
            }
          }

          if (refresh) {
            currentForm.db[dataSource].refresh();
          }
        }

        currentForm.tegsoftFormData.popupulatedDataCount++;
        this.updateDataPopuplated(currentForm);

        if (currentForm.onInitialDataPopuplated) {
          currentForm.onInitialDataPopuplated();
        }
      });
  },
  data() {
    return {
      tegsoftFormData: {
        localeDataPopupulated: false,
        popupulatedDataCount: 0,
        popupulatedDataRequired: -1,
        dataPopulated: false,
        saveRequired: false,
        undoRequired: false,
      },
      locale: new Proxy(
        {},
        {
          get(locale, keyword) {
            if (typeof keyword == "string") {
              if (keyword.startsWith("_") || keyword.indexOf("(") >= 0) {
                return locale[keyword];
              }
              var result = locale[("" + keyword).toLocaleUpperCase("en-US")];
              if (typeof result == "undefined") {
                console.log(
                  "Locale " +
                    locale.currentFormName +
                    "." +
                    keyword +
                    " is missing."
                );
                return keyword;
              }
              return result;
            }
            return locale[keyword];
          },
        }
      ),
      filterArea: { isFilterOpen: true },
      db: {},
      // isLoading: 0,
      errors: [],
    };
  },

  getViewState(currentForm) {
    if (typeof currentForm == "undefined") {
      return;
    }

    if (typeof currentForm.prepareViewState != "undefined") {
      try {
        currentForm.prepareViewState();
      } catch (ignoredError) {}
    }

    var viewState = {};
    var viewStateComponents = {
      viewStateComponentNames: [],
    };

    if (typeof currentForm.viewStateComponents != "undefined") {
      if (currentForm.viewStateComponents != null) {
        viewStateComponents = {
          viewStateComponentNames: Object.keys(currentForm.viewStateComponents),
          ...currentForm.viewStateComponents,
        };
      }
    }

    var viewStateDataSources = {};
    if (typeof currentForm.viewStateDataSources != "undefined") {
      viewStateDataSources.viewStateDataSourceNames =
        currentForm.viewStateDataSources;
      for (const viewStateDataSourceName of viewStateDataSources.viewStateDataSourceNames) {
        if (typeof currentForm == "undefined") {
          continue;
        }
        if (typeof currentForm.$data == "undefined") {
          continue;
        }
        if (typeof currentForm.$data.db == "undefined") {
          continue;
        }
        if (
          typeof currentForm.$data.db[viewStateDataSourceName] == "undefined"
        ) {
          continue;
        }
        if (
          typeof currentForm.$data.db[viewStateDataSourceName].currentRow ==
          "undefined"
        ) {
          continue;
        }
        if (
          typeof currentForm.$data.db[viewStateDataSourceName].currentRow
            .mirrorDataArray == "undefined"
        ) {
          continue;
        }
        viewStateDataSources[viewStateDataSourceName] =
          currentForm.$data.db[
            viewStateDataSourceName
          ].currentRow.mirrorDataArray;
      }
    }

    viewState.viewStateComponents = viewStateComponents;
    viewState.viewStateDataSources = viewStateDataSources;

    return { viewState: viewState };
  },

  processActions(currentForm, responseData) {
    if (typeof responseData == "undefined") {
      return;
    }

    if (responseData.serverDateTime) {
      if (global.util.userconsoleForm) {
        global.util.userconsoleForm.serverDateTime =
          responseData.serverDateTime;
      }
    }

    if (responseData.clientSideActions) {
      for (const clientSideAction of responseData.clientSideActions) {
        if (global.util.isNull(clientSideAction)) {
          continue;
        }
        if (clientSideAction.actionName == "refresh") {
          const childDataSource =
            currentForm.db[clientSideAction.dataSourceName];
          if (childDataSource != null) {
            childDataSource.refresh();
          }
        } else if (clientSideAction.actionName == "agentAction") {
          if (global.util.agentForm) {
            global.util.agentForm.processAction(clientSideAction);
          } else {
            if (global.util.lostAgentFormClientSideActions == undefined) {
              global.util.lostAgentFormClientSideActions = [];
            }
            global.util.lostAgentFormClientSideActions.push(clientSideAction);
          }
        } else if (clientSideAction.actionName == "showMessage") {
          let formatMessage = "";
          if (clientSideAction.tableName) {
            let arr = [];
            arr.push(currentForm.locale[clientSideAction.tableName]);
            formatMessage = global.util.formatMessage(
              clientSideAction.message,
              arr
            );
          } else {
            formatMessage = clientSideAction.message;
          }
          global.util.systemDialogues.show(
            clientSideAction.messageType.toLocaleUpperCase(),
            clientSideAction.messageType,
            formatMessage
          );
        } else if (clientSideAction.actionName == "showToastMessage") {
          let formatMessage = "";
          if (clientSideAction.tableName) {
            let arr = [];
            arr.push(currentForm.locale[clientSideAction.tableName]);
            formatMessage = global.util.formatMessage(
              clientSideAction.message,
              arr
            );
          } else {
            formatMessage = clientSideAction.message;
          }

          if (clientSideAction.messageHeader) {
            global.util.userconsoleForm.toastNotificationTitle =
              clientSideAction.messageHeader;
          }
          global.util.userconsoleForm.toastNotificationMessage = formatMessage;
          global.util.userconsoleForm.toastNotificationDisplay = true;

          setTimeout(() => {
            global.util.userconsoleForm.toastNotificationDisplay = false;
          }, 3000);
        } else if (clientSideAction.actionName == "messageBoardNotification") {
          global.util.userconsoleForm.messageBoard.newMessage = true;
          global.util.userconsoleForm.toastNotificationTitle =
            clientSideAction.messageHeader;
          global.util.userconsoleForm.toastNotificationMessage =
            clientSideAction.message;
          global.util.userconsoleForm.toastNotificationDisplay = true;

          setTimeout(() => {
            global.util.userconsoleForm.toastNotificationDisplay = false;
          }, 3000);
        } else if (
          clientSideAction.actionName == "internalMessageNotification"
        ) {
          if (!global.util.userconsoleForm.chatBoard.visibility) {
            global.util.userconsoleForm.chatBoard.newMessage = true;
            global.util.userconsoleForm.toastNotificationTitle =
              clientSideAction.messageHeader;
            global.util.userconsoleForm.toastNotificationMessage =
              clientSideAction.message;
            global.util.userconsoleForm.toastNotificationDisplay = true;

            setTimeout(() => {
              global.util.userconsoleForm.toastNotificationDisplay = false;
            }, 3000);
          }
        } else if (clientSideAction.actionName == "updateContentNotification") {
          if (global.util.userconsoleForm.chatBoard.visibility) {
            global.util.userconsoleForm.chatBoard.updateContent = true;
            global.util.userconsoleForm.chatBoard.updateContentId =
              clientSideAction.message;
          }
        } else if (clientSideAction.actionName == "logout") {
          global.util.logout();
        } else if (
          clientSideAction.actionName == "userBackgroundOperationAction"
        ) {
          if (global.util.userconsoleForm) {
            global.util.userconsoleForm.processAction(clientSideAction);
          }
        }
      }
    }
  },

  refreshDatabases(currentForm, dataSourceNames) {
    for (const dataSourceName of dataSourceNames) {
      const dataSource = currentForm.db[dataSourceName];
      if (dataSource != null) {
        dataSource.refresh();
      }
    }
  },

  scrollTable(currentForm) {
    if (currentForm.dataSource?.rows?.length <= 0) {
      return;
    }

    if (
      typeof currentForm.$refs[
        "rowIndex" + currentForm.dataSource?.currentRowIndex
      ] == "undefined"
    ) {
      return;
    }

    let currentRowRef =
      currentForm.$refs["rowIndex" + currentForm.dataSource?.currentRowIndex];

    if (currentRowRef == "undefined") {
      return;
    }

    if (currentRowRef == null) {
      return;
    }

    if (typeof currentRowRef.offsetTop == "undefined") {
      return;
    }

    currentForm.$refs["dataBody"].scrollTop =
      currentForm.$refs["rowIndex" + currentForm.dataSource?.currentRowIndex]
        .offsetTop -
      currentForm.$refs["rowIndex" + currentForm.dataSource?.currentRowIndex]
        .offsetHeight;
  },

  executeServerCommand(currentForm, commandName, dataSourceNames) {
    if (global.util.isLoading > 0) {
      return;
    }
    var viewState = this.getViewState(currentForm);

    global.util
      .post(
        tegsoftConfig.ExecuteCommandServlet +
          currentForm.name +
          "&commandName=" +
          commandName,
        viewState,
        currentForm
      )
      .then((response) => {
        for (const dataSourceName of dataSourceNames) {
          const dataSource = currentForm.db[dataSourceName];
          if (dataSource != null) {
            dataSource.refresh();
          }
        }
      });
  },

  executeServerCommandForAll(
    currentForm,
    commandName,
    loopDataSource,
    dataSourceNames
  ) {
    if (global.util.isLoading > 0) {
      return;
    }
    if (loopDataSource.rows.length == 0) {
      return;
    }

    this.executeServerCommandForAllIndex(
      currentForm,
      commandName,
      loopDataSource,
      dataSourceNames,
      0
    );
  },

  executeServerCommandForAllIndex(
    currentForm,
    commandName,
    loopDataSource,
    dataSourceNames,
    index
  ) {
    if (index >= loopDataSource.rows.length || index > 60) {
      for (const dataSourceName of dataSourceNames) {
        const dataSource = currentForm.db[dataSourceName];
        if (dataSource != null) {
          dataSource.refresh();
        }
      }
      loopDataSource.refresh();
      return;
    }

    loopDataSource.setCurrentRowIndex(index++);
    var viewState = this.getViewState(currentForm);

    global.util
      .post(
        tegsoftConfig.ExecuteCommandServlet +
          currentForm.name +
          "&commandName=" +
          commandName +
          "&bulkUpdate=true",
        viewState,
        currentForm
      )
      .then((response) => {
        this.executeServerCommandForAllIndex(
          currentForm,
          commandName,
          loopDataSource,
          dataSourceNames,
          index
        );
      });
  },
  runServerMethod(currentForm, methodName) {
    var viewState = this.getViewState(currentForm);

    global.util.post(
      tegsoftConfig.RunServerMethodServlet +
        currentForm.name +
        "&methodName=" +
        methodName,
      viewState,
      currentForm
    );
  },
  runServerMethodWithEventParameter(currentForm, methodName) {
    var viewState = this.getViewState(currentForm);

    global.util.post(
      tegsoftConfig.RunServerMethodServlet +
        currentForm.name +
        "&methodName=" +
        methodName +
        "&eventMethod=true",
      viewState,
      currentForm
    );
  },

  updateSaveRequired(currentForm) {
    currentForm.tegsoftFormData.saveRequired = false;
    currentForm.tegsoftFormData.undoRequired = false;
    for (const dataSourceName in currentForm.db) {
      const dataSource = currentForm.db[dataSourceName];
      if (dataSource != null) {
        dataSource.updateSaveRequired();
        if (currentForm.tegsoftFormData.saveRequired) {
          return;
        }
      }
    }
  },
};
export default tegsoftForm;
