<template>
  <h4 class="heading-4 text-left">{{ locale.CHOOSE_METHOD_TO_VERIFY_BUSINESS_WEBSITE }}</h4>
  <MDBAccordion v-model="activeItem" flush>
    <MDBAccordionItem
      :headerTitle="locale.DNS_VERIFICATION"
      collapseId="flush-collapseOne"
    >
      <div>
        <div>{{ locale.DNS_RECORD }}: </div>
        <p>
          <div>{{ locale.ENTRYTYPE }}: <code class="border-none">{{ verificationKey.DNS.dnsEntryType?.toUpperCase() }}</code></div>
          <div class="flex items-center">
            <span class="inline-block">{{ locale.VALUE }}: </span>
            <code class="code__copy-text">
              {{ verificationKey.DNS.dnsEntryValue }}
            </code>
            <div class="code__ext code__btn border px-2 cursor-pointer" @click="copyToClipboard(verificationKey.DNS.dnsEntryValue)">
              <i class="fa-solid fa-copy mx-1"></i>{{ locale.COPY }}
            </div>
          </div>
        </p>
      </div>
      <p>{{ locale.CLICKON.replace(/{.*}/, `"${locale.VERIFYDNS}"`) }} </p>
      <div class="py-2 pt-3 text-right">
        <MDBBtn color="primary" @click="verifyBusinessDNS">{{ locale.VERIFYDNS }}</MDBBtn>
      </div>
    </MDBAccordionItem>
    <MDBAccordionItem
      :headerTitle="locale.HTML_FILE_UPLOAD"
      collapseId="flush-collapseTwo"
    >
      <div>{{ locale.CREATE_HTML_FILE }}:</div>
         <div class="flex items-center">
            <span class="inline-block">{{ locale.FILE_NAME}}: </span>
            <code class="code__copy-text">
              {{ verificationKey.HTML.htmlFileName }}
            </code>
            <div class="code__ext code__btn border px-2 cursor-pointer" @click="copyToClipboard(verificationKey.HTML.htmlFileName)">
              <i class="fa-solid fa-copy mx-1"></i>{{ locale.COPY }}
            </div>
          </div>
      <div>{{ locale.CLICKON.replace(/{.*}/, `"${locale.VERIFY_UPLOADEDFILE}"`) }}</div>
      <div class="py-2 pt-3 text-right">
        <MDBBtn color="primary" @click="verifyBusinessHTML">{{ locale.VERIFY_UPLOADEDFILE }}</MDBBtn>
      </div>
      <p class="pt-3" style="line-height: 1.5;">{{ locale.EXPECTED_LINKS }}: 
        <div v-if="verificationKey.HTML.verificationUrl">
          <div class="flex">
            <a class="flex" :href="verificationKey.HTML.verificationUrl[0]" target="_blank">
              <span class="code code__link">{{ verificationKey.HTML.verificationUrl[0] }}</span>
              <i class="fa-solid fa-square-arrow-up-right flex items-center"></i>
            </a>
          </div>
          <div class="flex">
            <a class="flex" :href="verificationKey.HTML.verificationUrl[1]" target="_blank">
              <span class="code code__link">{{ verificationKey.HTML.verificationUrl[1] }}</span>
              <i class="fa-solid fa-square-arrow-up-right flex items-center"></i>
            </a>
          </div>
        </div>
      </p>
    </MDBAccordionItem>
    <MDBAccordionItem
      :headerTitle="locale.EMAIL"
      collapseId="flush-collapseThree"
    >
      <MDBRow>
        <MDBCol col="6">
          <MDBRadio
            :label="'info@'+businessFormGroup.website.value"
            name="flexRadioDefault"
            v-model="emailPrefix"
            value="info"
          />
        </MDBCol>
        <MDBCol col="6">
          <MDBRadio
            :label="'admin@'+businessFormGroup.website.value"
            name="flexRadioDefault"
            v-model="emailPrefix"
            value="admin"
          />
        </MDBCol>
        <MDBCol col="6">
          <MDBRadio
            :label="'mail@'+businessFormGroup.website.value"
            name="flexRadioDefault"
            v-model="emailPrefix"
            value="mail"
          />
        </MDBCol>
        <MDBCol col="6">
          <MDBRadio
            :label="'contact@'+businessFormGroup.website.value"
            name="flexRadioDefault"
            v-model="emailPrefix"
            value="contact"
          />
        </MDBCol>
        <MDBCol col="6">
          <MDBRadio
            :label="'support@'+businessFormGroup.website.value"
            name="flexRadioDefault"
            v-model="emailPrefix"
            value="support"
          />
        </MDBCol>
        <MDBCol col="6">
          <MDBRadio
            :label="'about@'+businessFormGroup.website.value"
            name="flexRadioDefault"
            v-model="emailPrefix"
            value="about"
          />
        </MDBCol>
      </MDBRow>
        
      <div v-if="!showEmailVerificationModal" class="py-2 pt-5 flex items-center">
        <div style="font-size: 1.3rem; margin-right: auto">
          {{ locale.ALREADY_HAS_CODE }}?
          <div class="link inline-block" @click="showEmailVerificationModal = true">{{ locale.VERIFY }}</div>
        </div>
        <MDBBtn color="primary" class="ml-auto" @click="generateVerificationKeyEMAIL">{{ locale.SEND_EMAIL }}</MDBBtn>
      </div>
    </MDBAccordionItem>
  </MDBAccordion>
  
  <MDBModal
    id="showEmailVerificationModal"
    tabindex="-1"
    labelledby="showEmailVerificationModal"
    v-model="showEmailVerificationModal"
    staticBackdrop
  >
    <!-- <verify-email
      :parentData="parentData"
      :emailVerificationCode="emailVerificationCode"
      :actionName="'verify email'"
      @verifyEmail="verifyBusinessEmail"
    ></verify-email> -->

    <!-- <div> -->
        <MDBModalHeader class="verifyEmailWebsitmodal relative">
      <img :src="require('@/assets/icons/email-sent.png')" alt="email verification" class="m-auto my-3 inline-block" />
    </MDBModalHeader>
    <!-- </div> -->
    <h2> {{ locale.VERIFY_EMAIL }}</h2>
    <MDBCol md="9" class="m-auto">
        <p class="text-center" 
          v-html="formatMessage(locale.VERIFYEMAIL, emailPrefix+'@'+businessFormGroup.website.value)"> </p>
        <label for="email-code" class="form-label"> {{ locale.ENTER_CODE }} </label>
        <MDBInput
          inputGroup
          aria-autocomplete="false"
          :formOutline="false"
          v-model="emailVerificationCode.value"
          id="email-code"
          :class="{'invalid': emailVerificationCode.hasError}"
          :onkeypress="onlyNumberKey"
        />
        <input-error-message 
          v-if="emailVerificationCode.hasError && !emailVerificationCode.invalidCode"
          :message="locale.EMAILCODE_REQUIRED"
        />
        <input-error-message 
          v-if="emailVerificationCode.hasError && emailVerificationCode.invalidCode"
          :message="locale.INVALID_CODE"
        />
      <div class="py-5 flex justify-between">
        <div > 
          <span v-if="!checkYourEmail" @click="generateVerificationKeyEMAIL" class="link inline-block px-2">{{ locale.RESEND_CODE }}</span>
          <span v-if="checkYourEmail" class="inline-block px-2" style="background:#01b74a; color:#fff; border-radius:3px;">
            {{ locale.EMAIL_SEND }}</span>
        </div>
        <MDBBtn color="primary" @click="verifyBusinessEmail"> {{ locale.VERIFY }} </MDBBtn>
      </div>
      <div class="text-left pb-4">
        <div class="link inline-block" @click="showEmailVerificationModal=false">{{ locale.CHANGEMETHOD }}</div>
      </div>
    </MDBCol>
  </MDBModal>
  <MDBModal
    id="showErrorModal"
    tabindex="-1"
    labelledby="showErrorModal"
    v-model="showErrorResponseModal"
    staticBackdrop
  >
    <h2 class="pt-5"> {{ locale.OOPS }}</h2>
    <MDBCol md="9" class="m-auto">
      <div v-if="verifyDNSFailed">
        <p class="text-center bold invalid">
            {{ locale.DNS_VERIFICATION_FAILED }}
        </p>
        <p class="text-center bold">
            {{ locale.ADD_PROVIDED_DNSVALUE }}
        </p>
      </div>
      <div v-if="verifyHTMLFailed">
        <p class="text-center bold invalid">
            {{ locale.HTML_FILE_NOTFOUND }}
        </p>
        <p class="text-center bold">
            {{ locale.ADD_HTML_FILE_TOHOST }}
        </p>
      </div>
      <div class="py-5 flex justify-end">
          <MDBBtn color="primary" @click="closeErrorResponseModal"> {{ locale.OK }} </MDBBtn>
      </div>
    </MDBCol>
  </MDBModal>

  <div class="mt-auto flex justify-between">
    <div class="link p-2 inline-block" @click="navigation.currentScreen = 'business-info'">
        {{ locale.GO_BACK }}
    </div>
    <!-- <div class="link p-2 inline-block" @click="navigation.currentScreen = 'region-info'">
        {{ locale.SKIP_WEBSITEVERIFICATION }}
    </div> -->
  </div>
</template>

<script>
import verifyEmail from './verify-email.vue';
export default {
  components: { verifyEmail },
    props:["parentData"],
    data(){
      return{
        ...this.$props.parentData,
        emailPrefix: "",
        activeItem: "flush-collapseOne",
        checkYourEmail: false,
        emailVerificationCode:{ 
          value:"", 
          hasError: false,
          invalidCode: false
        },
        showEmailVerificationModal: false,
        showErrorResponseModal: false,
        verifyDNSFailed: false,
        verifyHTMLFailed: false
      }
    },
    mounted(){
      if( this.businessFormGroup.website.valueChanged ){
        this.generateVerificationKeyDNS();
        this.generateVerificationKeyHTML();
        this.businessFormGroup.website.valueChanged = false;
      }
    },
    watch:{
      "checkYourEmail": function(){
        setTimeout(()=>{
          this.checkYourEmail = false;
        }, 5000);
      },
      "showEmailVerificationModal": function(){
        this.emailVerificationCode.value = ""
        this.emailVerificationCode.hasError = false;
        this.emailVerificationCode.invalidCode = false;
      },
      "emailVerificationCode.value": function(){
        this.emailVerificationCode.hasError = false;
        this.emailVerificationCode.invalidCode = false;
      },
      "activeItem": function(newValue, oldValue){
        if(newValue == ""){
          this.$nextTick(()=>{
            this.activeItem = oldValue;
            if(document.getElementById(oldValue != undefined)){
              document.getElementById(oldValue).style.display = "block";
              document.getElementById(oldValue).classList.add('show')
              setTimeout(() => {
                  document.getElementById(oldValue).style.height = "auto";
              }, 50);
            }
          })
        } 
      },
    },
    methods:{
      closeErrorResponseModal(){
        this.verifyDNSFailed = false;
        this.verifyHTMLFailed = false;
        this.showErrorResponseModal = false;
      },
      verifyBusinessDNS(){
        global.util.post(this.$tegsoftConfig.TouchVerifyDNS + this.businessFormGroup.website.value)
        .then( response =>{
          if(response.data.success){
            this.navigation.currentScreen = "region-info";
          }
          else{
            this.verifyDNSFailed = true;
            this.showErrorResponseModal = true;
          }
        })
      },
      verifyBusinessHTML(){
        global.util.post(this.$tegsoftConfig.TouchVerifyEMAIL + this.businessFormGroup.website.value)
        .then( response =>{
          if(response.data.success){
            this.navigation.currentScreen = "region-info";
          }
          else{
            this.verifyHTMLFailed = true;
            this.showErrorResponseModal = true;
          }
        })
      },
      verifyBusinessEmail(){
        if(this.emailVerificationCode.value == ""){
          this.emailVerificationCode.hasError = true;
          return;
        }

        global.util.post(this.$tegsoftConfig.TouchVerifyEMAIL + 
        `${this.businessFormGroup.website.value}&code=${this.emailVerificationCode.value}`)
        .then( response =>{
          if(response.data.success){
            this.showEmailVerificationModal = false;
            this.navigation.currentScreen = "region-info";
          }
          else{
            this.emailVerificationCode.hasError = true;
            this.emailVerificationCode.invalidCode = true;
          }
        })
      },
      generateVerificationKeyDNS(){
        global.util.get(this.$tegsoftConfig.TouchGenerateVerificationKeyDNS + this.businessFormGroup.website.value)
        .then( response =>{
          this.verificationKey.DNS = response.data.result;
        })
      },
      generateVerificationKeyHTML(){
        global.util.get(this.$tegsoftConfig.TouchGenerateVerificationKeyHTML + this.businessFormGroup.website.value)
        .then( response =>{
          this.verificationKey.HTML = response.data.result;
        })
      },
      generateVerificationKeyEMAIL(){
        global.util.get(this.$tegsoftConfig.TouchGenerateVerificationKeyEMAIL + 
          `${this.businessFormGroup.website.value}&emailPrefix${this.emailPrefix}`)
        .then( response =>{
          this.checkYourEmail = true;
          this.showEmailVerificationModal = true;
          this.verificationKey.EMAIL = response.data.result;
        })
      },
      copyToClipboard(text) {
          var dummy = document.createElement("textarea");
          document.body.appendChild(dummy);
          dummy.value = text;
          dummy.select();
          document.execCommand("copy");
          document.body.removeChild(dummy);
      },
      onlyNumberKey(event) {
        // Only ASCII character in the range allowed
        return event.charCode >= 48 && event.charCode <= 57;
      },
      formatMessage(str, arr){
        // return global.util.formatMessage(str, arr);
        if(!!arr){
            return str.replace(/{.*}/, "<code>"+arr+"</code>")
        }
        return str.replace(/{.*}/, "<code>"+global.util.userEmail+"</code>") 
      },
    }
}
</script>

<style lang="scss" scoped>
   a > span, a > code{
    &:hover{
        color: var(--color-primary);
    }
}
</style>