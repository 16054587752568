<template>
        <MDBModal
            tabindex="-1"
            labelledby="verifyTitle"
            v-model="showModalProp"
            centered
            :staticBackdrop="false"
            
        >
                <slot></slot>
        </MDBModal>
</template>

<script>
// import { ref, reactive } from "vue";
export default {
    name: "modal",
    props:["showModal"],
    data(){
        return{
            showModalProp: this.$props.showModal ?? false,
            ...this.parentData
        }
    },
    watch:{
        showModalProp: function( newValue ){
            if( newValue == false ){
                this.$emit("closeModal");
            }
        },
        showModal: function( newValue ){
            this.showModalProp = newValue;
        },
    },
    methods:{
        updatePhone(){

        }
    }
}
</script>

<style lang="scss" scoped>

</style>