<template>
  <transition name="invalid">
    <div class="invalid-note">
      {{ message }}
    </div>
  </transition>
</template>

<script>
export default {
  props: ["message"],
};
</script>

<style lang="scss" scoped>
</style>