import { Buffer } from "buffer";
import tegsoftConfig from "./tegsoftConfig";
import tegsoftForm from "./tegsoftForm";
import router from "../src/router";
import { DataRow } from "./tegsoftDataRow";

export const tegsoftUtil = {
  user: null,
  userconsoleForm: null,
  systemDialogues: null,
  agentForm: null,
  logoutCalled: false,
  rowTBLPBXEXT: {
    EXTEN: "0000",
  },

  parentPageDomainName: window.location.ancestorOrigins
    ? window.location.ancestorOrigins[0]
      ? window.location.ancestorOrigins[0]
      : "*"
    : "*",

  isLoading: 0,
  applications: [
    // {
    //   title: "",
    //   app: "",
    //   itemId: "",
    //   parameters: [],
    //   parentId:'',
    // },
  ],
  menu: [],
  pageWindow: {
    screenVisible: true,
    hidden: undefined,
    visibilityChange: undefined,
    visibilitySupported: true,
  },

  get: (url, currentForm, config) => {
    global.util.getHeaders();
    if (typeof global.util.token != "undefined") {
      window.server.defaults.headers.common["Authorization"] =
        "Bearer " + global.util.token;
    } else if (typeof global.util.publicToken != "undefined") {
      window.server.defaults.headers.common["Authorization"] =
        "Bearer " + global.util.publicToken;
    }

    let ignoreError = false;
    if (config?.ignoreError == true) {
      ignoreError = true;
    }

    global.util.systemDialogues?.isLoadingUpdated();
    return new Promise((resolve, reject) => {
      if (!ignoreError) {
        global.util.isLoading++;
      }
      url = global.util.getActiveClusterUrl(url);
      window.server
        .get(url)
        .then((response) => {
          if (!ignoreError) {
            global.util.isLoading--;
          }
          global.util.systemDialogues?.isLoadingUpdated();
          response.currentForm = currentForm;
          global.util.closeDisconnectedError();
          try {
            tegsoftForm.processActions(currentForm, response.data);
          } catch (exception) {
            console.error(exception);
            global.util.systemDialogues?.show("Error", "ERROR", exception);
          }
          if (global.util.proxyHost) {
            resolve({ data: response.data?.result });
          } else {
            resolve(response);
          }
        })
        .catch((error) => {
          if (!ignoreError) {
            global.util.isLoading--;
          }
          global.util.systemDialogues?.isLoadingUpdated();
          error.currentForm = currentForm;
          error.config = config;
          error.url = url;
          tegsoftForm.processActions(currentForm, error.response?.data);

          if (ignoreError) {
            reject(error, currentForm, config);
            return;
          }

          if (error.response?.status == 401) {
            reject(error, currentForm, config);
            global.util.closeDisconnectedError();
            return;
          }

          if (error.response?.status == 404 || error.response?.status >= 500) {
            global.util.systemDialogues?.showDisconnectedError(
              global.util.userconsoleForm?.locale?.connectionErrorMessage ||
              "Internet Connection issue"
            );
            reject(error, currentForm, config);
            return;
          }
          global.util.closeDisconnectedError();

          global.util.systemDialogues?.show(
            "DisconnectedError",
            "ERROR",
            error
          );
          reject(error, currentForm, config);
        });
    });
  },
  closeDisconnectedError() {
    if (!global.util.systemDialogues) {
      return;
    }
    if (!global.util.systemDialogues.dialogState) {
      return;
    }
    if (!global.util.systemDialogues.dialogState.type) {
      return;
    }
    if (!global.util.systemDialogues.dialogState.open) {
      return;
    }

    if (global.util.systemDialogues.dialogState.type == "DisconnectedError") {
      global.util.systemDialogues.closeDialog();
    }
    if (global.util.webrtcPhone) {
      global.util.webrtcPhone.attemptReconnection();
    }
  },
  post: (url, body, currentForm, config) => {
    global.util.getHeaders();
    if (global.util.token) {
      window.server.defaults.headers.common["Authorization"] =
        "Bearer " + global.util.token;
    }
    global.util.systemDialogues?.isLoadingUpdated();
    let ignoreError = false;
    if (config?.ignoreError == true) {
      ignoreError = true;
    }
    return new Promise((resolve, reject) => {
      if (!ignoreError) {
        global.util.isLoading++;
      }
      url = global.util.getActiveClusterUrl(url);
      window.server
        .post(url, body, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        })
        .then((response) => {
          if (!ignoreError) {
            global.util.isLoading--;
          }
          global.util.systemDialogues?.isLoadingUpdated();
          global.util.closeDisconnectedError();
          tegsoftForm.processActions(currentForm, response?.data);
          resolve(response, currentForm, config);
        })
        .catch((error) => {
          if (!ignoreError) {
            global.util.isLoading--;
          }
          global.util.systemDialogues?.isLoadingUpdated();
          tegsoftForm.processActions(currentForm, error.response?.data);
          if (ignoreError) {
            reject(error, currentForm, config);
            return;
          }
          if (error.response?.status == 404 || error.response?.status >= 500) {
            global.util.systemDialogues?.showDisconnectedError(
              global.util.userconsoleForm?.locale?.connectionErrorMessage ||
              "Internet Connection issues"
            );
            reject(error, currentForm, config);
            return;
          }
          global.util.closeDisconnectedError();
          reject(error, currentForm, config);
        });
    });
  },
  patch: (url, body, currentForm, config) => {
    global.util.getHeaders();
    if (global.util.token) {
      window.server.defaults.headers.common["Authorization"] =
        "Bearer " + global.util.token;
    }
    global.util.systemDialogues?.isLoadingUpdated();
    let ignoreError = false;
    if (config?.ignoreError == true) {
      ignoreError = true;
    }
    return new Promise((resolve, reject) => {
      if (!ignoreError) {
        global.util.isLoading++;
      }
      url = global.util.getActiveClusterUrl(url);
      window.server
        .patch(url, body, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        })
        .then((response) => {
          if (!ignoreError) {
            global.util.isLoading--;
          }
          global.util.systemDialogues?.isLoadingUpdated();
          global.util.closeDisconnectedError();
          tegsoftForm.processActions(currentForm, response.data);
          resolve(response, currentForm, config);
        })
        .catch((error) => {
          if (!ignoreError) {
            global.util.isLoading--;
          }
          global.util.systemDialogues?.isLoadingUpdated();
          tegsoftForm.processActions(currentForm, error.response?.data);
          if (config?.ignoreError) {
            reject(error, currentForm, config);
            return;
          }
          if (error.response?.status == 404 || error.response?.status >= 500) {
            global.util.systemDialogues?.showDisconnectedError(
              global.util.userconsoleForm?.locale?.connectionErrorMessage ||
              "Internet Connection issues"
            );
            reject(error, currentForm, config);
            return;
          }
          global.util.closeDisconnectedError();
          reject(error, currentForm, config);
        });
    });
  },
  put: (url, body, currentForm, config) => {
    global.util.getHeaders();
    if (global.util.token) {
      window.server.defaults.headers.common["Authorization"] =
        "Bearer " + global.util.token;
    }
    global.util.systemDialogues?.isLoadingUpdated();
    let ignoreError = false;
    if (config?.ignoreError == true) {
      ignoreError = true;
    }
    return new Promise((resolve, reject) => {
      if (!ignoreError) {
        global.util.isLoading++;
      }
      url = global.util.getActiveClusterUrl(url);
      window.server
        .put(url, body, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        })
        .then((response) => {
          if (!ignoreError) {
            global.util.isLoading--;
          }
          global.util.systemDialogues?.isLoadingUpdated();
          global.util.closeDisconnectedError();
          tegsoftForm.processActions(currentForm, response.data);
          resolve(response, currentForm, config);
        })
        .catch((error) => {
          if (!ignoreError) {
            global.util.isLoading--;
          }
          global.util.systemDialogues?.isLoadingUpdated();
          tegsoftForm.processActions(currentForm, error.response?.data);
          if (ignoreError) {
            reject(error, currentForm, config);
            return;
          }
          if (error.response?.status == 404 || error.response?.status >= 500) {
            global.util.systemDialogues?.showDisconnectedError(
              global.util.userconsoleForm?.locale?.connectionErrorMessage ||
              "Internet Connection issues"
            );
            reject(error, currentForm, config);
            return;
          }
          global.util.closeDisconnectedError();
          reject(error, currentForm, config);
        });
    });
  },
  delete: (url, currentForm, config) => {
    global.util.getHeaders();
    if (typeof global.util.token != "undefined") {
      window.server.defaults.headers.common["Authorization"] =
        "Bearer " + global.util.token;
    } else if (typeof global.util.publicToken != "undefined") {
      window.server.defaults.headers.common["Authorization"] =
        "Bearer " + global.util.publicToken;
    }
    global.util.systemDialogues?.isLoadingUpdated();
    let ignoreError = false;
    if (config?.ignoreError == true) {
      ignoreError = true;
    }
    return new Promise((resolve, reject) => {
      if (!ignoreError) {
        global.util.isLoading++;
      }
      url = global.util.getActiveClusterUrl(url);
      window.server
        .delete(url)
        .then((response) => {
          if (!ignoreError) {
            global.util.isLoading--;
          }
          global.util.systemDialogues?.isLoadingUpdated();
          response.currentForm = currentForm;
          global.util.closeDisconnectedError();
          try {
            tegsoftForm.processActions(currentForm, response.data);
          } catch (exception) {
            console.error(exception);
            global.util.systemDialogues?.show("Error", "ERROR", exception);
          }
          resolve(response);
        })
        .catch((error) => {
          if (!ignoreError) {
            global.util.isLoading--;
          }
          global.util.systemDialogues?.isLoadingUpdated();
          error.currentForm = currentForm;
          tegsoftForm.processActions(currentForm, error.response?.data);
          if (ignoreError) {
            reject(error, currentForm, config);
            return;
          }

          if (error.response?.status == 401) {
            reject(error, currentForm, config);
            global.util.closeDisconnectedError();
            return;
          }
          if (error.response?.status == 404 || error.response?.status >= 500) {
            global.util.systemDialogues?.showDisconnectedError(
              global.util.userconsoleForm?.locale?.connectionErrorMessage ||
              "Internet Connection issue"
            );
            reject(error, currentForm, config);
            return;
          }
          global.util.closeDisconnectedError();
          global.util.systemDialogues?.show("Error", "ERROR", error);
          reject(error, currentForm, config);
        });
    });
  },
  getActiveClusterUrl(url) {
    if (global.util.isNull(url)) {
      return url;
    }

    if (
      global.util.proxyHost == true &&
      global.util.isNotNull(global.util.activeHost)
    ) {
      if (global.util.proxyToken) {
        return (
          tegsoftConfig.proxyHostUrl +
          encodeURIComponent("https://" + global.util.activeHost + url) +
          "&token=" +
          global.util.proxyToken
        );
      } else {
        return (
          tegsoftConfig.proxyHostUrl +
          encodeURIComponent("https://" + global.util.activeHost + url)
        );
      }
    }

    if (!url.startsWith("/")) {
      return url;
    }

    if (global.util.isNull(global.util.activeHost)) {
      return url;
    }

    if (!global.util.appLogin) {
      if (global.util.isNull(url)) {
        return url;
      }

      if (!url.startsWith("/")) {
        return url;
      }
      if (global.util.isNull(global.util.activeHost)) {
        return url;
      }

      return "https://" + global.util.activeHost + url;
    }

    return "https://" + global.util.activeHost + url;
  },
  getClusterMembersHostNames() {
    if (global.util.isNull(global.util.sessionConfig)) {
      return undefined;
    }

    if (global.util.isNull(global.util.sessionConfig.clusterMembers)) {
      return undefined;
    }

    let clusterMembersHostNames = "";

    for (var clusterMember of global.util.sessionConfig.clusterMembers) {
      if (global.util.isNull(clusterMember.hostname)) {
        continue;
      }

      if (clusterMember.hostname == global.util.activeHost) {
        continue;
      }

      clusterMembersHostNames += clusterMember.hostname + ",";
    }

    if (global.util.isNull(clusterMembersHostNames)) {
      return undefined;
    }

    return clusterMembersHostNames;
  },
  getHeaders() {
    if (global.util.useTouchServices() || global.util.isTouchLoging()) {
      window.server.defaults.headers.common["application-user-agent"] =
        tegsoftConfig.TEGSOFT_TOUCH_USERAGENT;
      window.server.defaults.headers.common["application-date"] = Date.now();
      window.server.defaults.headers.common["application-key"] =
        tegsoftConfig.TEGSOFT_TOUCH_APPLICATIONKEY;
      window.server.defaults.headers.common["application-client-key"] =
        tegsoftConfig.TEGSOFT_TOUCH_CLIENTKEY;
    } else {
      window.server.defaults.headers.common["application-user-agent"] =
        "TegsoftVUE Client build 2022";
      window.server.defaults.headers.common["application-date"] = Date.now();
      window.server.defaults.headers.common["application-key"] =
        tegsoftConfig.TEGSOFT_VUE_CLIENTKEY;
      window.server.defaults.headers.common["application-client-key"] =
        "sampleClientKey";
    }
  },
  getActiveFormName: () => {
    if (!global.util.userconsoleForm) {
      return null;
    }

    if (global.util.userconsoleForm.formName == "statusPanel") {
      return global.util.userconsoleForm.formName;
    }

    if (!global.util.applications) {
      return null;
    }

    if (global.util.applications.length == 0) {
      return null;
    }

    if (global.util.applications.length == 1) {
      return global.util.applications[0].app?.name;
    }

    for (const application of global.util.applications) {
      if (
        application.itemId ==
        global.util.userconsoleForm.currentApplicationItemId
      ) {
        return application.app?.name;
      }
    }
    return null;
  },
  getApplication(applicationItemId) {
    var applicationIndex = global.util.applications.findIndex((element) => {
      return element.itemId == applicationItemId;
    });

    if (applicationIndex < 0) {
      return null;
    }

    return global.util.applications[applicationIndex];
  },
  isNull: (object) => {
    if (typeof object == "undefined") {
      return true;
    }
    if (object == null) {
      return true;
    }
    if (object === "") {
      return true;
    }

    return false;
  },
  isNotNull: (object) => {
    // Type error return !this.isNull(object);
    if (typeof object == "undefined") {
      return false;
    }
    if (object == null) {
      return false;
    }
    if (object == "") {
      return false;
    }

    return true;
  },
  touchLogout() {
    localStorage.removeItem("loginToken");
    localStorage.removeItem("loginData");
    global.util.user = undefined;
    global.util.telescopeUser = undefined;
    if (global.util.appLogin) {
      global.util.activeHost = undefined;
      global.util.appLogin = undefined;
    }
    global.util.loginServiceName = undefined;
    global.util.token = undefined;
    global.util.activeHost = undefined;
    global.util.appLogin = undefined;
    router.push("/logout");
  },
  logout: (isTegsoftWidget) => {
    localStorage.clear();
    global.util.deactivatePresenceServlet();
    if (global.util.webrtcPhone) {
      global.util.webrtcPhone.stopAnnounceSound();
      if (global.util.webrtcPhone.userAgent) {
        global.util.webrtcPhone.userAgent.stop();
      }
      global.util.webrtcPhone = undefined;
    }
    global.util.get(
      tegsoftConfig.LoginServlet + "login&service=performLogout",
      global.util.userconsoleForm
    );
    global.util.systemDialogues?.closeError();
    global.util.selectedSessionType = undefined;
    global.util.user = undefined;
    if (global.util.appLogin) {
      global.util.activeHost = undefined;
      global.util.appLogin = undefined;
    }
    global.util.token = undefined;
    global.util.logoutCalled = true;
    //global.util.systemDialogues = undefined;
    global.util.userconsoleForm = undefined;
    global.util.agentForm = null;
    global.util.applications = [];
    global.util.menu = [];

    var id = window.setTimeout(function () { }, 0);
    while (id--) {
      window.clearTimeout(id);
    }
    window.onbeforeunload = undefined;
    if (typeof isTegsoftWidget != "undefined" && isTegsoftWidget == true) {
      router.push("/tegsoftWidgetLogout");
    } else {
      router.push("/logout");
    }
  },
  logoutFromAllClusterMembers(authenticationParameters) {
    if (global.util.isNull(global.util.sessionConfig)) {
      return;
    }

    if (global.util.isNull(global.util.sessionConfig.clusterMembers)) {
      return;
    }

    if (global.util.isNull(authenticationParameters)) {
      return;
    }

    if (global.util.isNotNull(authenticationParameters.token)) {
      global.util.token = authenticationParameters.token;

      let promises = [];

      for (var clusterMember of global.util.sessionConfig.clusterMembers) {
        if (global.util.isNull(clusterMember.hostname)) {
          continue;
        }
        promises.push(
          global.util.get(
            "https://" +
            clusterMember.hostname +
            tegsoftConfig.LoginServlet +
            "login&service=performLogout",
            null,
            {
              ignoreError: true,
            }
          )
        );
      }

      Promise.all(promises).then(() => { });
      return;
    }

    if (
      global.util.isNull(authenticationParameters.usercode) ||
      global.util.isNull(authenticationParameters.password)
    ) {
      return;
    }

    let promises = [];
    promises.push(
      global.util
        .post(
          tegsoftConfig.LoginServlet + "&service=crypt",
          { text: authenticationParameters.password },
          null,
          {
            ignoreError: true,
          }
        )
        .then((response) => {
          let encodedPassword = response.data.encoded;

          for (var clusterMember of global.util.sessionConfig.clusterMembers) {
            if (global.util.isNull(clusterMember.hostname)) {
              continue;
            }
            promises.push(
              global.util.get(
                "https://" +
                clusterMember.hostname +
                tegsoftConfig.LoginServlet +
                "login&service=performLogout&usercode=" +
                this.usercode +
                "&password=" +
                encodedPassword,
                null,
                {
                  ignoreError: true,
                }
              )
            );
          }
        })
    );

    Promise.all(promises).then(() => { });
    return;
  },
  logoutFromActiveHost(authenticationParameters) {
    if (global.util.isNotNull(global.util.sessionConfig)) {
      return;
    }

    if (global.util.isNull(authenticationParameters)) {
      return;
    }

    if (global.util.isNotNull(authenticationParameters.token)) {
      global.util.token = authenticationParameters.token;

      global.util.get(
        tegsoftConfig.LoginServlet + "login&service=performLogout",
        null,
        {
          ignoreError: true,
        }
      );
      return;
    }

    if (
      global.util.isNull(authenticationParameters.usercode) ||
      global.util.isNull(authenticationParameters.password)
    ) {
      return;
    }

    global.util
      .post(
        tegsoftConfig.LoginServlet + "&service=crypt",
        { text: authenticationParameters.password },
        null,
        {
          ignoreError: true,
        }
      )
      .then((response) => {
        let encodedPassword = response.data.encoded;

        global.util.get(
          tegsoftConfig.LoginServlet +
          "login&service=performLogout&usercode=" +
          this.usercode +
          "&password=" +
          encodedPassword,
          null,
          {
            ignoreError: true,
          }
        );
      });
    return;
  },
  presenceInterval: null,
  trendIntervalCounter: 0,
  activatePresenceServlet() {
    if (global.util.presenceInterval) {
      return;
    }
    global.util.presenceInterval = setInterval(() => {
      if (global.util.presenceServletPending) {
        return;
      }

      if (global.util.user == undefined) {
        return;
      }

      if (global.util.user == null) {
        return;
      }

      let isWaitingContactsUnlimited = false;
      if (
        global.util.userconsoleForm.currentApplicationItemId == "Menu-CC-STATUS"
      ) {
        if (
          global.util.userconsoleForm.defaultWaitingQueueLayout == "queueColumn"
        ) {
          isWaitingContactsUnlimited = true;
        }
      }

      global.util.getHeaders();
      global.util.presenceServletPending = true;
      global.util
        .get(
          tegsoftConfig.PresenceServlet +
          (isWaitingContactsUnlimited == true
            ? "&waitingContactsLimit=50"
            : "")
        )
        .then((response) => {
          if (response.data == undefined) {
            global.util.presenceServletPending = false;
            return;
          }

          if (response.data.forceLogout == true) {
            global.util.logout();
          } else if (response.data.loginState == false) {
            global.util.performSwitch();
          }
          global.util.presenceServletPending = false;
          if (response.data.busySkills) {
            global.util.userconsoleForm.busySkills.length = 0;
            global.util.userconsoleForm.busySkills.push(
              ...response.data.busySkills
            );
          } else {
            global.util.userconsoleForm.busySkills.length = 0;
          }
          if (response.data.waitingContacts) {
            global.util.userconsoleForm.waitingContacts.length = 0;
            global.util.userconsoleForm.waitingContacts.push(
              ...response.data.waitingContacts
            );
          } else {
            global.util.userconsoleForm.waitingContacts.length = 0;
          }
          if (response.data.backgroundOperation) {
            global.util.userconsoleForm.backgroundOperations.length = 0;
            global.util.userconsoleForm.backgroundOperations.push(
              response.data.backgroundOperation
            );
          } else {
            global.util.userconsoleForm.backgroundOperations.length = 0;
          }

          global.util.prepareGroupedWaitingContacts();
          if (global.util.trendIntervalCounter == 0) {
            Object.keys(
              global.util.userconsoleForm.groupedWaitingContacts
            ).forEach((skillKey) => {
              global.util.calculateTrendOfWaitingTime(skillKey);
            });

            Object.keys(
              global.util.userconsoleForm.waitingContactsTrendStore
            ).forEach((skillKey) => {
              if (
                global.util.userconsoleForm.groupedWaitingContacts[skillKey] ==
                undefined
              ) {
                delete global.util.userconsoleForm.waitingContactsTrendStore[
                  skillKey
                ];
              }
            });
          }
          if (global.util.trendIntervalCounter >= 10) {
            global.util.trendIntervalCounter = 0;
          } else {
            global.util.trendIntervalCounter++;
          }
        })
        .catch((err) => {
          global.util.presenceServletPending = false;
        });
    }, 1000);
    window.addEventListener("resize", global.util.resizeHandler);
  },
  deactivatePresenceServlet() {
    if (global.util.presenceInterval) {
      clearInterval(global.util.presenceInterval);
      global.util.presenceServletPending = false;
      global.util.presenceInterval = undefined;
    }
  },
  prepareGroupedWaitingContacts() {
    global.util.userconsoleForm.groupedWaitingContacts = {};
    if (global.util.userconsoleForm.waitingContacts.length > 0) {
      global.util.userconsoleForm.busySkills.forEach((skill, index) => {
        let contactsForSkill =
          global.util.userconsoleForm.waitingContacts.filter((contact) => {
            if (contact.SKILL) {
              return contact.SKILL == skill.SKILL;
            }
            if (contact.SKILLID) {
              return contact.SKILLID == skill.SKILL;
            }
            return contact.SKILLNAME == skill.SKILLNAME;
          });
        if (contactsForSkill.length > 0) {
          const skillKey = global.util.getSkillKey(skill);
          global.util.userconsoleForm.groupedWaitingContacts[skillKey] =
            contactsForSkill.slice(0, 50);
        }
      });
    }
    if (typeof global.util.userconsoleForm.groupedWaitingContacts == "object") {
      Object.keys(global.util.userconsoleForm.groupedWaitingContacts).forEach(
        (key) => {
          global.util.userconsoleForm.groupedWaitingContacts[key] =
            global.util.userconsoleForm.groupedWaitingContacts[key].map(
              (contact) => {
                if (contact.callerIdName?.includes("AA-")) {
                  contact.callerIdName = contact.callerIdName.replace(
                    "AA-",
                    ""
                  );
                }
                if (
                  contact.callerIdName &&
                  global.util.isNull(contact.numberValue)
                ) {
                  const slpittedName = contact.callerIdName.split(" <");
                  contact.callerIdName = slpittedName[0];
                  contact.numberValue = "<" + slpittedName[1];
                }
                if (contact.EMAIL?.includes("@anonymous.com")) {
                  contact.EMAIL = "_" + "@anonymous.com";
                }
                return contact;
              }
            );
        }
      );
    }
  },
  getSkillKey(skill) {
    if (skill.SKILLID) {
      return skill.SKILLID.replace(/ /g, "_").replace(/-/g, "_");
    }

    if (skill.SKILL) {
      return skill.SKILL.replace(/ /g, "_").replace(/-/g, "_");
    }

    return skill.SKILLNAME.replace(/ /g, "_").replace(/-/g, "_");
  },
  calculateTrendOfWaitingTime(skillKey) {
    let currentContactsLength = 0;
    if (
      global.util.isNotNull(
        global.util.userconsoleForm.groupedWaitingContacts[skillKey]
      )
    ) {
      currentContactsLength =
        global.util.userconsoleForm.groupedWaitingContacts[skillKey].length;
    }

    if (currentContactsLength <= 0) {
      return 0;
    }

    const firstContactInQueue =
      global.util.userconsoleForm.groupedWaitingContacts[skillKey][
      currentContactsLength - 1
      ];
    if (
      global.util.userconsoleForm.waitingContactsTrendStore[skillKey] ==
      undefined
    ) {
      global.util.userconsoleForm.waitingContactsTrendStore[skillKey] = {
        count: currentContactsLength,
        waitDurationSeconds: firstContactInQueue.waitDurationSeconds,
      };
    }
    if (
      global.util.userconsoleForm.waitingContactsTrendStore[skillKey]
        .trendingTrack == undefined
    ) {
      if (firstContactInQueue.waitDurationSeconds <= 20) {
        global.util.userconsoleForm.waitingContactsTrendStore[
          skillKey
        ].trendingTrack = -1;
      } else {
        global.util.userconsoleForm.waitingContactsTrendStore[
          skillKey
        ].trendingTrack = 1;
      }
    }

    if (firstContactInQueue.waitDurationSeconds <= 40) {
      global.util.userconsoleForm.waitingContactsTrendStore[
        skillKey
      ].trendingTrack = 0;
    } else {
      if (
        global.util.userconsoleForm.waitingContactsTrendStore[skillKey].count >
        currentContactsLength
      ) {
        global.util.userconsoleForm.waitingContactsTrendStore[
          skillKey
        ].trendingTrack = -1;
      } else if (
        global.util.userconsoleForm.waitingContactsTrendStore[skillKey].count <
        currentContactsLength
      ) {
        global.util.userconsoleForm.waitingContactsTrendStore[
          skillKey
        ].trendingTrack = 1;
      } else if (
        global.util.userconsoleForm.waitingContactsTrendStore[skillKey].count ==
        currentContactsLength
      ) {
        if (
          firstContactInQueue.waitDurationSeconds >
          global.util.userconsoleForm.waitingContactsTrendStore[skillKey]
            .waitDurationSeconds
        ) {
          global.util.userconsoleForm.waitingContactsTrendStore[
            skillKey
          ].trendingTrack = 1;
        } else if (
          firstContactInQueue.waitDurationSeconds <
          global.util.userconsoleForm.waitingContactsTrendStore[skillKey]
            .waitDurationSeconds
        ) {
          global.util.userconsoleForm.waitingContactsTrendStore[
            skillKey
          ].trendingTrack = -1;
        } else {
          global.util.userconsoleForm.waitingContactsTrendStore[
            skillKey
          ].trendingTrack = 0;
        }
      }
    }

    global.util.userconsoleForm.waitingContactsTrendStore[
      skillKey
    ].waitDurationSeconds = firstContactInQueue.waitDurationSeconds;
    global.util.userconsoleForm.waitingContactsTrendStore[skillKey].count =
      currentContactsLength;
  },

  clusterStatusCheckInterval: null,
  activateClusterStatusCheck() {
    if (global.util.clusterStatusCheckInterval) {
      return;
    }
    global.util.clusterStatusCheckInterval = setInterval(() => {
      global.util.clusterStatusCheck();
    }, 10000);
  },
  clusterStatusCheck() {
    if (global.util.isNull(global.util.sessionConfig)) {
      console.error("Invalid session config");
      global.util.sessionConfigCheckFailed = true;
      return;
    }
    if (global.util.isNull(global.util.sessionConfig.clusterMembers)) {
      console.error("Invalid session config check clusterMembers ");
      global.util.sessionConfigCheckFailed = true;
      return;
    }

    for (var clusterMember of global.util.sessionConfig.clusterMembers) {
      if (global.util.isNull(clusterMember.hostname)) {
        continue;
      }

      clusterMember.serverStatusUrl =
        "https://" + clusterMember.hostname + tegsoftConfig.ServerStatus;
      clusterMember.memberStatus = "Pending";

      global.util
        .get(clusterMember.serverStatusUrl, null, {
          ignoreError: true,
        })
        .then((response) => {
          let clusterMember = global.util.sessionConfig.clusterMembers.find(
            (x) => x.serverStatusUrl == response.config.url
          );
          if (global.util.isNull(clusterMember)) {
            return;
          }

          if (clusterMember.memberStatusLog == undefined) {
            clusterMember.memberStatusLog = true;
            console.warn(
              "Cluster member " + clusterMember.hostname + " is ready."
            );
          }

          clusterMember = Object.assign(clusterMember, response.data);
          clusterMember.memberStatus = "OK";
          clusterMember.failCount = 0;
        })
        .catch((error) => {
          let clusterMember = global.util.sessionConfig.clusterMembers.find(
            (x) => x.serverStatusUrl == error.url
          );
          if (global.util.isNull(clusterMember)) {
            return;
          }

          if (clusterMember.memberStatusLog == undefined) {
            clusterMember.memberStatusLog = true;
            console.warn(
              "Cluster member " + clusterMember.hostname + " failed."
            );
          }

          clusterMember.memberStatus = "FAILED";
          if (!clusterMember.failCount) {
            clusterMember.failCount = 1;
          } else {
            clusterMember.failCount++;
          }

          if (global.util.activeHost == clusterMember.hostname) {
            if (
              clusterMember.failCount >=
              global.util.sessionConfig.failoverSwitch.timeoutCount
            ) {
              global.util.setActiveClusterHost(
                global.util.sessionConfig.failoverSwitch.rule
              );
              console.warn(
                "Failover ACTION: New Active cluster member selected ",
                global.util.activeHost
              );
            }
          }
        });
    }
  },
  deactivateClusterStatusCheck() {
    if (global.util.clusterStatusCheckInterval) {
      clearInterval(global.util.clusterStatusCheckInterval);
      global.util.presenceServletPending = false;
      global.util.clusterStatusCheckInterval = undefined;
    }
  },
  async setActiveClusterHost(switchRule) {
    if (this.isAllClusterMembersQualified() == false) {
      console.warn("Cluster members are not ready waiting more...");
      await global.util.delay(500);
    }

    if (this.isAllClusterMembersQualified() == false) {
      console.warn("Cluster members are not ready waiting more...");
      await global.util.delay(1000);
    }

    if (this.isAllClusterMembersQualified() == false) {
      console.warn("Cluster members are not ready waiting 5 seconds more...");
      await global.util.delay(5000);
    }

    switch (switchRule) {
      case "None":
        global.util.sessionConfigCheckDone = true;
        return;
      case "Priority":
        global.util.sessionConfig.clusterMembers.sort(
          (a, b) => Number(a.priority) - Number(b.priority)
        );
        break;
      case "LoginCount":
        global.util.sessionConfig.clusterMembers.sort((a, b) =>
          Number(a.priority) - Number(b.priority) != 0
            ? Number(a.priority) - Number(b.priority)
            : Number(a.loginAgentCount) - Number(b.loginAgentCount)
        );
        break;
      case "CallCount":
        global.util.sessionConfig.clusterMembers.sort((a, b) =>
          Number(a.priority) - Number(b.priority) != 0
            ? Number(a.priority) - Number(b.priority)
            : Number(a.activeChannels) - Number(b.activeChannels)
        );
        break;
      case "WorkLoad":
        global.util.sessionConfig.clusterMembers.sort((a, b) =>
          Number(a.priority) - Number(b.priority) != 0
            ? Number(a.priority) - Number(b.priority)
            : Number(a.loadAvarage) - Number(b.loadAvarage)
        );
        break;
    }

    let firstActiveClusterMember = this.getFirstActiveClusterMember();
    if (global.util.isNull(firstActiveClusterMember)) {
      console.warn(
        "Active cluster member could not be found waiting 5 seconds more..."
      );
      await global.util.delay(5000);
    }
    firstActiveClusterMember = this.getFirstActiveClusterMember();
    if (global.util.isNull(firstActiveClusterMember)) {
      console.error("Active cluster member could not be found!");
      global.util.sessionConfigCheckDone = true;
      return;
    }
    global.util.activeHost = firstActiveClusterMember.hostname;
    global.util.sessionConfigCheckDone = true;
    console.warn(
      "Active cluster member selected ",
      firstActiveClusterMember.hostname,
      switchRule
    );

    if (global.util.firstSwitch) {
      global.util.performSwitch();
    } else {
      global.util.firstSwitch = true;
    }
    return;
  },
  performSwitch() {
    if (global.util.switchPending) {
      return;
    }
    global.util.switchPending = true;
    global.util
      .get(
        global.util.userconsoleForm.$tegsoftConfig.LoginServlet +
        global.util.userconsoleForm.$options.name +
        "&service=performLogin&locale=" +
        global.util.language,
        global.util.userconsoleForm,
        {
          ignoreError: true,
        }
      )
      .then((response) => {
        global.util.user = response.data.user;
        global.util.token = response.data.token;
        cookie.setCookie("TOBE_LOGIN_UID", response.data.user.UID);
        cookie.setCookie("TOBE_SESSIONID", response.data.user.sessionid);

        global.util.userconsoleForm.SERVERNAME = global.util.user.SERVERNAME;

        let notificationMessage =
          "Server switched to " + global.util.activeHost;
        if (global.util.activeHost) {
          if (global.util.webrtcPhone) {
            global.util.webrtcPhone.restart(global.util.activeHost);
          }
        } else {
          notificationMessage = "Connection recovered.";
        }

        global.util.userconsoleForm.toastNotificationMessage =
          notificationMessage;
        global.util.userconsoleForm.toastNotificationDisplay = true;
        setTimeout(() => {
          global.util.userconsoleForm.toastNotificationDisplay = false;
        }, 3000);

        if (global.util.agentForm) {
          global.util
            .get(
              global.util.agentForm.$tegsoftConfig.LoginServlet +
              global.util.agentForm.$options.name +
              "&service=performWidgetLogin" +
              "&locale=" +
              global.util.language +
              "&sessionType=" +
              global.util.selectedSessionType,
              global.util.agentForm,
              {
                ignoreError: true,
              }
            )
            .then((response) => {
              global.util.switchPending = false;
              if (response.data?.success == false) {
                return;
              }
            })
            .catch(() => {
              global.util.switchPending = false;
            });
        } else {
          global.util.switchPending = false;
        }
      })
      .catch(() => {
        global.util.switchPending = false;
      });
  },
  getFirstActiveClusterMember() {
    for (var clusterMember of global.util.sessionConfig.clusterMembers) {
      if (global.util.isNull(clusterMember.hostname)) {
        continue;
      }
      if (clusterMember.memberStatus != "OK") {
        continue;
      }
      return clusterMember;
    }
  },
  isAllClusterMembersQualified() {
    for (var clusterMember of global.util.sessionConfig.clusterMembers) {
      if (global.util.isNull(clusterMember.hostname)) {
        continue;
      }
      if (clusterMember.memberStatus == "OK") {
        continue;
      }
      if (clusterMember.memberStatus == "FAILED") {
        continue;
      }
      return false;
    }
    return true;
  },

  resizeHandler(event) { },

  delay(milliseconds) {
    return new Promise((resolve) => {
      setTimeout(resolve, milliseconds);
    });
  },

  convertHMS(value) {
    if (!value) {
      return "00:00";
    }
    const sec = parseInt(value, 10); // convert value to number if it's string
    let hours = Math.floor(sec / 3600); // get hours
    let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
    let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
    // add 0 if value < 10; Example: 2 => 02
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    if (hours == "00") {
      return minutes + ":" + seconds; // Return is HH : MM : SS
    }
    return hours + ":" + minutes + ":" + seconds; // Return is HH : MM : SS
  },
  mergeDeep(target, source) {
    if (!this.isObject(target) || !this.isObject(source)) {
      return;
    }

    try {
      for (const key in source) {
        if (this.isObject(source[key])) {
          if (!target[key]) {
            Object.assign(target, { [key]: {} });
          }
          this.mergeDeep(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    } catch (exception) {
      console.error(exception);
    }
  },

  compare(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects =
        this.isNotNullObject(val1) && this.isNotNullObject(val2);
      if (
        (areObjects && !this.compare(val1, val2)) ||
        (!areObjects && val1 !== val2)
      ) {
        return false;
      }
    }
    return true;
  },

  isNotNullObject(object) {
    return object != null && typeof object === "object";
  },

  isObject(item) {
    return item && typeof item === "object" && !Array.isArray(item);
  },

  toSeconds(str) {
    if (!str) return 0;
    str = str.split(":");
    return Number(+str[0]) * 60 + Number(+str[1]);
  },

  toHHss(seconds) {
    if (isNaN(seconds)) {
      seconds = 0;
    }
    let minutes = parseInt(seconds / 60);
    seconds = parseInt(seconds - minutes * 60);
    return (
      (minutes < 10 ? "0" + minutes : minutes) +
      ":" +
      (seconds < 10 ? "0" + seconds : seconds)
    );
  },

  date: {
    formatDateTime(input) {
      let date;

      if (typeof input === 'string' || input instanceof String) {
        date = new Date(input);
      } else if (input instanceof Date) {
        date = input;
      } else {
        throw new Error("Invalid date format for input: " + input);
      }

      if (isNaN(date)) {
        throw new Error("Invalid date format for input: " + input);
      }

      let year = date.getFullYear();
      let month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      let day = String(date.getDate()).padStart(2, '0');

      let hours = String(date.getHours()).padStart(2, '0');
      let minutes = String(date.getMinutes()).padStart(2, '0');
      let seconds = String(date.getSeconds()).padStart(2, '0');

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    formatForDateInput(date) {
      let d = new Date(date);
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      let year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      return [year, month, day].join('-');
    },
    addSeconds(date, seconds) {
      let formattedDate = this.formatDateTime(date);

      var result = new Date(formattedDate);
      result.setSeconds(result.getSeconds() + seconds);
      return result;
    },
    addMinutes(date, minutes) {
      let formattedDate = this.formatDateTime(date);

      var result = new Date(formattedDate);
      result.setMinutes(result.getMinutes() + minutes);
      return result;
    },
    addHours(date, hours) {
      let formattedDate = this.formatDateTime(date);

      var result = new Date(formattedDate);
      result.setHours(result.getHours() + hours);
      return result;
    },
    addDays(date, days) {
      let formattedDate = this.formatDateTime(date);

      var result = new Date(formattedDate);
      result.setDate(result.getDate() + days);

      return result;
    },
    addMonths(date, months) {
      let formattedDate = this.formatDateTime(date);

      var result = new Date(formattedDate);
      result.setMonth(result.getMonth() + months);
      return result;
    },
    addYears(date, years) {
      let formattedDate = this.formatDateTime(date);

      var result = new Date(formattedDate);
      result.setFullYear(result.getFullYear() + years);
      return result;
    },
    getDifferenceInDays(date1, date2, dontGetAbs) {
      let formattedDate1 = this.formatDateTime(date1);
      let formattedDate2 = this.formatDateTime(date2);

      let diffTime = dontGetAbs ?
        new Date(formattedDate2) - new Date(formattedDate1) :
        Math.abs(new Date(formattedDate2) - new Date(formattedDate1));

      return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    },
    today() {
      var date = new Date();
      var month = "" + (date.getMonth() + 1);
      var day = "" + date.getDate();
      var year = date.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    now() {
      var date = new Date();
      var month = "" + (date.getMonth() + 1);
      var day = "" + date.getDate();
      var year = date.getFullYear();

      var hours = date.getHours();
      var minutes = date.getMinutes();
      var seconds = date.getSeconds();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      if (hours.length < 2) hours = "0" + hours;
      if (minutes.length < 2) minutes = "0" + minutes;
      if (seconds.length < 2) seconds = "0" + seconds;

      return (
        [year, month, day].join("-") + " " + [hours, minutes, seconds].join(":")
      );
    },
    isAfter(date1, date2) {
      let formattedDate1 = this.formatDateTime(date1);
      let formattedDate2 = this.formatDateTime(date2);

      return new Date(formattedDate1) > new Date(formattedDate2);
    },
    isBefore(date1, date2) {
      let formattedDate1 = this.formatDateTime(date1);
      let formattedDate2 = this.formatDateTime(date2);

      return new Date(formattedDate1) < new Date(formattedDate2);
    },
  },

  // Left for compatibility. Use "date" instead.
  today() {
    var date = new Date();
    var month = "" + (date.getMonth() + 1);
    var day = "" + date.getDate();
    var year = date.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  },
  now() {
    var date = new Date();
    var month = "" + (date.getMonth() + 1);
    var day = "" + date.getDate();
    var year = date.getFullYear();

    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    if (hours.length < 2) hours = "0" + hours;
    if (minutes.length < 2) minutes = "0" + minutes;
    if (seconds.length < 2) seconds = "0" + seconds;

    return (
      [year, month, day].join("-") + " " + [hours, minutes, seconds].join(":")
    );
  },
  roundToFixedDecimal(inputValue, digits) {
    if (!inputValue) return 0;
    if (Number.isInteger(inputValue)) return inputValue;
    let rounded = Math.pow(10, digits);

    if (isNaN((Math.round(inputValue * rounded) / rounded).toFixed(digits)))
      return 0;
    return (Math.round(inputValue * rounded) / rounded).toFixed(digits);
  },
  isValidPhoneNumber(phoneNumber) {
    /** ACCEPTS THE FOLLOWING FORMATS:
     * (123) 456-7890, +(123) 456-7890, +(123)-456-7890, +(123) - 456-7890, +(123) - 456-78-90,
     * 123-456-7890, 123.456.7890, 1234567890, +31636363634, 075-63546725 */
    return /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g.test(phoneNumber);
  },
  isValidEmail(email) {
    return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
  },
  download(url) {
    window.open(url, "Download").focus();
  },
  saveAs(url) {
    var filename = url.substring(url.lastIndexOf("/") + 1).split("?")[0];
    var xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.onload = function () {
      var a = document.createElement("a");
      a.href = window.URL.createObjectURL(xhr.response);
      a.download = filename;
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      a.remove();
    };
    xhr.open("GET", url);
    xhr.send();
  },

  formatMessage(str, arr) {
    const args = { ...arr };
    return str.replace(/({\d})/g, (i) => {
      return args[i.replace(/{/, "").replace(/}/, "")];
    });
  },
  initiateDragAndDropArea(selectorID, callbackFunc) {
    let dropArea = document.getElementById(selectorID);
    if (dropArea) {
      ["dragenter", "dragover", "dragleave", "drop"].forEach((eventName) => {
        dropArea.addEventListener(
          eventName,
          (e) => {
            e.preventDefault();
            e.stopPropagation();
          },
          false
        );
      });
      ///indicateDragAndDrop
      ["dragenter", "dragover"].forEach((eventName) => {
        dropArea.addEventListener(
          eventName,
          () => {
            dropArea.classList.add("drag-in-file");
          },
          false
        );
      });
      ["dragleave", "drop"].forEach((eventName) => {
        dropArea.addEventListener(
          eventName,
          () => {
            dropArea.classList.remove("drag-in-file");
          },
          false
        );
      });
      dropArea.addEventListener(
        "drop",
        (e) => {
          let dt = e.dataTransfer;
          let files = dt.files;
          if (typeof callbackFunc != "undefined") {
            callbackFunc(files[0], true);
          }
        },
        false
      );
    }
  },
  getBrowserID() {
    var Sys = {};
    var ua = navigator.userAgent.toLowerCase();
    var s;
    (s = ua.match(/msie ([\d.]+)/))
      ? (Sys.ie = s[1])
      : (s = ua.match(/firefox\/([\d.]+)/))
        ? (Sys.firefox = s[1])
        : (s = ua.match(/chrome\/([\d.]+)/))
          ? (Sys.chrome = s[1])
          : (s = ua.match(/opera.([\d.]+)/))
            ? (Sys.opera = s[1])
            : (s = ua.match(/version\/([\d.]+).*safari/))
              ? (Sys.safari = s[1])
              : 0;

    if (Sys.ie) return "IE:" + Sys.ie;
    if (Sys.firefox) return { brand: "Firefox:", id: Sys.firefox };
    if (Sys.chrome) return { brand: "Chrome:", id: Sys.chrome };
    if (Sys.opera) return { brand: "Opera:", id: Sys.opera };
    if (Sys.safari) return { brand: "Safari:", id: Sys.safari };
  },

  checkAccess(ACCESSID) {
    if (global.util.isNull(global.util.user)) {
      return false;
    }

    if (global.util.isNull(global.util.user.ACCESSLIST)) {
      return false;
    }

    if (global.util.user.ACCESSLIST["Access_ALL"] != undefined) {
      return true;
    }

    if (global.util.user.ACCESSLIST["Access-ALL"] != undefined) {
      return true;
    }

    ACCESSID = ACCESSID.replaceAll("-", "_");
    if (global.util.user.ACCESSLIST[ACCESSID] != undefined) {
      return true;
    }
    return false;
  },

  relativeTime(date, isUtc = true) {
    /**
     * THIS METHOD PROCESS param "date" and COMPARE WITH NOW_DATE()
     * RETURNED DATA FORMAT EXAMPLE: { time: 2, timeName: MONTHS_AGO}
     * EXAMPLE RETURNED DATA USAGE: Just now, 1 second ago, 1 month ago, 2 years ago,
     */
    const relativeTimePeriods = [
      [31536000, "YEAR"],
      [2419200, "MONTH"],
      [604800, "WEEK"],
      [86400, "DAY"],
      [3600, "HOUR"],
      [60, "MINUTE"],
      [1, "SECOND"],
    ];

    const seconds = (new Date() - new Date(date)) / 1000;
    for (let [secondsPer, name] of relativeTimePeriods) {
      if (seconds >= secondsPer) {
        const amount = Math.floor(seconds / secondsPer);
        return {
          time: amount,
          timeName: `${name}${amount > 1 ? "S" : ""}_AGO`,
        };
      }
    }
    return { time: "", timeName: "JUST_NOW" };
  },

  parseQueryParameters(queryParameters) {
    var uri = window.location.href.split("?");
    if (uri.length == 2) {
      let vars = uri[1].split("&");
      let tmp = "";
      vars.forEach(function (v) {
        tmp = v.split("=");
        if (tmp.length == 2) {
          queryParameters[tmp[0]] = tmp[1];
        }
      });
    }
  },

  isIOS() {
    const browserInfo = navigator.userAgent.toLowerCase();

    if (browserInfo.match("iphone") || browserInfo.match("ipad")) {
      return true;
    }
    if (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform)
    ) {
      return true;
    }
    return false;
  },

  isTouchLoging() {
    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("fileName") == "forms/TegsoftOS/login/appLoginPage") {
      return true;
    }
    return false;
  },

  useTouchServices() {
    if (global.util.isNotNull(global.util.activeHost)) {
      return false;
    }
    if (global.util.appLogin == true) {
      return false;
    }
    if (window.location?.href?.includes("localhost:")) {
      if (this.isNull(app?.process?.env?.VUE_APP_TARGET)) {
        return true;
      }
      if (app?.process?.env?.VUE_APP_TARGET == "touch") {
        return true;
      }
    }
    if (global.util.isTouchServiceLocation()) {
      return true;
    }
    return false;
  },

  isTouchServiceLocation() {
    if (global.util.isAppLocalServiceLocation()) {
      return true;
    }
    if (window.location.protocol != "https:") {
      return false;
    }
    if (window.location?.href?.includes("console.tegsoft.com")) {
      return true;
    }
    if (global.util.isAppServiceLocation()) {
      return true;
    }
    if (global.util.isTelescopeServiceLocation()) {
      return true;
    }
    return false;
  },
  isAppLocalServiceLocation() {
    if (window.location?.href?.includes("applocal.tegsoft.com")) {
      return true;
    }
    if (window.location?.href?.includes("app17.tegsoft.com")) {
      return true;
    }
    if (window.location?.href?.includes("app17.tegsoftcloud.com")) {
      return true;
    }
    return false;
  },
  isAppServiceLocation() {
    if (!window.location.protocol == "https:") {
      return false;
    }
    if (window.location?.href?.includes("app.tegsoft.com")) {
      return true;
    }
    if (window.location?.href?.includes("app17.tegsoft.com")) {
      return true;
    }
    if (window.location?.href?.includes("app28.tegsoftcloud.com")) {
      return true;
    }
    return false;
  },
  isTelescopeServiceLocation() {
    if (!window.location.protocol == "https:") {
      return false;
    }
    if (window.location?.href?.includes("telescope.tegsoft.com")) {
      return true;
    }
    if (window.location?.href?.includes("telescope28.tegsoft.com")) {
      return true;
    }
    if (window.location.search?.includes("telescopeLogin")) {
      return true;
    }
    return false;
  },

  isMobile() {
    if (
      /iPhone|iPod|Android|IEMobile|BlackBerry|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      return true;
    }
    return false;
  },

  installApp() {
    // Code to handle install prompt on desktop
    let deferredPrompt;
    const addBtn = document.getElementById("addToHomeScreenId");
    if (addBtn) {
      window.addEventListener("beforeinstallprompt", (e) => {
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        e.preventDefault();
        // Stash the event so it can be triggered later.
        deferredPrompt = e;
        // Update UI to notify the user they can add to home screen
        addBtn.style.display = "block";

        addBtn.addEventListener("click", () => {
          // hide our user interface that shows our A2HS button
          addBtn.style.display = "none";
          // Show the prompt
          deferredPrompt.prompt();
          // Wait for the user to respond to the prompt
          deferredPrompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === "accepted") {
              console.log("User accepted the A2HS prompt");
            } else {
              console.log("User dismissed the A2HS prompt");
            }
            deferredPrompt = null;
          });
        });
      });
    }
  },
  uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  },
  capitalizedText(strText) {
    const arrText = strText.split(" ");
    for (var i = 0; i < arrText.length; i++) {
      arrText[i] = arrText[i].charAt(0).toUpperCase() + arrText[i].slice(1);
    }

    return arrText.join(" ");
  },

  initiatePageVisibilityAPI() {
    if (typeof document.hidden !== "undefined") {
      // Opera 12.10 and Firefox 18 and later support
      global.util.pageWindow.hidden = "hidden";
      global.util.pageWindow.visibilityChange = "visibilitychange";
    } else if (typeof document.msHidden !== "undefined") {
      global.util.pageWindow.hidden = "msHidden";
      global.util.pageWindow.visibilityChange = "msvisibilitychange";
    } else if (typeof document.webkitHidden !== "undefined") {
      global.util.pageWindow.hidden = "webkitHidden";
      global.util.pageWindow.visibilityChange = "webkitvisibilitychange";
    }
    window.addEventListener("focus", function (event) {
      global.util.onVisible();
    });
    window.addEventListener("blur", function (event) {
      global.util.onHidden();
    });
    document.addEventListener("focus", function (event) {
      global.util.onVisible();
    });
    document.addEventListener("blur", function (event) {
      global.util.onHidden();
    });

    // Handle page visibility change
    document.addEventListener(
      global.util.pageWindow.visibilityChange,
      function () {
        if (document.visibilityState === "hidden") {
          global.util.onHidden();
        } else {
          global.util.onVisible();
        }
      },
      false
    );
  },
  onHidden() {
    global.util.pageWindow.screenVisible = false;
    if (global.util.agentForm?.webchatUpdateAgentWritingStatus) {
      global.util.agentForm.webchatUpdateAgentWritingStatus(false);
    }
    if (global.util.ccchatForm?.webchatUpdateContactWritingStatus) {
      global.util.ccchatForm.webchatUpdateContactWritingStatus(false);
    }
  },
  onVisible() {
    global.util.pageWindow.screenVisible = true;
  },

  initiateUtil() {
    global.util.initiatePageVisibilityAPI();
  },
  goToMainPage(URLOptions, storageName, params) {
    if (URLOptions.includes("callbackurl")) {
      const urlParams = new URLSearchParams(URLOptions);
      let callbackurl = urlParams.get("callbackurl");

      if (global.util.isNotNull(storageName)) {
        localStorage.removeItem(storageName);
      }

      if (params) {
        if (callbackurl.includes("/?")) {
          callbackurl = callbackurl + "&" + params;
        } else {
          callbackurl = callbackurl + "/?" + params;
        }
      }
      window.location.href = callbackurl;
    }
  },
  pageIsLoadedInsideIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  },
  updateIframeLayout(layoutName) {
    window.parent.postMessage(layoutName, global.util.parentPageDomainName);
    /** layoutNames can be anyName,
     * This layoutPayload will be accessed on the thirt party/iframe side. */
    const layoutPayload = {
      type: "event",
      group: "design",
      name: "OnIframeLayoutChange",
      data: {
        layoutName: layoutName,
      },
    };
    window.parent?.postMessage(layoutPayload, global.util.parentPageDomainName);
  },

  encode(stringText) {
    // Encode the String
    return Buffer.from(stringText).toString("base64");
  },
  decode(encodedString) {
    // Decode the Encoded String
    const decodedText = Buffer.from(encodedString, "base64").toString();
    if (decodedText?.startsWith("AaC")) {
      return decodedText.replace(/^.{3}/g, "");
    }
    return decodedText;
  },
  refreshDestinationDatasetServlet(form, dataSourceName, DESTTYPE) {
    if (global.util.isNull(DESTTYPE)) {
      if (form?.db) {
        if (form.db[dataSourceName]?.rows) {
          form.db[dataSourceName].rows.length = 0;
          form.db[dataSourceName].fetchable = true;
          form.db[dataSourceName].currentRowIndex = 0;
        }
      }
      return;
    }

    global.util
      .get(
        form.$tegsoftConfig.DestinationDatasetServlet +
        form.name +
        "&dataSourceName=" +
        dataSourceName +
        "&DESTTYPE=" +
        DESTTYPE,
        form
      )
      .then((response) => {
        response.currentForm.db[dataSourceName].rows.length = 0;
        for (const dataRow of response.data.dataSource.rows) {
          response.currentForm.db[dataSourceName].rows.push(
            new DataRow(response.currentForm.db[dataSourceName], dataRow)
          );
        }
        response.currentForm.db[dataSourceName].fetchable =
          response.data.dataSource.fetchable;
        response.currentForm.db[dataSourceName].currentRowIndex = 0;
      });
  },
  refreshDestinationDatasetServletIntoTargetDataSource(
    form,
    dataSourceName,
    DESTTYPE,
    targetDataSourceName
  ) {
    if (global.util.isNull(DESTTYPE)) {
      if (form?.db) {
        if (form.db[targetDataSourceName]?.rows) {
          form.db[targetDataSourceName].rows.length = 0;
          form.db[targetDataSourceName].fetchable = true;
          form.db[targetDataSourceName].currentRowIndex = 0;
        }
      }
      return;
    }

    global.util
      .get(
        form.$tegsoftConfig.DestinationDatasetServlet +
        form.name +
        "&dataSourceName=" +
        dataSourceName +
        "&DESTTYPE=" +
        DESTTYPE,
        form
      )
      .then((response) => {
        response.currentForm.db[targetDataSourceName].rows.length = 0;
        for (const dataRow of response.data.dataSource.rows) {
          response.currentForm.db[targetDataSourceName].rows.push(
            new DataRow(response.currentForm.db[targetDataSourceName], dataRow)
          );
        }
        response.currentForm.db[targetDataSourceName].fetchable =
          response.data.dataSource.fetchable;
        response.currentForm.db[targetDataSourceName].currentRowIndex = 0;
      });
  },
  refreshDestinationDatasetServletIntoArray(
    form,
    dataSourceName,
    DESTTYPE,
    targetArray
  ) {
    if (targetArray == undefined) {
      return;
    }
    if (global.util.isNull(DESTTYPE)) {
      targetArray.length = 0;
      return;
    }

    global.util
      .get(
        form.$tegsoftConfig.DestinationDatasetServlet +
        form.name +
        "&dataSourceName=" +
        dataSourceName +
        "&DESTTYPE=" +
        DESTTYPE,
        form
      )
      .then((response) => {
        targetArray.length = 0;
        for (const dataRow of response.data.dataSource.rows) {
          targetArray.push(new DataRow(null, dataRow));
        }
      });
  },
  byteArrayImgToBase64(byteArray) {
    let charCodeArray = new Uint8Array(byteArray);
    let stringFromCharCodeArray = "";
    for (let index = 0; index < charCodeArray.length; index++) {
      stringFromCharCodeArray += String.fromCharCode(charCodeArray[index]);
    }
    return "data:image/jpg;base64," + btoa(stringFromCharCodeArray);
  },
  getLocaleDayString(day) {
    let dayString = "";
    switch (day) {
      case 1:
        dayString = "MONDAY";
        break;
      case 2:
        dayString = "TUESDAY";
        break;
      case 3:
        dayString = "WEDNESDAY";
        break;
      case 4:
        dayString = "THURSDAY";
        break;
      case 5:
        dayString = "FRIDAY";
        break;
      case 6:
        dayString = "SATURDAY";
        break;
      case 7:
        dayString = "SUNDAY";
        break;
      default:
        dayString = "";
    }
    return dayString;
  },
  storeDataWithLastUsageDate(key, value, name) {
    const storedData = {
      value: value,
      name: name,
      lastUsageTime: new Date().getTime(),
    };
    try {
      localStorage.setItem(key, JSON.stringify(storedData));
    } catch (ex) {
      window.parent?.postMessage(storedData, global.util.parentPageDomainName);
    }
  },
  getDataWithLastUsageDate(key, maxInactiveHours) {
    try {
      let storedData = localStorage.getItem(key);
      if (storedData) {
        storedData = JSON.parse(storedData);
        const lastUsageTime = storedData.lastUsageTime;
        if (
          lastUsageTime &&
          new Date().getTime() - lastUsageTime >
          maxInactiveHours * 60 * 60 * 1000
        ) {
          localStorage.removeItem(key);
          return "removed";
        }
        global.util.storeDataWithLastUsageDate(key, storedData.value);
        return { status: "notExpired", value: storedData.value };
      }
      return { name: "tgChat", status: "notFound" };
    } catch (ex) {
      return { name: "tgChat", status: "notFound" };
    }
  },
  removeParameterFromURL(parameterName) {
    var url = window.location.href;
    var urlObject = new URL(url);
    urlObject.searchParams.delete(parameterName);
    window.history.replaceState({}, document.title, urlObject.href);
  },
  removeRedundancy(arr, property) {
    const uniqueObjects = arr.reduce((uniqueMap, obj) => {
      const key = obj[property];
      if (!uniqueMap.has(key)) {
        uniqueMap.set(key, obj);
      }
      return uniqueMap;
    }, new Map());

    return Array.from(uniqueObjects.values());
  },
  isValidHttpUrl(string) {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  },
  groupChatMessagesByDate(chatMessages) {
    if (global.util.isNull(chatMessages)) {
      return [];
    }
    let groupedChat = {};
    /** Create array of object with a date key. e.g 16/022020:{..} */
    chatMessages.forEach((chatMessage) => {
      try {
        chatMessage.messageText = decodeURIComponent(chatMessage.messageText);
      } catch (ex) {
        chatMessage.messageText = chatMessage.messageText;
      }
      if (
        chatMessages.nameOfMessageOwner &&
        chatMessages.nameOfMessageOwner.includes("null")
      ) {
        chatMessages.nameOfMessageOwner = chatMessages.nameOfMessageOwner
          .replace("null", "")
          .trim();
      }
      //Date convertion resource https://www.w3schools.com/jsref/tryit.asp?filename=tryjsref_tolocalestring_date_all
      let date = new Date(chatMessage.messageDate);
      let chatDate = date.toLocaleString("de-DE");
      let time = chatDate.split(" ")[1];
      chatMessage["time"] = time.split(":")[0] + ":" + time.split(":")[1];
      // chatDate += " " + this.locale[global.util.getLocaleDayString(date.getDay())];
      chatDate = chatDate.split(",")[0];
      if (chatDate in groupedChat) {
        groupedChat[chatDate].push(chatMessage);
      } else {
        groupedChat[chatDate] = new Array(chatMessage);
      }
    });
    /** Edit groupedChat: to add it in the array format instead */
    let groupedChatMessageArray = Object.keys(groupedChat).map((date) => {
      return {
        date,
        chatMessages: groupedChat[date],
      };
    });
    groupedChatMessageArray = groupedChatMessageArray.map((data) => {
      data.chatMessages = data.chatMessages.map((chatMessage) => {
        chatMessage.messageText = chatMessage.messageText.replace(
          /\\n/g,
          "<br />"
        );
        return chatMessage;
      });
      return data;
    });
    return groupedChatMessageArray;
  },
};
export default tegsoftUtil;
