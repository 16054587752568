<template>
  <MDBCol col="12" md="7" class="relative flex flex-col">
    <h4 class="heading-4">
      {{
        layoutType != "passwordHasChanged"
          ? locale.FORGOT_PASSWORD
          : locale.PASSWORD_CHANGED
      }}
    </h4>
    <!-- <div v-if="layoutType == 'passwordHasChanged'" class="mb-5 pb-4">
      <div>{{ locale.PASSWORD_HAS_CHANGED }}</div>
    </div> -->
    <div>
      <input-error-message
        v-if="reset.errorOccured"
        :message="reset.ErrorMessage"
      />
    </div>

    <div
      v-if="layoutType == 'sendCodeToEmail' || layoutType == 'sendCodeAndEmail'"
    >
      <label for="email" class="form-label"> {{ locale.EMAIL }}</label>
      <MDBInput
        inputGroup
        :formOutline="false"
        v-model="formGroupInput.email.value"
        id="email"
        name="email"
        :class="{ invalid: formGroupInput.email.hasError }"
      />
      <input-error-message
        v-if="formGroupInput.email.hasError && wrong_email_format == false"
        :message="locale.EMAIL_REQUIRED"
      />
      <input-error-message
        v-if="email_does_not_exist"
        :message="locale.EMAILNOTEXIST"
      />
      <input-error-message
        v-if="wrong_email_format"
        :message="locale.INVALID_EMAIL_FORMAT"
      />
    </div>
    <div v-if="layoutType == 'sendCodeAndEmail'">
      <label for="code" class="form-label">
        {{ locale.VERIFICATION_CODE }}</label
      >
      <MDBInput
        inputGroup
        :formOutline="false"
        v-model="formGroupInput.code.value"
        id="code"
        name="code"
        :class="{ invalid: formGroupInput.code.hasError }"
      />
      <input-error-message
        v-if="formGroupInput.code.hasError"
        :message="locale.CODE_REQUIRED"
      />
      <input-error-message
        v-if="invalid_code_error"
        :message="locale.VERIFICATION_CODE_ERROR"
      />
    </div>
    <div v-if="layoutType == 'resetPassword'">
      <label for="password" class="form-label">
        {{ locale.NEW_PASSWORD }}</label
      >
      <MDBInput
        inputGroup
        :formOutline="false"
        v-model="formGroupInput.newPassword.value"
        id="newPassword"
        name="newPassword"
        type="password"
        :class="{ invalid: formGroupInput.newPassword.hasError }"
      />
      <input-error-message
        v-if="formGroupInput.newPassword.hasError"
        :message="locale.NEW_PASSWORD_REQUIRED"
      />
    </div>
    <div>
      <MDBBtn
        v-if="layoutType != 'passwordHasChanged'"
        @click="handleSubmit()"
        class="btn-submit btn-primary w-full text-center"
      >
        {{
          layoutType == "sendCodeToEmail"
            ? locale.SEND_CODE
            : layoutType == "sendCodeAndEmail"
            ? locale.RESET_PASSWORD
            : locale.RESET_PASSWORD
        }}
      </MDBBtn>
      <MDBBtn
        v-if="layoutType == 'passwordHasChanged'"
        @click="goToLogin()"
        class="btn-submit btn-primary w-full text-center"
      >
        {{ locale.BACK_TO_LOGIN }}
      </MDBBtn>
    </div>
    <p v-if="layoutType == 'sendCodeToEmail'" class="text-left mt-3">
      <a class="link inline" @click.prevent="layoutType = 'sendCodeAndEmail'"
        >{{ locale.I_HAVE_CODE }}
      </a>
    </p>
    <p v-if="layoutType == 'sendCodeAndEmail'" class="text-left mt-3">
      <a class="link inline" @click.prevent="handleSubmit(true)">
        <span v-if="resendCodeStatus == 'sending'">
          <img
            style="width: 20px"
            :src="require('@/assets/icons/gifs/dots-loading.gif')"
            alt="..."
          />
        </span>
        <span v-if="resendCodeStatus == 'sent'">
          <img
            style="width: 20px"
            :src="require('@/assets/icons/gifs/-checked.gif')"
            alt="&#10003;"
          />
        </span>
        <span v-else>{{ locale.RESEND_CODE }}</span>
      </a>
    </p>
    <div class="mt-auto text-center" v-if="layoutType != 'passwordHasChanged'">
      <a @click="goToLogin()" class="link inline">
        {{ locale.BACK_TO_LOGIN }}
      </a>
    </div>
  </MDBCol>
</template>

<script>
export default {
  props: ["parentData", "form"],
  data() {
    return {
      layoutType: "sendCodeToEmail", // sendCodeToEmail, sendCodeAndEmail and resetPassword
      formGroupInput: {
        code: { value: "", required: true, hasError: null },
        email: { value: "", required: true, hasError: null },
        newPassword: { value: "", required: true, hasError: null },
      },
      ...this.$props["parentData"],
      reset: {
        errorOccured: false,
        errorMessage: "",
      },
      wrong_email_format: false,
      invalid_code_error: false,
      email_does_not_exist: false,
      resendCodeStatus: "",
    };
  },
  watch: {
    "formGroupInput.email.value"() {
      this.resetFormErrMessages();
    },
    "formGroupInput.code.value"() {
      this.resetFormErrMessages();
    },
    "formGroupInput.newPassword.value"() {
      this.resetFormErrMessages();
    },
  },
  methods: {
    resetFormErrMessages() {
      this.email_does_not_exist = false;
      this.invalid_code_error = false;
      this.formGroupInput.email.hasError = false;
      this.wrong_email_format = false;
      this.reset.errorOccured = false;
      this.reset.errorMessage = "";
    },
    goToLogin() {
      const signupURLOptions = localStorage.getItem("signupOptions");
      if (global.util.isNotNull(signupURLOptions)) {
        global.util.goToMainPage(signupURLOptions, "signupOptions");
      } else {
        this.pageProp.layoutType = "login";
      }
    },
    handleSubmit(isResendCode) {
      if (this.layoutType == "sendCodeToEmail" || isResendCode) {
        if (this.formGroupInput.email.value == "") {
          this.formGroupInput.email.hasError = true;
          return;
        }
        if (!global.util.isValidEmail(this.formGroupInput.email.value)) {
          this.wrong_email_format = true;
          this.formGroup.usercode.hasError = true;
          return;
        }
        if (isResendCode) {
          this.resendCodeStatus = "sending";
        }
        global.util
          .post(this.$tegsoftConfig.TouchForgotPassword, {
            email: this.formGroupInput.email.value,
          })
          .then((response) => {
            if (response.data?.success) {
              this.layoutType = "sendCodeAndEmail";
              if (isResendCode) {
                this.resendCodeStatus = "sent";
                setTimeout(() => {
                  this.resendCodeStatus = "";
                }, 5000);
              }
            } else {
              this.email_does_not_exist = true;
              if (isResendCode) {
                this.resendCodeStatus = "";
              }
            }
          });
      } else if (this.layoutType == "sendCodeAndEmail") {
        if (this.formGroupInput.email.value == "") {
          this.formGroupInput.email.hasError = true;
          return;
        }
        if (this.formGroupInput.code.value == "") {
          this.formGroupInput.code.hasError = true;
          return;
        }
        global.util
          .post(this.$tegsoftConfig.TouchForgotPassword, {
            email: this.formGroupInput.email.value,
            verifyCode: this.formGroupInput.code.value,
          })
          .then((response) => {
            if (response.data?.success) {
              global.util.token = response.data.result.token;
              this.layoutType = "resetPassword";
            } else {
              this.invalid_code_error = true;
            }
          });
      } else {
        if (this.formGroupInput.newPassword.value == "") {
          this.formGroupInput.newPassword.hasError = true;
          return;
        }
        global.util
          .put(this.$tegsoftConfig.TouchResetPassword, {
            newPassword: this.formGroupInput.newPassword.value,
          })
          .then((response) => {
            if (response.data.success) {
              this.layoutType = "passwordHasChanged";
            } else {
              this.reset.errorOccured = true;
              this.reset.errorMessage = response.data.message;
            }
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  min-height: 10rem;
  transition: all 0.2s;
}
.sign-link a {
  font-weight: bold;
  color: var(--dark-bg);
  transition: all 0.2s;
  &:hover {
    color: var(--color-primary);
  }
}
.lang-icon {
  margin-top: 0.7rem;
  margin-right: 0.5rem;
}
</style>