import { DataRow } from "./tegsoftDataRow";

export default class DataSource {
  name = null;
  datasetName = null;
  dataColumns = [];
  rows = [];
  fetchable = false;
  fetchSize = Number.MAX_VALUE;
  currentRowIndex = -1;
  form = null;
  lastAction = null;
  self = null;
  refreshHandler = undefined;
  saveHandler = undefined;
  undoHandler = undefined;
  undoEventListener = undefined;
  deleteEventListener = undefined;
  saveEventListener = undefined;
  nextEventListener = undefined;
  currentRowChangedEventListener = undefined;
  skeletonLoading = false;
  saveOperationInprogress = false;
  oldViewState = undefined;

  get currentRow() {
    if (this.rows.length == 0) {
      return {};
    }

    if (this.currentRowIndex < 0) {
      this.currentRowIndex = 0;
    }

    if (this.currentRowIndex >= this.rows.length) {
      return {};
    }

    return this.rows[this.currentRowIndex];
  }

  deleteEnabled = true;
  insertEnabled = true;
  updateEnabled = true;

  autoSave = false;

  queryChangeOnNavigation = false;
  addEmptyRowOnLastRow = false;

  refreshTime = 0;

  tabPanelCondition = null;
  deleteAccessId = null;
  insertAccessId = null;
  updateAccessId = null;
  exportAccessId = null;
  importAccessId = null;

  childDataSources = null;
  parentDataSources = null;

  constructor(form, jsonString) {
    this.self = this;
    this.form = form;
    this.name = jsonString.name;
    this.datasetName = jsonString.datasetName;
    this.dataColumns = jsonString.dataColumns ? jsonString.dataColumns : [];
    this.fetchable = jsonString.fetchable ? jsonString.fetchable : false;
    this.fetchSize = jsonString.fetchSize
      ? jsonString.fetchSize
      : Number.MAX_VALUE;
    this.currentRowIndex = jsonString.currentRowIndex
      ? jsonString.currentRowIndex
      : -1;
    this.deleteEnabled = jsonString.deleteEnabled;
    this.insertEnabled = jsonString.insertEnabled;
    this.updateEnabled = jsonString.updateEnabled;
    this.autoSave = jsonString.autoSave ? jsonString.autoSave : false;
    this.queryChangeOnNavigation = jsonString.queryChangeOnNavigation
      ? jsonString.queryChangeOnNavigation
      : false;
    this.addEmptyRowOnLastRow = jsonString.addEmptyRowOnLastRow
      ? jsonString.addEmptyRowOnLastRow
      : false;
    this.refreshTime = jsonString.refreshTime ? jsonString.refreshTime : 0;
    this.tabPanelCondition = jsonString.tabPanelCondition;
    this.deleteAccessId = jsonString.deleteAccessId;
    this.insertAccessId = jsonString.insertAccessId;
    this.updateAccessId = jsonString.updateAccessId;
    this.exportAccessId = jsonString.exportAccessId;
    this.importAccessId = jsonString.importAccessId;
    this.childDataSources = jsonString.childDataSources
      ? jsonString.childDataSources
      : [];
    this.parentDataSources = jsonString.parentDataSources
      ? jsonString.parentDataSources
      : [];
    this.rows.length = 0;
    if (jsonString.dataRows) {
      for (const dataRow of jsonString.dataRows) {
        this.rows.push(new DataRow(this, dataRow));
      }
    }

    this.first();
  }

  first() {
    this.setCurrentRowIndex(0);
  }

  next() {
    this.setCurrentRowIndex(this.currentRowIndex + 1);
    if (this.nextEventListener != undefined) {
      this.nextEventListener();
    }
  }

  last() {
    this.setCurrentRowIndex(this.rows.length - 1);
  }

  previous() {
    this.setCurrentRowIndex(this.currentRowIndex - 1);
  }

  undo() {
    if (this.undoHandler != undefined) {
      this.undoHandler();
      return;
    }

    this.lastAction = "undoAction";
    var deleteList = [];
    for (const dataRow of this.rows) {
      if (dataRow.dataRowState == "Added") {
        deleteList.push(dataRow);
        continue;
      }

      if (dataRow.updated) {
        dataRow.mirrorDataArray = { ...dataRow.dataArray };
        dataRow.updated = false;
      }
    }

    for (const dataRow of deleteList) {
      for (var i = 0; i < this.rows.length; i++) {
        if (this.rows[i] === dataRow) {
          this.rows.splice(i, 1);
          i--;
        }
      }
    }

    var tmpCurrentRowIndex = this.currentRowIndex;
    this.currentRowIndex = -1;
    this.setCurrentRowIndex(tmpCurrentRowIndex);

    if (this.childDataSources) {
      if (this.childDataSources.length > 0) {
        this.undoChildDataSources();
      }
    }

    this.form.$tegsoftForm.updateSaveRequired(this.form);
    if (this.undoEventListener != undefined) {
      this.undoEventListener();
    }

    if (this.form?.onUndo != undefined) {
      this.form.onUndo(this.name);
    }

    if (this.form?.onDataEvent) {
      this.form.onDataEvent("onUndo", this.name);
    }
  }

  setCurrentRowIndex(newCurrentRowIndex) {
    if (this.rows.length == 0) {
      return;
    }

    if (newCurrentRowIndex >= this.rows.length) {
      newCurrentRowIndex = this.rows.length - 1;
    }

    if (newCurrentRowIndex < 0) {
      newCurrentRowIndex = 0;
    }

    if (this.currentRowIndex == newCurrentRowIndex) {
      return;
    }

    //    this.reflectUpdates();

    this.currentRowIndex = newCurrentRowIndex;
    //  this.currentRow = { ...this.rows[this.currentRowIndex] };

    if (this.currentRow.dataRowState != "Added") {
      if (this.fetchable) {
        if (this.rows.length > this.fetchSize) {
          if (this.rows.length - this.fetchSize < this.currentRowIndex) {
            this.oldViewState = undefined;
            this.refresh();
          }
        }
      }
    }

    if (this.childDataSources) {
      if (this.childDataSources.length > 0) {
        for (const childDataSourceName of this.childDataSources) {
          const childDataSource = this.form.db[childDataSourceName];
          if (childDataSource != null) {
            childDataSource.refresh();
          }
        }
      }
    }

    if (this.currentRowChangedEventListener != undefined) {
      this.currentRowChangedEventListener();
    }

    if (this.form?.onCurrentRowChanged) {
      this.form.onCurrentRowChanged();
    }

    if (this.form?.onDataEvent) {
      this.form.onDataEvent("onCurrentRowChanged", this.name);
    }
  }

  refresh(formLoading, commandName) {
    if (this.refreshHandler != undefined) {
      this.refreshHandler();
      return;
    }

    if (this.form.tegsoftFormData.saveRequired) {
      //TODO: Save check
    }

    if (!this.isTabPanelConditionMet()) {
      this.clear();
      this.oldViewState = null;
      return;
    }

    var viewState = this.form.$tegsoftForm.getViewState(this.form);
    if (global.util.isNotNull(this.oldViewState)) {
      if (JSON.stringify(viewState) === JSON.stringify(this.oldViewState)) {
        this.oldViewState = null;
        return;
      }
    }

    this.oldViewState = viewState;

    if (formLoading == undefined) {
      formLoading = false;
    }

    this.skeletonLoading = true;
    return new Promise((resolve) => {
      global.util
        .post(
          this.form.$tegsoftConfig.DataSourceServlet +
            this.form.name +
            "&dataSourceName=" +
            this.name +
            "&currentRowIndex=" +
            this.currentRowIndex +
            (formLoading == true
              ? "&refresh=false"
              : "" + "&commandName=" + commandName),
          viewState,
          this.form
        )
        .then((response) => {
          if (!response.data.dataSource) {
            console.warn("Empty data access ", this.name, response);
            return;
          }

          let index = 0;
          for (const dataRow of response.data.dataSource.dataRows) {
            if (this.rows.length <= index) {
              this.rows.push(new DataRow(this, dataRow, false));
              index++;
              continue;
            }
            this.rows[index] = new DataRow(this, dataRow, false);
            index++;
          }

          if (this.rows.length > index) {
            this.rows.length = index;
          }
          this.fetchable = response.data.dataSource.fetchable;

          var tmpCurrentRowIndex = this.currentRowIndex;
          this.currentRowIndex = -1;
          this.setCurrentRowIndex(tmpCurrentRowIndex);
          this.form.$tegsoftForm.updateSaveRequired(this.form);

          if (!this.form.tegsoftFormData.dataPopulated) {
            this.form.tegsoftFormData.popupulatedDataCount++;
            this.form.$tegsoftForm.updateDataPopuplated(this.form);
          }
          resolve(this);
          this.skeletonLoading = false;
          if (this.form?.onRefresh != undefined) {
            this.form.onRefresh(this.name);
          }
          if (this.form?.onDataEvent) {
            this.form.onDataEvent("onRefresh", this.name);
          }
        });
    });
  }

  insert() {
    if (!this.insertEnabled) {
      return;
    }

    if (this.currentRow.dataRowState == "Added") {
      return;
    }

    if (this.parentDataSources.length > 0) {
      for (const parentDataSourceName of this.parentDataSources) {
        const parentDataSource = this.form.db[parentDataSourceName];
        if (parentDataSource != null) {
          if (parentDataSource.currentRow != null) {
            if (parentDataSource.currentRow.dataRowState == "Added") {
              return;
            }
          }
        }
      }
    }

    this.rows.push(new DataRow(this, null));
    this.last();

    if (this.form?.onInsert != undefined) {
      this.form.onInsert(this.name);
    }

    if (this.form?.onDataEvent) {
      this.form.onDataEvent("onInsert", this.name);
    }

    this.form.$tegsoftForm.updateSaveRequired(this.form);
  }

  isDeleteEnabled() {
    if (!this.deleteEnabled) {
      return false;
    }

    if (global.util.isNotNull(this.deleteAccessId)) {
      if (!global.util.checkAccess(this.deleteAccessId)) {
        return false;
      }
    }

    return true;
  }

  isInsertEnabled() {
    if (global.util.isNotNull(this.insertAccessId)) {
      if (!global.util.checkAccess(this.insertAccessId)) {
        return false;
      }
    }
    return true;
  }

  isUpdateEnabled() {
    if (global.util.isNotNull(this.updateAccessId)) {
      if (!global.util.checkAccess(this.updateAccessId)) {
        return false;
      }
    }
    return true;
  }

  isExportEnabled() {
    if (global.util.isNotNull(this.exportAccessId)) {
      if (!global.util.checkAccess(this.exportAccessId)) {
        return false;
      }
    }
    return true;
  }

  isImportEnabled() {
    if (global.util.isNotNull(this.importAccessId)) {
      if (!global.util.checkAccess(this.importAccessId)) {
        return false;
      }
    }
    return true;
  }

  del() {
    if (this.deleteHandler != undefined) {
      this.deleteHandler();
      return;
    }

    if (!this.isDeleteEnabled()) {
      return;
    }

    if (this.rows.length <= 0) {
      return;
    }

    if (this.currentRow.dataRowState == "Added") {
      for (var i = 0; i < this.rows.length; i++) {
        if (this.rows[i] === this.currentRow) {
          this.rows.splice(i, 1);
          i--;
        }
      }

      var tmpCurrentRowIndex = this.currentRowIndex;
      this.currentRowIndex = -1;
      this.setCurrentRowIndex(tmpCurrentRowIndex);

      if (this.form?.onDelete != undefined) {
        this.form.onDelete(this.name);
      }

      if (this.form?.onDataEvent) {
        this.form.onDataEvent("onDelete", this.name);
      }

      this.form.$tegsoftForm.updateSaveRequired(this.form);
      return;
    }

    global.util.systemDialogues
      .show("question", "Question", this.form.locale.datasource_2)
      .then((res) => {
        if (res == true) {
          var chanedRows = [];
          this.currentRow.mirrorDataArray.dataRowState = "Deleted";
          this.currentRow.mirrorDataArray.originalDataArray =
            this.currentRow.dataArray;
          chanedRows.push(this.currentRow.mirrorDataArray);

          var updatedRows = {};
          updatedRows.rows = chanedRows;

          global.util
            .post(
              this.form.$tegsoftConfig.UpdateDatasetServlet +
                this.form.name +
                "&dataSourceName=" +
                this.name,
              updatedRows,
              this.form
            )
            .then(() => {
              this.refresh();

              var tmpCurrentRowIndex = this.currentRowIndex;
              this.currentRowIndex = -1;
              this.setCurrentRowIndex(tmpCurrentRowIndex);
              this.form.$tegsoftForm.updateSaveRequired(this.form);
              if (this.deleteEventListener != undefined) {
                this.deleteEventListener();
              }
              if (this.form?.onDelete != undefined) {
                this.form.onDelete(this.name);
              }

              if (this.form?.onDataEvent) {
                this.form.onDataEvent("onDelete", this.name);
              }
            });
        }
      });
  }

  save(isRefreshRequired) {
    if (this.saveHandler != undefined) {
      this.saveHandler();
      return;
    }

    this.lastAction = "saveAction";
    if (!this.deleteEnabled && !this.updateEnabled && !this.insertEnabled) {
      this.saveChildDataSources();
      return;
    }

    if (this.saveOperationInprogress == true) {
      return;
    }

    this.saveOperationInprogress = true;

    let forceRefresh = false;

    var chanedRows = [];
    for (const dataRow of this.rows) {
      if (dataRow.dataRowState == "Added") {
        dataRow.mirrorDataArray.dataRowState = "Added";
        chanedRows.push(dataRow.mirrorDataArray);
        forceRefresh = true;
        continue;
      }

      if (dataRow.updated) {
        dataRow.mirrorDataArray.dataRowState = "Modified";
        dataRow.mirrorDataArray.originalDataArray = dataRow.dataArray;
        chanedRows.push(dataRow.mirrorDataArray);
      }
    }

    if (chanedRows.length == 0) {
      this.saveChildDataSources();
      if (this.form?.onSave != undefined) {
        this.form.onSave(this.name);
      }

      if (this.form?.onDataEvent) {
        this.form.onDataEvent("onSave", this.name);
      }

      this.form.$tegsoftForm.updateSaveRequired(this.form);
      this.saveOperationInprogress = false;
      return;
    }

    var updatedRows = {};
    updatedRows.rows = chanedRows;

    global.util
      .post(
        this.form.$tegsoftConfig.UpdateDatasetServlet +
          this.form.name +
          "&dataSourceName=" +
          this.name,
        updatedRows,
        this.form
      )
      .then((response) => {
        if (response?.data?.errorMessage) {
          this.saveOperationInprogress = false;
          return;
        }

        for (const dataRow of chanedRows) {
          dataRow.dataRowState = "Unchanged";
          dataRow.dataArray = { ...dataRow.mirrorDataArray };
        }

        if (this.childDataSources) {
          if (this.childDataSources.length > 0) {
            this.saveChildDataSources();
          }
        }
        if (isRefreshRequired == true || forceRefresh || global.util.widget) {
          this.refresh();
        } else {
          for (const dataRow of this.rows) {
            if (dataRow.updated) {
              dataRow.dataRowState = "Unchanged";
              dataRow.dataArray = { ...dataRow.mirrorDataArray };
              dataRow.updated = false;
            }
          }
        }

        var tmpCurrentRowIndex = this.currentRowIndex;
        this.currentRowIndex = -1;
        this.setCurrentRowIndex(tmpCurrentRowIndex);
        this.form.$tegsoftForm.updateSaveRequired(this.form);
        if (this.saveEventListener != undefined) {
          this.saveEventListener();
        }

        if (this.form?.onSave != undefined) {
          this.form.onSave(this.name);
        }
        if (this.form?.onDataEvent) {
          this.form.onDataEvent("onSave", this.name);
        }
        this.saveOperationInprogress = false;
      })
      .catch(() => {
        this.saveOperationInprogress = false;
      });
  }

  saveChildDataSources() {
    if (!this.childDataSources) {
      return;
    }

    for (const childDataSourceName of this.childDataSources) {
      const childDataSource = this.form.db[childDataSourceName];
      if (childDataSource != null) {
        childDataSource.save();
      }
    }
  }

  undoChildDataSources() {
    if (!this.childDataSources) {
      return;
    }

    for (const childDataSourceName of this.childDataSources) {
      const childDataSource = this.form.db[childDataSourceName];
      if (childDataSource != null) {
        childDataSource.undo();
      }
    }
  }

  updateSaveRequired() {
    if (this.form.tegsoftFormData.saveRequired) {
      return;
    }

    for (const dataRow of this.rows) {
      if (dataRow.updated) {
        this.form.tegsoftFormData.saveRequired = true;
        this.form.tegsoftFormData.undoRequired = true;
        console.warn("SaveRequired - datachanged ", this.name);
        return;
      }

      if (dataRow.dataRowState == "Added") {
        console.warn("SaveRequired - new data row added ", this.name);
        this.form.tegsoftFormData.undoRequired = true;
        return;
      }
    }
  }

  clear() {
    this.rows.length = 0;
  }

  export() {
    var viewState = this.form.$tegsoftForm.getViewState(this.form);
    global.util.post(
      this.form.$tegsoftConfig.DataSourceServlet +
        this.form.name +
        "&dataSourceName=" +
        this.name +
        "&currentRowIndex=" +
        this.currentRowIndex +
        "&service=export",
      viewState,
      this.form
    );
  }

  import(fileData) {
    var viewState = this.form.$tegsoftForm.getViewState(this.form);
    global.util.post(
      this.form.$tegsoftConfig.DataSourceServlet +
        this.form.name +
        "&dataSourceName=" +
        this.name +
        "&service=import",
      fileData,
      this.form
    );
  }

  isTabPanelConditionMet() {
    let activeFormName = global.util.getActiveFormName();
    if (activeFormName == "cc_agent") {
      if (this.parentDataSources?.includes("TBLCRMCONTACTS")) {
        if (this.name == "TBLCRMACT") {
          if (
            global.util.agentForm.agentDesktop.childTabId == "activities" &&
            global.util.agentForm.agentDesktop.activeTabId1 == "R1"
          ) {
            return true;
          } else {
            return false;
          }
        }
        if (
          this.name == "grpunTBLCRMGRP" ||
          this.name == "grpasTBLCRMGRP" ||
          this.name == "TBLADRPROVINCE" ||
          this.name == "TBLCRMCONTACTRESP" ||
          this.name == "TBLCRMDSQUERY" ||
          this.name == "TBLADRCITY"
        ) {
          if (
            global.util.agentForm.agentDesktop.childTabId == "details" &&
            global.util.agentForm.agentDesktop.activeTabId1 == "R1"
          ) {
            return true;
          } else {
            return false;
          }
        }
        if (
          this.name == "TBLCRMCONTACTS_PHONENUMBERS" ||
          this.name == "TBLCRMCONTACTS_EMAILS"
        ) {
          if (
            global.util.agentForm.agentDesktop.childTabId == "profile" &&
            global.util.agentForm.agentDesktop.activeTabId1 == "R1"
          ) {
            return true;
          } else {
            return false;
          }
        }

        if (this.name == "TBLCRMCCARD" || this.name == "TBLCCVPOSTRAN") {
          if (
            global.util.agentForm.agentDesktop.childTabId == "creditCard" &&
            global.util.agentForm.agentDesktop.activeTabId1 == "R1"
          ) {
            return true;
          } else {
            return false;
          }
        }
        if (this.name == "TBLCRMORD") {
          if (
            global.util.agentForm.agentDesktop.childTabId == "orders" &&
            global.util.agentForm.agentDesktop.activeTabId1 == "R1"
          ) {
            return true;
          } else {
            return false;
          }
        }
        if (this.name == "TBLCRMCONTNOTE") {
          if (
            global.util.agentForm.agentDesktop.childTabId == "notes" &&
            global.util.agentForm.agentDesktop.activeTabId1 == "R1"
          ) {
            return true;
          } else {
            return false;
          }
        }
        if (this.name == "TBLCRMCONTSUR") {
          if (
            global.util.agentForm.agentDesktop.childTabId == "surveys" &&
            global.util.agentForm.agentDesktop.activeTabId1 == "R1"
          ) {
            return true;
          } else {
            return false;
          }
        }
      }
    }

    if (global.util.isNull(this.tabPanelCondition)) {
      return true;
    }

    if (global.util.isNull(global.util.agentForm)) {
      return true;
    }

    if (global.util.isNull(global.util.agentForm.agentDesktop)) {
      return true;
    }

    if (global.util.isNull(global.util.agentForm.agentDesktop.childTabId)) {
      return true;
    }

    if (this.tabPanelCondition == "crmTabpanel_history") {
      if (
        global.util.agentForm.agentDesktop.childTabId == "history" &&
        typeof global.util.widget != "undefined" &&
        activeFormName == "cc_agent"
      ) {
        return true;
      }
      if (
        global.util.agentForm.agentDesktop.childTabId == "profile" &&
        typeof global.util.widget != "undefined" &&
        activeFormName == "cc_agent"
      ) {
        return true;
      } else if (
        global.util.agentForm.agentDesktop.childTabId == "history" &&
        global.util.agentForm.agentDesktop.activeTabId1 == "R1" &&
        activeFormName == "cc_agent"
      ) {
        return true;
      } else {
        return false;
      }
    }

    if (
      this.tabPanelCondition == "tabpanelAgentsettings" &&
      activeFormName == "cc_agent"
    ) {
      if (
        global.util.agentForm.agentDesktop.activeTabId1 == "R5" &&
        activeFormName == "cc_agent"
      ) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }
}
