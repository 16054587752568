<template>
  <div id="login" class="login">
    <signingFormBase>
      <spinning-loader v-if="!tegsoftFormData.dataPopulated" />
      <template v-if="tegsoftFormData.dataPopulated">
        <div class="languege-select" v-if="pageProp.layoutType == 'login'">
          <i class="fas fa-globe lang-icon"></i>
          <combobox
            v-model="selectedLanguage"
            :options="languageOptions"
            value-field="LOCALE"
            text-field="LOCALENAME"
            :clearButton="false"
          />
        </div>
        <MDBCol
          v-if="pageProp.layoutType == 'login'"
          col="12"
          md="7"
          class="relative"
        >
          <div v-if="showLoader" class="loaderAbsolute">
            <spinning-loader />
          </div>
          <form @submit.prevent="performLogin">
            <h3 class="heading-3">
              {{ locale.WELCOME_TO_TOUCH }}
            </h3>

            <h4 class="heading-4">
              {{ locale.LOGIN_TO_YOUR_ACCOUNT }}
            </h4>
            <p class="paragraph text-center">
              {{ locale.FILL_INFORMATION_BELOW }}
            </p>
            <div>
              <input-error-message
                v-if="invalid_login_details"
                :message="locale.INVALID_LOGIN_DETAILS"
              />
              <input-error-message
                v-if="facebookEmailError"
                :message="locale.FACEBOOK_EMAIL_NOTFOUND"
              />
              <input-error-message
                v-if="facebookLoginAuthError"
                :message="locale.FACEBOOK_AUTH_ERROR"
              />
            </div>
            <div>
              <label for="usercode" class="form-label">
                {{ locale.EMAIL }}</label
              >
              <MDBInput
                inputGroup
                :formOutline="false"
                v-model="formGroup.usercode.value"
                id="email"
                name="email"
                :class="{ invalid: formGroup.usercode.hasError }"
              />
              <input-error-message
                v-if="
                  formGroup.usercode.hasError && wrong_email_format == false
                "
                :message="locale.EMAIL_REQUIRED"
              />
              <input-error-message
                v-if="wrong_email_format"
                :message="locale.INVALID_EMAIL_FORMAT"
              />

              <label for="password" class="form-label">{{
                locale.PASSWORD
              }}</label>
              <MDBInput
                inputGroup
                :formOutline="false"
                v-model="formGroup.password.value"
                type="password"
                id="password"
                name="password"
                :class="{ invalid: formGroup.password.hasError }"
              />
              <input-error-message
                v-if="formGroup.password.hasError"
                :message="locale.PASSWORD_REQUIRED"
              />

              <MDBBtn class="btn-submit btn-primary w-full" type="submit">
                {{ locale.LOGIN }}
              </MDBBtn>
              <p class="text-right sign-link mt-2">
                <a
                  class="link inline"
                  @click.prevent="pageProp.layoutType = 'forgotPassword'"
                  >{{ locale.FORGOT_PASSWORD }}
                </a>
              </p>
            </div>
          </form>
          <div class="or-separator">{{ locale.OR }}</div>
          <div style="height: fit-content">
            <div>
              <div id="googleSignInBtn"></div>
              <MDBBtn
                @click="checkFBLoginState"
                :disabled="facebookEmailError"
                class="facebok-login-btn btn-submit btn-light mx-auto shadow-none"
              >
                <i class="fa-brands fa-facebook-f"></i>
                {{ locale.LOGIN_WITH_FACEBOOK }}
              </MDBBtn>

              <p class="text-center sign-link mb-2 mt-3">
                {{ locale.NO_ACCOUNT }}
                <a class="link inline" @click.prevent="signup">{{
                  locale.SIGNUP
                }}</a>
              </p>
            </div>
          </div>
        </MDBCol>

        <forgotPassword
          v-if="pageProp.layoutType == 'forgotPassword'"
          :parentData="$data"
          :form="this"
        />
      </template>
    </signingFormBase>
  </div>
</template>
<script>
import { ref } from "vue";
import signingFormBase from "../../../components/signingFormBase";
import forgotPassword from "./forgotpassword";
export default {
  name: "login",
  components: {
    signingFormBase,
    forgotPassword,
  },
  setup() {
    let temp = "";
    let selectedLanguage = ref("en");
    const urlParam = new URLSearchParams(window.location.search);
    const localeParam = urlParam.get("locale");
    if (localeParam) {
      temp = localeParam;
      cookie.setCookie("TOBE_LOCALE", temp);
    } else if (cookie.getCookie("TOBE_LOCALE")) {
      temp = cookie.getCookie("TOBE_LOCALE");
    } else {
      temp = navigator.language.split("-")[0];
    }
    selectedLanguage = ref(temp);
    return {
      selectedLanguage,
    };
  },
  data() {
    return {
      pageProp: {
        layoutType: "login",
      },
      showLoader: false,
      pageIsLoading: false,
      facebookEmailError: false,
      invalid_login_details: false,
      facebookLoginAuthError: false,
      formGroup: {
        usercode: { value: "", required: true, hasError: null },
        password: { value: "", required: true, hasError: null },
      },
      wrong_email_format: false,
      languageOptions: [],
      vueVersion: "^3.0.0",
      appVersion: "1.3",
      googleScriptLoadedCounter: 0,
      localePackageNames: "com.tegsoft.console",
      urlParams: "",
      ...this.$tegsoftForm.data(),
    };
  },
  mounted() {
    console.log(
      "Tegsoft UI is starting. (App version: ",
      this.appVersion,
      " Vue version: ",
      this.vueVersion.substring(1),
      ")"
    );
    this.urlParams = this.extractQueryParameters(window.location.href);
    if (this.$route.params.facebookLoginAuthError) {
      this.facebookLoginAuthError = true;
    }
    this.$tegsoftForm.onFormLoad(this);
    this.setLanguages();
    if (this.selectedLanguage) {
      this.setLocale(this);
    }
    this.initializeAppLoginPage();
  },
  watch: {
    selectedLanguage() {
      this.setLocale(this);
      if (!this.isSignUpRedirect() && !this.isForgotPasswordRedirect()) {
        this.initializeGoogleSignIn();
      }
    },
    "formGroup.usercode.value": function () {
      this.facebookLoginAuthError = false;
      this.facebookEmailError = false;
      this.formGroup.usercode.hasError = false;
      this.invalid_login_details = false;
      this.wrong_email_format = false;
    },
    "formGroup.password.value": function () {
      this.facebookEmailError = false;
      this.formGroup.usercode.hasError = false;
      this.invalid_login_details = false;
    },
    "tegsoftFormData.dataPopulated": function () {
      if (!this.isSignUpRedirect() && !this.isForgotPasswordRedirect()) {
        this.initializeGoogleSignIn();
      }
    },
  },
  methods: {
    initializeGoogleSignIn() {
      setTimeout(() => {
        if (!window.google && this.googleScriptLoadedCounter < 10) {
          this.googleScriptLoadedCounter = this.googleScriptLoadedCounter++;
          this.initializeGoogleSignIn();
          return;
        } else if (!window.google) {
          console.error("Google script is not loaded properly");
          return;
        }

        window.google.accounts.id.initialize({
          client_id: global.util.decode(
            this.$tegsoftConfig.VUE_APP_GOOGLE_CLIENT_ID
          ),
          callback: this.handleCredentialResponse,
          locale: global.util.language,
        });

        window.google.accounts.id.renderButton(
          document.getElementById("googleSignInBtn"),
          {
            theme: "outline",
            size: "large",
            locale: global.util.language,
          }
        );
        window.google.accounts.id.prompt();
      }, 500);
    },

    handleCredentialResponse(response) {
      this.loginGoogleToken(response.credential);
    },

    signup() {
      global.util.user = undefined;
      global.util.userEmail = undefined;
      global.util.verifiyEmail = undefined;
      global.util.loginServiceName = undefined;
      this.$router.push("/signup");
      return;
    },
    /** FACEBOOK login start*/
    checkFBLoginState() {
      let vm = this;
      this.facebookLoginAuthError = false;

      FB.getLoginStatus(function (response) {
        if (response.authResponse !== null) {
          //Already logged in
          FB.api("/me", function (response) {});
          vm.loginFBToken(
            response.authResponse.userID,
            response.authResponse.accessToken
          );
        } else {
          vm.doFBlogin();
        }
      });
    },
    doFBlogin() {
      let vm = this;
      FB.login(function (response) {
        if (response.authResponse) {
          vm.loginFBToken(
            response.authResponse.userID,
            response.authResponse.accessToken
          );
        } else {
          /** User cancelled login or did not fully authorize. */
          vm.facebookLoginAuthError = true;
        }
      });
    },
    loginFBToken(facebookdId, token) {
      this.showLoader = true;
      global.util
        .post(this.$tegsoftConfig.LoginFacebook, {
          facebookdId: facebookdId,
          access_token: token,
        })
        .then((response) => {
          this.showLoader = false;
          if (response.data && response.data.success) {
            global.util.token = response.data.result.token.accesstoken;
            const tokenDecodablePart = global.util.token.split(".")[1];
            global.util.user = response.data.result;
            if (response.data.result && response.data.result.CompanyDetails) {
              this.$router.push("/userconsole");
            } else {
              global.util.loginServiceName = response.data.serviceName;
              this.$router.push("/signup");
            }
          } else if (response.data.errorCode == "Auth/EX002") {
            this.facebookEmailError = true;
          }
        })
        .catch((ex) => {
          this.showLoader = false;
          console.error("loginFBToken() ", ex);
        });
    },
    /** FACEBOOK login end */
    setLanguages() {
      if (typeof global.util.publicToken !== "undefined") {
        this.getLanguages();
      } else {
        let timeRuns = 0;
        let waitForToken = setInterval(() => {
          timeRuns++;
          if (this.languageOptions.length > 0) {
            clearInterval(waitForToken);
            return;
          } else {
            this.getLanguages();
          }
          if (timeRuns === 10) {
            clearInterval(waitForToken);
            console.error("Server took too long to load. Can't load languages");
          }
        }, 2000);
      }
    },
    getLanguages() {
      global.util.get(this.$tegsoftConfig.TouchLanguages).then((response) => {
        this.languageOptions = response.data.LocaleArray;
      });
    },
    loginGoogleToken(token) {
      this.showLoader = true;
      global.util
        .post(this.$tegsoftConfig.LoginGoogle, { access_token: token })
        .then((response) => {
          this.showLoader = false;
          if (response.data && response.data.success) {
            global.util.token = response.data.result.token.accesstoken;
            if (response.data.success) {
              global.util.user = response.data.result;
              global.util.token = response.data.result.token.accesstoken;
              if (response.data.result.CompanyDetails) {
                this.$router.push("/userconsole");
              } else {
                global.util.loginServiceName = response.data.serviceName;
                this.$router.push("/signup");
              }
            }
          }
        })
        .catch((ex) => {
          this.showLoader = false;
          console.error("loginGoogleToken() ", ex);
        });
    },
    performLogin() {
      let formIsValid = true;
      if (this.formGroup.usercode.value == "") {
        this.formGroup.usercode.hasError = true;
        formIsValid = false;
      }
      if (this.formGroup.password.value == "") {
        this.formGroup.password.hasError = true;
        formIsValid = false;
      }
      if (formIsValid == false) {
        return;
      }
      if (!global.util.isValidEmail(this.formGroup.usercode.value)) {
        this.wrong_email_format = true;
        this.formGroup.usercode.hasError = true;
        return;
      }

      const jsonData = {
        email: this.formGroup.usercode.value,
        password: this.formGroup.password.value,
      };
      this.showLoader = true;
      global.util
        .post(this.$tegsoftConfig.TouchLogin, jsonData, this, {
          ignoreError: true,
        })
        .then((response) => {
          this.showLoader = false;
          if (response.data.success) {
            global.util.user = response.data.result;
            global.util.token = response.data.result.token.accesstoken;
            if (response.data.result.CompanyDetails) {
              this.$router.push("/userconsole");
            } else {
              global.util.verifiyEmail = false;
              this.$router.push("/signup");
            }
          } else {
            if (response.data.errorCode == "Auth/AUTHERROR002") {
              global.util.user = response.data.result;
              global.util.token = response.data.result
                ? response.data.result.token.accesstoken
                : undefined;
              global.util.userEmail = this.formGroup.usercode.value;
              global.util.verifiyEmail = true;
              this.$router.push("/signup");
            } else {
              this.invalid_login_details = true;
            }
          }
        })
        .catch((ex) => {
          this.showLoader = false;
          console.error("performLogin() ", ex);
        });
    },

    setLocale(currentForm) {
      this.$nextTick();
      global.util.language = this.selectedLanguage;
      if (global.util.publicToken || global.util.token) {
        global.util
          .get(
            this.$tegsoftConfig.TouchLocale + `?locale=${this.selectedLanguage}`
          )
          .then((response) => {
            cookie.setCookie("TOBE_LOCALE", this.selectedLanguage);
            this.$tegsoftForm.executeLocale(currentForm);
          });
      }
    },
    initializeAppLoginPage() {
      if (this.isForgotPasswordRedirect()) {
        if (this.urlParams.includes("callbackurl")) {
          localStorage.setItem("signupOptions", this.urlParams);
        }
        window.history.pushState({}, null, window.location.pathname);
        this.pageProp.layoutType = "forgotPassword";
      } else if (this.isSignUpRedirect()) {
        if (
          this.urlParams.includes("business") ||
          this.urlParams.includes("callbackurl")
        ) {
          localStorage.setItem("signupOptions", this.urlParams);
        }
        window.history.pushState({}, null, window.location.pathname);
        this.$router.push("/signup");
      }
    },
    isForgotPasswordRedirect() {
      if (
        this.urlParams.includes("forgotpassword") ||
        this.urlParams.includes("forgotPassword") ||
        this.urlParams.includes("forgot-password")
      ) {
        return true;
      }
      return false;
    },
    isSignUpRedirect() {
      if (
        this.urlParams.includes("signUp") ||
        this.urlParams.includes("sign-up") ||
        this.urlParams.includes("signup")
      ) {
        return true;
      }
      return false;
    },

    extractQueryParameters(url) {
      const urlParts = url.split("?");
      if (urlParts.length > 1) {
        urlParts.shift(); // Remove the first part (before the first "?")
        return urlParts.join("?");
      } else {
        return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  min-height: 10rem;
  transition: all 0.2s;
}
.sign-link a {
  font-weight: bold;
  color: var(--dark-bg);
  transition: all 0.2s;
  &:hover {
    color: var(--color-primary);
  }
}
.lang-icon {
  margin-top: 0.7rem;
  margin-right: 0.5rem;
}
.btn-light {
  background: #fff;
  color: #3c4043;
  border: 1px solid #dadce0;
  min-width: 250px;
}
</style>