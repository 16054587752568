import { createApp, markRaw } from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import tegsoftConfig from "../tegsoftlib/tegsoftConfig";

const app = createApp(App);
app.use(router);

import tegsoftForm from "../tegsoftlib/tegsoftForm";
import tegsoftUtil from "../tegsoftlib/tegsoftUtil";

app.config.globalProperties.$tegsoftConfig = tegsoftConfig;
app.config.globalProperties.$tegsoftForm = tegsoftForm;
global.util = tegsoftUtil;

//FACEBOOK INIT
import { initFacebookSdk } from "./_helpers/init-facebook-sdk.js";
// wait for facebook sdk to start app
initFacebookSdk();

//FIREBASE
import firebaseMessaging from "./firebase";
app.config.globalProperties.$messaging = firebaseMessaging;

import * as mdbvue from "mdb-vue-ui-kit";
import "mdb-vue-ui-kit/css/mdb.min.css";

import "./assets/tailwind.css";
import "mdb-vue-ui-kit/src/scss/index.pro.scss";
import "./assets/styles/main.scss";

//import "v-calendar/dist/style.css";
//import * as vcalendar from "v-calendar";
//for (const component in vcalendar) {
//  app.component(component, vcalendar[component]);
//}

// import { tSelect } from "./components/select";
// app.component(tSelect, mdbvue[tSelect]);
for (const component in mdbvue) {
  if (component != "MDBTabs" && component != "MDBTabNav") {
    app.component(component, mdbvue[component]);
  }
}
import * as tegsoftComponents from "./components/";
for (const component in tegsoftComponents) {
  app.component(component, tegsoftComponents[component]);
}

import { VueCookieNext } from "vue-cookie-next";
VueCookieNext.config({ expire: "7d" });
app.use(VueCookieNext);
window.cookie = VueCookieNext;

const instance = axios.create({
  baseURL: tegsoftConfig.baseURL,
});
window.server = app.config.globalProperties.$server = instance;

/* Error Handling */

app.config.errorHandler = function (err, vm, info) {
  console.error("Global VUE Error", err, vm, info);
};
window.onerror = function (msg, url, line, col, error) {
  console.error("Global Error", msg, url, line, col, error);
};
// window.addEventListener('unhandledrejection', function(event) {
//     console.error("Global Unhandled Error", event);
// });

app.use(router);
app.mount("#app");
