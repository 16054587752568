<template>
  <div v-if="!tegsoftFormData.dataPopulated">
    <div
      class="flex justify-center items-center"
      style="height: 100vh; width: 100vw"
    >
      <spinning-loader />
    </div>
  </div>
  <tegsoft-navbar :parentData="$data" />
  <div class="flex flex-row w-100" style="height: calc(100% - 50px)">
    <tegsoft-sidebar-menu :parentData="$data" />
    <div class="userconsole__body">
      <router-view :parentData="$data" :key="$route.fullPath" />
    </div>
  </div>
</template>

<script>
import { markRaw } from "vue";

import tegsoftNavbar from "./tegsoftNavbar.vue";
import TegsoftSidebarMenu from "./tegsoftSidebarMenu.vue";
import dashboard from "./dashboard/dashboard.vue";
export default {
  components: { tegsoftNavbar, TegsoftSidebarMenu, dashboard },
  name: "userconsole",
  data() {
    return {
      profile: {
        activeTabId: "profile",
      },
      user: global.util.user,
      companyusers: [],
      localePackageNames: "com.tegsoft.console",
      ...this.$tegsoftForm.data(),
    };
  },
  mounted() {
    global.util.userconsoleForm = markRaw(this);
    this.$tegsoftForm.onFormLoad(this);
    global.util.currentCompany = global.util.user.CompanyDetails;
    this.getBusinessList();
    this.getFCMToken();
    this.getUser();
  },
  methods: {
    getUser() {
      global.util
        .get(this.$tegsoftConfig.TouchUser + global.util.user.token.UID)
        .then((response) => {
          if (
            typeof response.data.result.name == "undefined" &&
            typeof response.data.result.surname == "undefined"
          ) {
            const email = response.data.result.email;
            const names = email.split("@")[0];
            let name = "";
            let surname = "";
            if (names.includes(".")) {
              name = names.split(".")[0];
              surname = names.split(".")[1];
            } else {
              name = names;
            }
            response.data.result.name = name;
            response.data.result.surname = surname;
          }
          this.user.profile = response.data.result;
          global.util.user.profile = response.data.result;
        });
    },
    /** Firebase cloud messaging */
    getFCMToken() {
      // console.log('Firebase cloud messaging object', this.$messaging.app);
      this.$messaging.getToken().then((currentToken) => {
        if (currentToken) {
          this.registergetFCMToken(currentToken);
        } else {
          console.log(
            "No registration token available. Request permission to generate one."
          );
        }
      });
    },
    registergetFCMToken(token) {
      const myUniqiId =
        global.util.getBrowserID().id + global.util.user.token.UID;
      const queryParam = {
        BUNDLEID: "com.tegsoft.web.portal",
        MOBILEDID: myUniqiId,
        APPLICATIONNAME: "web",
        BRAND: global.util.getBrowserID().brand,
        DEVICECOUNTRY: Intl.DateTimeFormat().resolvedOptions().timeZone,
        DEVICELOCALE: global.util.language,
        USERAGENT: window.navigator.userAgent,
        VERSION: navigator.appVersion,
        PUSHTOKEN: token,
      };
      global.util
        .post(this.$tegsoftConfig.TouchRegisterFCMToken, queryParam)
        .then((response) => {});
    },
    getBusinessList() {
      global.util
        .get(this.$tegsoftConfig.TouchCreateBusiness)
        .then((response) => {
          if (!response.data.success) {
            return;
          }

          if (!global.util.companyList) {
            global.util.companyList = [];
          }
          global.util.companyList.length = 0;
          global.util.companyList = [...response.data.result.BusinessList];
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.userconsole {
  &__body {
    width: 100%;
    height: 100%;
  }
}
</style>
