<template>
  <label :for="id" class="form-label"> {{ label }} </label>
  <MDBInput
    :id="id"
    inputGroup
    :formOutline="false"
    v-model="inputValue"
    @input="
      $emit('update:modelValue', $event.target.value);
      removeHasError($event);
    "
    :class="{ invalid: hasError }"
    v-bind="$attrs"
  />

  <transition name="invalid">
    <div v-if="hasError" class="invalid-note">
      {{ errorMessage }}
    </div>
  </transition>
</template>

<script>
export default {
  name: "inputField",
  props: ["modelValue", "id", "label", "hasError", "errorMessage"],
  data() {
    return {
      inputValue: "",
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.inputValue = this.modelValue;
    });
  },
  methods: {
    removeHasError(event) {
      if (this.hasError) {
        this.$emit("removeHasError", event.target.value);
      }
    },
  },
};
</script>

<style>
</style>