<template>
 <form @submit.prevent="onsubmit" class="business-info">
  <div class="region-info">
      <h2>{{ locale.ALMOST_DONE }}</h2>
      <h3>{{ locale.REGION_INFO }}</h3>
      <p>{{ locale.ENTER_BUSINESS_INFO }}</p>
      <MDBCol col="12" md="11" class="m-auto mb-6">
        <MDBRow>
          <MDBCol col="12" md="6" class="text-left">
            <label for="country" class="form-label"> {{ locale.COUNTRY }}* </label>
            <combobox
              v-model="formGroup.country.value"
              :options="countries"
              value-field="isoCode"
              text-field="name"
              :clearButton="false"
            />
            <input-error-message 
              v-if="formGroup.country.hasError"
              :message="locale.COUNTRY_REQUIRED"
            />
          </MDBCol>
          <MDBCol col="12" md="6">
            <label for="province" class="form-label"> {{ locale.PROVINCE }} </label>
             <combobox
              v-model="formGroup.province.value"
              :options="provincesOfCountry"
              value-field="isoCode"
              text-field="name"
              :clearButton="false"
            />
            <input-error-message 
              v-if="formGroup.province.hasError"
              :messge="locale.PROVINCE_REQUIRED"
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol col="12" md="6" class="text-left">
            <label for="city" class="form-label"> {{ locale.CITY }} </label>
            <combobox
              v-model="formGroup.city.value"
              :options="citiesOfState"
              value-field="name"
              text-field="name"
              :clearButton="false"
            />
            <input-error-message 
              v-if="formGroup.city.hasError"
              :message="locale.CITY_REQUIRED"
            />
          </MDBCol>
          <MDBCol col="12" md="6">
            <label for="suburb" class="form-label"> {{ locale.DISTRICT }} </label>
            <MDBInput
              :formOutline="false"
              v-model="formGroup.suburb.value"
              id="suburb"
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol col="12" md="6" class="text-left">
            <label for="address" class="form-label"> {{ locale.ADDRESS }} </label>
            <MDBTextarea
              style="min-height: 65px"
              inputGroup
              :formOutline="false"
              v-model="formGroup.address.value"
              id="address"
              :class="{'invalid': formGroup.address.hasError}"
            />
            <input-error-message 
              v-if="formGroup.address.hasError"
              :message="locale.ADDRESS_REQUIRED"
            />
          </MDBCol>
          <MDBCol col="12" md="6">
            <label for="address" class="form-label"> {{ locale.NOTES }} </label>
            <MDBTextarea
              style="min-height: 65px"
              inputGroup
              :formOutline="false"
              v-model="formGroup.notes.value"
              :placeholder="locale.OPTIONAL"
              id="address"
              :class="{'invalid': formGroup.notes.hasError}"
            />
          </MDBCol>
          <MDBCol col="12" md="6">
            <label for="zipcode" class="form-label"> {{ locale.ZIPCODE }} </label>
            <MDBInput
              :formOutline="false"
              v-model="formGroup.zipcode.value"
              id="zipcode"
              :class="{'invalid': formGroup.zipcode.hasError}"
            />
            <input-error-message 
              v-if="formGroup.zipcode.hasError"
              :messge="locale.ZIPCODE_REQUIRED"
            />
          </MDBCol>
        </MDBRow>
        <div class="flex justify-between items-center">
          <div class="p-2 link" @click="goBack">
              {{ locale.GO_BACK }}
          </div>
          <MDBBtn class="btn-submit btn-primary inline-block" type="submit">
            {{ locale.FINISH }}
          </MDBBtn>
        </div>

      </MDBCol>
  </div>
 </form>
</template>

<script>
import { Country, State, City }  from 'country-state-city';
export default {
  props: ["parentData"],
  data(){
    return{
      ...this.$props["parentData"],
      countries: this.getAllCountries(),
      provincesOfCountry: [],
      citiesOfState: [],
      formGroup:{
        country: { value: "", required: true, hasError: false },
        province: { value: "", required: true, hasError: false },
        city: { value: "", required: true, hasError: false },
        suburb: { value: "", required: false, hasError: false },
        address: { value: "", required: true, hasError: false },
        notes: { value: "", required: true, hasError: false },
        zipcode: { value: "", required: false, hasError: false },
        notes: { value: "", required: false, hasError: false },
      }
    }
  },
  watch:{
    "formGroup.country.value"(){
      this.provincesOfCountry = State.getStatesOfCountry(this.formGroup.country.value);
    },
    "formGroup.province.value"(){
      this.citiesOfState = City.getCitiesOfState(this.formGroup.country.value, this.formGroup.province.value);
    },
  },
  methods:{
    getAllCountries(){
      return Country.getAllCountries().map( country => {
        return {
          name: country.flag +" "+ country.name,
          isoCode: country.isoCode
        }
      }).filter( country => country.isoCode != "AX" );
    },
    onsubmit(){
      let formIsValid = true;
      for( let key in this.formGroup){
        //Check for empty input fields
        if( this.formGroup[key].required && this.formGroup[key].value == "" ){
          this.formGroup[key].hasError = true;
          formIsValid = false;
        }
      }
      if( formIsValid ){
        let businessData = {
          NAME: this.businessFormGroup.name.value,
          PROFESSION: this.businessFormGroup.category.value,
          WEBURL: "https://"+this.businessFormGroup.website.value,
          TAXNUMBER: this.businessFormGroup.tax_number.value,
          NOTES: this.formGroup.notes.value,
          COUNTRY: Country.getAllCountries().filter( country => country.isoCode == this.formGroup.country.value)[0].name,
          PROVINCE: this.provincesOfCountry.filter( state => state.isoCode == this.formGroup.province.value)[0].name,
          CITY: this.formGroup.city.value,
          DISTRICT: this.formGroup.suburb.value,
          ADDR: this.formGroup.address.value,
        };
        this.signup.showLoader = true;
        global.util.post(this.$tegsoftConfig.TouchCreateBusiness, businessData)
        .then(response =>{
          this.signup.showLoader = false;
          if(response.data.success){
            global.util.user.CompanyDetails = response.data.result;
            this.$router.push("/userconsole");
          }
        });
      }
    },
    goBack(){
      if(this.businessFormGroup.website.value == ""){
        this.navigation.currentScreen = "business-info";
        return;
      }
      this.navigation.currentScreen = "verify-website";
    }
  }
}
</script>

<style lang="scss" scoped>

</style>