<template>
  <div>
    <h2 class="my-5"> {{ locale.VERIFY_PHONE }}</h2>
    <MDBCol md="9" class="m-auto pt-5">
        <p class="text-center">
             {{ locale.VERIFYPHONE }} 
             <code> 
                 <span>{{ phonenumber }}</span>
             </code>
             <MDBBtn class="btn-light px-3 py-1"  @click="editPhone=true">
                 {{ locale.CHANGE_NUMBER }}
             </MDBBtn>
        </p>
        <p v-if="phoneVerificationCode.resendError" class="unknown-error">{{ locale.SOMETHING_WENT_WRONG }}</p>
        <label for="phone-code" class="form-label"> {{ locale.ENTER_CODE }} </label>
        <MDBInput
          inputGroup
          aria-autocomplete="none"
          :formOutline="false"
          v-model="phoneVerificationCode.value"
          id="phone-code"
          :class="{'invalid': phoneVerificationCode.hasError}"
          :onkeypress="onlyNumberKey"
        />
        <input-error-message 
          v-if="phoneVerificationCode.hasError && !phoneVerificationCode.invalidCode"
          :message="locale.PHONE_CODE_REQUIRED"
        />
        <input-error-message 
          v-if="phoneVerificationCode.hasError && phoneVerificationCode.invalidCode"
          :message="locale.WRONG_CODE"
        />
        <div class="py-4 flex justify-between">
            <!-- <div class="link" @click="resendCode"> {{ locale.RESEND_CODE }}</div> -->
            <div class="link resend-code" :class="{'resend-code__sent': phoneVerificationCode.resend}" 
                @click="resendCode"> {{ phoneVerificationCode.resend ? locale.CODE_SENT +'!':locale.RESEND_CODE  }}</div>
            <MDBBtn color="primary" @click="verifyPhoneCode"> {{ locale.VERIFY }}</MDBBtn>
        </div>
    </MDBCol>

    <modal :showModal="editPhone" @closeModal="editPhone=false">
        <MDBRow >
            <MDBCol  class="p-4">
                <h4 class="heading-4">
                {{ locale.REENTER_PHONE }}
                </h4>
                <input-phone-number :locale="locale" @inputPhoneNumber="inputPhoneNumber"/>
            </MDBCol>
        </MDBRow>
        <MDBRow >
            <MDBCol  class="p-4 pt-3 text-right">
                <MDBBtn @click="updatePhone" class="btn-primary"> {{ locale.DONE }} </MDBBtn>
            </MDBCol>
        </MDBRow>
    </modal>
</div>
</template>

<script>
export default {
    props:["parentData"],
    data(){
        return{
            editPhone: false,
            updatedPhonenumber:"",
            phonenumber: this.getPhonenumber(),
            updatePhoneProcess: false,
            showLoader: false,
            ...this.$props.parentData
        }
    },
    mounted(){
        this.verifyPhone.visited=true;
    },
    watch:{
        "showPhoneVerificationModal": function(){
            this.phoneVerificationCode.value = ""
            this.phoneVerificationCode.hasError = false;
            this.phoneVerificationCode.invalidCode = false;
        },
        "phonenumber": function(value){
            this.updatePhoneProcess = true;
        },
        "phoneVerificationCode.resendError": function(value){
            if(value == true){
                setTimeout(()=>{
                        this.phoneVerificationCode.resendError = false;
                }, 8000);
            }
        }
    },
    methods:{
        inputPhoneNumber(inputPhone){
            this.phonenumber = inputPhone;
        },
        updatePhone(){
            this.clearVirificationInput();
            if( this.updatePhoneProcess ){
                const jsonData = {
                    name : global.util.user.name,
                    surname : global.util.surname,
                    gender : "not specif",
                    phone:  this.phonenumber
                };
                this.editPhone = false;
                this.signup.showLoader = true;
                global.util.patch( this.$tegsoftConfig.TouchUpadateUser, jsonData )
                    .then( response => {
                        this.signup.showLoader = false;
                        this.updatePhoneProcess = false;
                        global.util.user.phoneNumber =  this.phonenumber;
                        this.phonenumber = decodeURIComponent( this.phonenumber );
                    })
                    .catch(ex=>{
                        this.signup.showLoader = false;
                        this.phoneVerificationCode.resendError = true;
                    });
            }
            else{
                this.editPhone = false;
            }
        },
        getPhonenumber(){
            return decodeURIComponent( global.util.user.phoneNumber );
        },
        resendCode(){
            this.clearVirificationInput();
            this.signup.showLoader = true;
            global.util.get( this.$tegsoftConfig.TouchSignupResendPhoneCode + encodeURIComponent(global.util.user.phoneNumber) +
            `&UID=${global.util.user.user_id}`)
            .then( response =>{
                this.signup.showLoader = false;
                if(response.data.success){
                    this.phoneVerificationCode.resend=true;
                    setTimeout(()=>{
                         this.phoneVerificationCode.resend=false;
                    }, 5000);
                }
                else{
                    this.phoneVerificationCode.resendError = true;
                }
            })
            .catch(ex => {
                this.signup.showLoader = false;
            })
        },
        verifyPhoneCode(){
            if(this.phoneVerificationCode.value != "" ){
                this.$emit('verifyPhone');
            }
            else{
                this.phoneVerificationCode.hasError = true;
            }
        },
        onlyNumberKey(event) {
            // Only ASCII character in the range allowed
            return event.charCode >= 48 && event.charCode <= 57;
        },
        clearVirificationInput(){
            this.phoneVerificationCode.value ="";
            this.phoneVerificationCode.hasError = false;
        }
    }
}
</script>

<style lang="scss" scoped>
    .code__link{

    }

</style>