<template>
      <div class="tegsoft-grid-item border p-3">
        <h4 class="font-bold">Complaints about your business</h4>
        <div class="p-3 border-bottom flex justify-between">
            <div>All tickets</div>
            <div>0</div>
        </div>
        <div class="p-3 border-bottom flex justify-between">
            <div>All tickets</div>
            <div>0</div>
        </div>
        <div class="p-3 border-bottom flex justify-between">
            <div>All tickets</div>
            <div>0</div>
        </div>
        <div class="small-text pt-6 mt-3">
            Performance over the past 28 days
        </div>
    </div>
</template>

<script>
export default {
    name: "company-complaints",
    props: ["parentData"],
    data(){
        return{
            companyComplaints: [],
            ...this.$props.parentData,
        }
    }

}
</script>

<style>

</style>