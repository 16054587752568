import tegsoftUtil from "./tegsoftUtil";

export class DataRow {
  dataSource = null;
  dataRowState = "Added";
  dataArray = {};
  mirrorDataArray = {};
  updated = false;

  constructor(dataSource, jsonString, enableDefaultValues) {
    this.dataSource = dataSource;

    if (enableDefaultValues != false) {
      this.assignDefaultValues();
    }

    if (tegsoftUtil.isNotNull(jsonString)) {
      for (const columnName in jsonString) {
        this.setOriginalValue(columnName, jsonString[columnName]);
      }
      this.dataRowState = "Unchanged";
    } else {
      this.dataRowState = "Added";
    }

    return new Proxy(this, {
      get(dataRow, columnName) {
        if (columnName == "dataSource") {
          return dataRow.dataSource;
        }
        if (columnName == "dataRowState") {
          return dataRow.dataRowState;
        }
        if (columnName == "dataArray") {
          return dataRow.dataArray;
        }
        if (columnName == "mirrorDataArray") {
          return dataRow.mirrorDataArray;
        }
        if (columnName == "updated") {
          return dataRow.updated;
        }

        return dataRow.getValue(columnName);
      },

      set(dataRow, columnName, value) {
        if (columnName == "dataSource") {
          dataRow.dataSource = value;
          return true;
        }
        if (columnName == "dataRowState") {
          dataRow.dataRowState = value;
          return true;
        }
        if (columnName == "dataArray") {
          dataRow.dataArray = value;
          return true;
        }
        if (columnName == "mirrorDataArray") {
          dataRow.mirrorDataArray = value;
          return true;
        }
        if (columnName == "updated") {
          dataRow.updated = value;
          return true;
        }

        dataRow.setCurrentValue(columnName, value);
        return true;
      },
    });
  }

  getValue(columnName) {
    return this.mirrorDataArray[columnName];
  }

  setCurrentValue(columnName, value) {
    if (this.mirrorDataArray[columnName] == value) {
      return;
    }

    if (this.mirrorDataArray[columnName] == undefined && value == "") {
      return;
    }

    if (this.mirrorDataArray[columnName] == "" && value == undefined) {
      return;
    }

    this.mirrorDataArray[columnName] = value;
    if (this.dataRowState != "Added") {
      this.dataRowState = "Modified";
    }
    this.updated = true;

    console.warn(
      "SaveRequired - datachanged ",
      this.dataSource.name,
      columnName
    );

    if (this.dataSource?.form?.onDataUpdate) {
      this.dataSource.form.onDataUpdate(
        this.dataSource.name,
        columnName,
        value
      );
    }

    if (this.dataSource?.form?.onDataEvent) {
      this.dataSource.form.onDataEvent(
        "onDataUpdate",
        this.dataSource.name,
        columnName,
        value
      );
    }

    this.dataSource?.updateSaveRequired();
  }

  setOriginalValue(columnName, value) {
    this.dataArray[columnName] = value;
    this.mirrorDataArray[columnName] = value;
  }

  assignDefaultValues() {
    if (this.dataSource == null) {
      return;
    }

    if (this.dataSource == undefined) {
      return;
    }

    for (const dataColumn of this.dataSource.dataColumns) {
      var defaultValue = dataColumn.defaultValue;
      var defaultValueType = dataColumn.defaultValueType;

      if (defaultValueType == "UUID") {
        defaultValue = this.uuidv4();
      } else if (defaultValueType == "Object") {
        // use defaultValue
      } else if (defaultValueType == "ComponentDbParameter") {
        defaultValue = this.dataSource.form.$data["defaultValueComponentId"];
      } else if (defaultValueType == "OsDbParameter") {
      } else if (defaultValueType == "DataSourceDbParameter") {
        let textFieldNames = dataColumn.defaultValueColumnName.split(",");
        let textValue = "";
        let i = 0;
        for (i = 0; i < textFieldNames.length; i++) {
          textValue +=
            this.dataSource.form.$data.db[dataColumn.defaultValueDataSourceName]
              .currentRow[textFieldNames[i]] + " ";
        }
        defaultValue = textValue;
      } else if (defaultValueType == "OsDbParameter") {
        // use defaultValue
      } else if (defaultValueType == "CrmDbParameter") {
        // use defaultValue
      }

      if (defaultValue == undefined || defaultValue.trim() === "undefined") {
        defaultValue = "";
      }

      if (defaultValue == null) {
        continue;
      }

      if (defaultValue == "") {
        continue;
      }

      this.dataArray[dataColumn.columnName] = defaultValue;
      this.mirrorDataArray[dataColumn.columnName] = defaultValue;
    }
  }

  uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }
}
