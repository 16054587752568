<template>
  <div class="phonenumber-input">
    <label for="phone" class="form-label">{{ locale.PHONE }}</label>
    <MDBInput
        id="phonenumber" inputGroup 
        :formOutline="false" autocomplete="tel"
        v-model="inputValue" @input="getPhoneCountry($event)"
        :class="{'invalid': inputHasError || wrong_phone_format}"
        @blur="emitPhonenumber"
        >
        <template #prepend>
            <div class="relative phonePrefix" style="width: 5rem">
            <MDBSelect id="phoneExtensionInput" class="phoneExtensionInput" 
            v-model:options="countries" v-model:selected="phoneExtension" filter />
            <MDBInput
                type="text" readonly
                id="countryFlag"
                v-model="countryFlag"
                class="phoneCodeInput form-icon-trailing"
            >
                <i class="fas fa-caret-down trailing"></i>
            </MDBInput>
            </div>
        </template>
    </MDBInput>
    <transition name="invalid">
    <div v-if="inputHasError" class=invalid-note>
        {{ locale.PHONE_REQUIRED }}
    </div>
    </transition>
    <transition name="invalid">
    <div v-if="wrong_phone_format" class=invalid-note > 
        {{ locale.INVALID_PHONE_FORMAT }}
    </div>
    </transition>
  </div>
</template>

<script>
import { Country }  from 'country-state-city';
export default {
    name:"inputPhoneNumber",
    props: ["locale", "value", "hasError", "validateProp"],
    data(){
        return{
            inputHasError: this.$props.hasError,
            inputValue: "",
            
            countries: this.getCountries(),
            phoneExtension: "",
            updateExtentionCount: 0,
            countryFlag: "",
            wrong_phone_format: false,
            updated:{ 
                newValue: "", status: false
            }
        }
    },
    mounted(){
        global.util.userLocation = Country.getAllCountries().find(country => country.timezones[0].zoneName ==
             Intl.DateTimeFormat().resolvedOptions().timeZone);
        this.inputValue = this.value;
        this.getDefaultPhoneExtention();
    },
    watch:{
        "phoneExtension": function(value){
            this.countryFlag = this.countries.find(country => country.value === value).flag;
            this.updateExtentionCount++;
            if( !this.value ){
                this.inputValue = "+" + value.replace("+","");
            }
            else if(this.value  && this.updateExtentionCount > 2){
                this.inputValue = "+" + value.replace("+","");
            }
        },
        "inputValue": function(){
            this.inputHasError = false;
            this.wrong_phone_format = false;
        },
        "hasError": function(value){
            if(value == true){
                this.inputHasError = true
            }
            else{
                this.inputHasError = false
            }
        }
    },
    methods:{
        getPhoneCountry( event, updated ){
            if(this.validateProp == false){
                return;
            }
            if( updated ){
                this.updated.status = true;
            }
             let tempInputValue =  event.target.value;
            if(tempInputValue.startsWith("00")){
                tempInputValue = tempInputValue.replace("00","");
            }
            if(tempInputValue.startsWith("+")){
               tempInputValue = tempInputValue.replace("+","");
            }
            /** Extract Phone Extention */
            let defaultCountry = this.countries.find(
                country => country.value !== "" && tempInputValue.startsWith(country.value)
            );
            if( defaultCountry && defaultCountry.value){
                this.countryFlag = defaultCountry.flag;
                this.phoneExtension = defaultCountry.value;
                return;
            }
        },
        emitPhonenumber(event){
            if(this.validateProp == false){
                this.$emit("inputPhoneNumber", this.inputValue);
                return;
            }
            if(event.target.value == ""){
                this.inputHasError = true;
                return 0;
            }
            if( !global.util.isValidPhoneNumber( event.target.value ) || event.target.value.legnth > 25){
                this.wrong_phone_format = true;
                return 0;
            }
            if(this.phoneExtension == ""){
                this.getDefaultPhoneExtention();
            }
            this.inputValue = this.getCorrectPhoneFormat(event.target.value);
            this.$emit("inputPhoneNumber", this.inputValue);
        },
        getCountries(){
            return Country.getAllCountries().map( country => {
                return{
                    text: `${country.flag} ${country.name}`,
                    value: country.phonecode.replace("+",""),
                    flag: country.flag
                }
            });
        },
        getDefaultPhoneExtention(){
            if(this.$props.value !== "" && this.$props.value !== undefined){
                if(this.$props.value.startsWith("+")){
                    this.inputValue = this.sliptFirstOccurrence(this.$props.value, "+");
                }
                else if(this.$props.value.startsWith("00")){
                    this.inputValue = this.sliptFirstOccurrence(this.$props.value, "00");
                }
                else{
                    this.getDefaultPhoneExtentionByLocation();
                    return 0;
                }
                /** Extract Phone Extention */
                let defaultCountry = this.countries.find(country => this.inputValue.startsWith(country.value));
                if( defaultCountry && defaultCountry.value){
                    this.phoneExtension = defaultCountry.value;
                    this.countryFlag = defaultCountry.flag;
                }
                else{
                    this.getDefaultPhoneExtentionByLocation();
                }
            }
            else{
                this.getDefaultPhoneExtentionByLocation(); 
            }
        },
        getDefaultPhoneExtentionByLocation(){
            if( typeof global.util.userLocation === "object"){
                this.phoneExtension = global.util.userLocation.phonecode;
                this.countryFlag = global.util.userLocation.flag;
            }
            else{
                return 0;
            }
        },
         sliptFirstOccurrence(stringValue, splitChar ){
            const [first, ...rest] = stringValue.split(splitChar);
            const remainder = rest.join(splitChar);
            return remainder;
        },
        getCorrectPhoneFormat(inputPhoneNumber){
            let phonenumber = ""
            if(inputPhoneNumber.startsWith("+")){
                phonenumber = this.sliptFirstOccurrence(inputPhoneNumber, "+");
            }
            else if(inputPhoneNumber.startsWith("00")){
                phonenumber = this.sliptFirstOccurrence(inputPhoneNumber, "00");
            }
            if( phonenumber !== "" ){
                let defaultCountryCode = this.countries.find(country => phonenumber.startsWith(country.value));
                if(defaultCountryCode){
                    phonenumber = "+"+phonenumber;
                }
            }
            else{
                if(inputPhoneNumber.startsWith("0")){
                    phonenumber = "+"+ this.phoneExtension.replace("+","") + this.sliptFirstOccurrence(inputPhoneNumber, "0");
                }
                else{
                    let defaultCountryCode = this.countries.find(country => inputPhoneNumber.startsWith(country.value));
                    if(defaultCountryCode){
                        phonenumber = "+"+inputPhoneNumber;
                    }
                    else{
                        phonenumber = "+" + this.phoneExtension.replace("+","") + inputPhoneNumber;
                    }
                }
            }
            return phonenumber;
        }
    }
}
</script>

<style lang="scss" scoped>

</style>