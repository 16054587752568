<template>
  <div class="signup relative">
    <signingFormBase>
      <spinning-loader v-if="!tegsoftFormData.dataPopulated" />
      <MDBCol class="flex" v-if="tegsoftFormData.dataPopulated">
        <div class="self-stretch">
          <signupSidebar
            v-if="skipSidebarOnCurrentScreen() == false"
            :parentData="$data"
          />
        </div>
        <div class="relative flex flex-col flex-1 justify-center">
          <div v-if="signup.showLoader" class="loaderAbsolute">
            <spinning-loader />
          </div>

          <!-- <keep-alive> -->
          <language
            v-if="
              navigation.currentScreen == '' ||
              navigation.currentScreen == 'lamguagge'
            "
            :parentData="$data"
          />

          <tegsoft-privacy
            v-if="navigation.currentScreen == 'tegsoft-privacy'"
            :parentData="$data"
          />
          <owner-info
            v-if="
              navigation.currentScreen == 'owner-info' ||
              navigation.currentScreen == 'verify-email' ||
              navigation.currentScreen == 'verify-phone'
            "
            :parentData="$data"
          />
          <business-info
            v-if="navigation.currentScreen == 'business-info'"
            :parentData="$data"
          />
          <verify-website
            v-if="navigation.currentScreen == 'verify-website'"
            :parentData="$data"
          />
          <region-info
            v-if="navigation.currentScreen == 'region-info'"
            :parentData="$data"
          />

          <!-- <verify-details></verify-details> -->
          <!-- </keep-alive> -->
        </div>
      </MDBCol>
    </signingFormBase>
  </div>
</template>

<script>
import signingFormBase from "../../../components/signingFormBase.vue";
import BusinessInfo from "./business-info.vue";
import Language from "./language.vue";
// import VerifyModal from './modals/verifyModal.vue';
import OwnerInfo from "./owner-info.vue";
import VerifyWebsite from "./verify-website.vue";
import RegionInfo from "./region-info.vue";
import SignupSidebar from "./signup-sidebar.vue";
import TegsoftPrivacy from "./tegsoft-privacy.vue";
// import VerifyDetails from './verify-details.vue';

export default {
  name: "signup",
  components: {
    signingFormBase,
    SignupSidebar,
    Language,
    TegsoftPrivacy,
    OwnerInfo,
    BusinessInfo,
    VerifyWebsite,
    RegionInfo,
  },
  name: "signup",
  data() {
    return {
      signup: {
        showLoader: false,
      },
      navigation: { currentScreen: "" },
      verifyEmail: { status: false },
      verifyPhone: { status: false, visited: false },
      businessFormGroup: {
        name: { value: "", required: true, hasError: false },
        category: { value: "", required: true, hasError: false },
        website: {
          value: "",
          prevValue: "",
          valueChanged: false,
          required: true,
          hasError: false,
        },
        tax_number: { value: "", required: true, hasError: false },
        privacy_and_policy: { value: "", required: false, hasError: false },
      },
      verificationKey: {
        DNS: "",
        HTML: "",
        EMAIL: "",
      },
      ...this.$tegsoftForm.data(),
    };
  },
  mounted() {
    this.getLanguage();
    this.$tegsoftForm.onFormLoad(this);
    this.goToRequiredScreen();
  },
  methods: {
    goToRequiredScreen() {
      if (
        global.util.loginServiceName == "LoginGoogle" ||
        global.util.loginServiceName == "LoginFacebook"
      ) {
        this.navigation.currentScreen = "business-info";
        return;
      }
      if (global.util.verifiyEmail) {
        this.verifyEmail.status = true;
        this.navigation.currentScreen = "verify-email";
        return;
      }
      if (
        global.util.isNotNull(global.util.user) &&
        global.util.user.phoneVerificationStatus == "100"
      ) {
        this.verifyPhone.status = true;

        this.navigation.currentScreen = "verify-phone";
        return;
      }
      if (global.util.isNotNull(global.util.user)) {
        this.navigation.currentScreen = "business-info";
        return;
      }
    },
    skipSidebarOnCurrentScreen() {
      if (this.navigation.currentScreen == "") {
        return true;
      }
      let screensToSkip = ["language", "business", "tegsoft-privacy"];
      return screensToSkip.includes(this.navigation.currentScreen);
    },
    getLanguage() {
      let temp = "";
      const urlParams = new URLSearchParams(window.location.search);
      const localeParam = urlParams.get("locale");
      if (localeParam) {
        temp = localeParam;
        cookie.setCookie("TOBE_LOCALE", temp);
      } else if (cookie.getCookie("TOBE_LOCALE")) {
        temp = cookie.getCookie("TOBE_LOCALE");
      } else {
        temp = navigator.language.split("-")[0];
      }
      global.util.language = temp;
    },
  },
};
</script>

<style lang="scss">
</style>