<template>
  <div class="tegsoft-grid-item border p-0">
    <spinning-loader
      v-if="
        companyChannels !== undefined &&
        companyChannels.engineStatistics == undefined
      "
    />
    <MDBRow v-else-if="companyChannels == undefined">
      <MDBCol col="12">
        <h4 class="p-3 px-0 font-bold">
          {{ locale.REACH_CUSTOMERS_WITH_SOCIALMEDIA }}
        </h4>
        <p class="pb-3">
          {{ locale.THERE_PEOPLE_USING_SOCIALMEDIA }}
        </p>
      </MDBCol>
      <MDBCol col="12" class="p-0">
        <MDBRow class="company-channel__items-list">
          <MDBCol col="4">
            <div @click="addChannel" class="company-channel__add-item flex">
              <div class="img-wrap py-3">
                <img
                  :src="require('@/assets/icons/userconsole/messenger.png')"
                  alt="fb"
                />
              </div>
              <div class="company-channel__add-channel p-3">
                {{ locale.ADD_FACEBOOK_MESSENGER }}
              </div>
            </div>
          </MDBCol>
          <MDBCol col="4">
            <div @click="addChannel" class="company-channel__add-item flex">
              <div class="img-wrap py-3">
                <img
                  :src="require('@/assets/icons/userconsole/whatsapp.png')"
                  alt="wa"
                />
              </div>
              <div class="company-channel__add-channel p-3">
                {{ locale.ADD_WHATSAPP_MESSEGER }}
              </div>
            </div>
          </MDBCol>
          <MDBCol col="4">
            <div @click="addChannel" class="company-channel__add-item flex">
              <div class="img-wrap py-3">
                <img
                  :src="require('@/assets/icons/userconsole/instagram.png')"
                  alt="ig"
                />
              </div>
              <div class="company-channel__add-channel p-3">
                {{ locale.ADD_INSTAGRAM }}
              </div>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBCol>
    </MDBRow>
    <MDBRow v-else>
      <h4 class="p-3 font-bold">{{ locale.CUSTOMERS }}</h4>
      <MDBCol col="6" class="text-center">
        <div>{{ locale.INBOUND }}</div>
        <div><img scr="" alt="" /></div>
        <div class="large-text">{{ "0" }}</div>
      </MDBCol>
      <MDBCol col="6" class="text-center">
        <div>{{ locale.OUTBOUND }}</div>
        <div><img scr="" alt="" /></div>
        <div class="large-text">{{ "0" }}</div>
      </MDBCol>

      <MDBCol col="6" class="text-center">
        <div>Web Chat</div>
        <div class="icon">
          <img
            :src="require('@/assets/icons/userconsole/webchat.png')"
            alt=""
          />
        </div>
        <div class="large-text">
          {{ companyChannels.engineStatistics.rows[0].WEBCHAT }}
        </div>
      </MDBCol>
      <MDBCol col="6" class="text-center">
        <div>Messenger</div>
        <div class="icon">
          <img
            :src="require('@/assets/icons/userconsole/messenger.png')"
            alt="inbound"
          />
        </div>
        <div class="large-text">
          {{ companyChannels.engineStatistics.rows[0].FACEBOOK }}
        </div>
      </MDBCol>
      <MDBCol col="6" class="text-center">
        <div>Whatsapp</div>
        <div class="icon">
          <img
            :src="require('@/assets/icons/userconsole/whatsapp.png')"
            alt=""
          />
        </div>
        <div class="large-text">
          {{ companyChannels.engineStatistics.rows[0]?.WHATSAPP }}
        </div>
      </MDBCol>
      <MDBCol col="6" class="text-center">
        <div>Instagram</div>
        <div class="icon">
          <img
            :src="require('@/assets/icons/userconsole/instagram.png')"
            alt=""
          />
        </div>
        <div class="large-text">
          {{ companyChannels.engineStatistics.rows[0].INSTAGRAM }}
        </div>
      </MDBCol>
      <MDBCol col="12">
        <div class="p-3 small-text">
          {{ locale.PASTPERFORMANCE }}
          <!-- Performance over the past 28 days -->
        </div>
      </MDBCol>
    </MDBRow>
  </div>
</template>

<script>
export default {
  name: "company-channels",
  props: ["parentData"],
  data() {
    return {
      ...this.$props.parentData,
      companyChannels: [],
      campaignData: {},
      chatsSectionActive: true,
    };
  },
  created() {
    this.getCompanyChannels();
    this.getRealTimeCampaignData();
  },
  methods: {
    addChannel() {
      this.$router.push("/catalog");
    },
    getCompanyChannels() {
      global.util
        .get(
          this.$tegsoftConfig.TouchCompanyChannels +
            global.util.currentCompany.COMPID
        )
        .then((response) => {
          this.companyChannels = response.data.result;
        })
        .catch((ex) => {
          this.companyChannels = [];
        });
    },
    getRealTimeCampaignData() {
      // global.util
      // .get( this.$tegsoftConfig.RealTimeCampaignData )
      // .then( response => {
      //     this.campaignData = response.data;
      // })
    },
  },
};
</script>

<style lang="scss" scoped>
</style>