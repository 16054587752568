<template>
<div>
    <div>
        <img :src="require('@/assets/icons/email-sent.png')" alt="email verification" class="m-auto my-3 inline-block" />
    </div>
    <h2 class=""> {{ locale.VERIFY_EMAIL }}</h2>
    <MDBCol md="9" class="m-auto py-3">
        <p class="text-center" v-html="formatMessage(locale.VERIFYEMAIL, formGroup.email.value)">
        </p>
        <p v-if="emailVerificationCode.resendError" class="unknown-error">{{ locale.SOMETHING_WENT_WRONG }}</p>
        <label for="email-code" class="form-label"> {{ locale.ENTER_CODE }} </label>
        <MDBInput
          inputGroup
          aria-autocomplete="none"
          :formOutline="false"
          v-model="emailVerificationCode.value"
          id="email-code"
          :class="{'invalid': emailVerificationCode.hasError}"
          :onkeypress="onlyNumberKey"
        />
        <input-error-message 
          v-if="emailVerificationCode.hasError && !emailVerificationCode.invalidCode"
          :message="locale.EMAIL_CODE_REQUIRED"
        />
        <input-error-message 
          v-if="emailVerificationCode.hasError && emailVerificationCode.invalidCode"
          :message="locale.WRONG_CODE"
        />
        <div class="py-4 flex justify-between">

            <div class="link resend-code" :class="{'resend-code__sent': emailVerificationCode.resend}" 
                @click="resendCode"> {{ emailVerificationCode.resend ? locale.CODE_SENT +'!':locale.RESEND_CODE  }}</div>
            <MDBBtn color="primary" @click="verifyEmailCode()"> {{ locale.VERIFY }}</MDBBtn>
        </div>
    </MDBCol>
</div>
</template>

<script>
export default {
    props:[ "parentData", "actionName"],
    data(){
        return{
            ...this.parentData,
        }
    },
    watch:{
        "showEmailVerificationModal": function(){
            this.emailVerificationCode.value = ""
            this.emailVerificationCode.hasError = false;
            this.emailVerificationCode.invalidCode = false;
        },
    },
    methods:{
        resendCode(){
            if(this.emailVerificationCode.resend){
                return;
            }
            this.signup.showLoader = true;
            global.util.get( this.$tegsoftConfig.TouchSignupResendEmailCode + global.util.user.user_id)
            .then( response =>{
                this.signup.showLoader = false;
                if(response.data.success){
                    this.emailVerificationCode.resend=true;
                    setTimeout(()=>{
                         this.emailVerificationCode.resend=false;
                    }, 5000);
                }
                else{
                    this.emailVerificationCode.resendError = true;
                    setTimeout(()=>{
                         this.emailVerificationCode.resendError = false;
                    }, 8000);
                }
            })
        },
        formatMessage(str, arr){
            if(!!arr){
                return str.replace(/{.*}/, "<code>"+arr+"</code>")
            }
            return str.replace(/{.*}/, "<code>"+global.util.userEmail+"</code>") 
        },
        verifyEmailCode(){
            if(this.emailVerificationCode.value != "" ){
                this.$emit('verifyEmail');
            }
        },
        onlyNumberKey(event) {
            // Only ASCII character in the range allowed
            return event.charCode >= 48 && event.charCode <= 57;
        }
    }
}
</script>

<style lang="scss" scoped>

</style>