import Userconsole from "@/forms/TegsoftTouchWeb/userconsole/userconsole";
import Dashboard from "@/forms/TegsoftTouchWeb/userconsole/dashboard/dashboard";
import Signup from "@/forms/TegsoftTouchWeb/signup/signup";
import Login from "@/forms/TegsoftTouchWeb/login/login";

const tegsoftRoutes = [
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },

  {
    path: "/logout",
    name: "Logout",
    component: Login,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/",
    name: "Initial",
    component: Userconsole,
    children: [
      {
        path: "/",
        component: Dashboard,
      },
      {
        path: "/profile",
        name: "Profile",
        component: () => {
          return import(
            "@/forms/TegsoftTouchWeb/userconsole/profile/profile.vue"
          );
        },
        props: true,
      },
      {
        path: "/catalog",
        name: "Catalog",
        component: () => {
          return import("@/forms/TegsoftTouchWeb/userconsole/catalog/catalog");
        },
      },
      {
        path: "/whatsapp",
        name: "Whatsapp",
        component: () => {
          return import(
            "@/forms/TegsoftTouchWeb/userconsole/whatsapp/whatsapp"
          );
        },
        props: true,
      },
      {
        path: "/users",
        name: "Users",
        component: () => {
          return import("@/forms/TegsoftTouchWeb/userconsole/users/users");
        },
        props: true,
      },
      {
        path: "/platforms",
        name: "Platforms",
        component: () => {
          return import(
            "@/forms/TegsoftTouchWeb/userconsole/platforms/platforms"
          );
        },
        props: true,
      },
      {
        path: "/salespipeline",
        name: "SalesPipeline",
        component: () => {
          return import(
            "@/forms/TegsoftTouchWeb/userconsole/salespipeline/salespipeline"
          );
        },
        props: true,
      },
    ],
  },
  {
    path: "/userconsole",
    redirect: "/",
  },
  {
    path: "/dashboard",
    redirect: "/",
  },
  {
    path: "/salespipeline",
    redirect: "/",
  },
];

export default tegsoftRoutes;
