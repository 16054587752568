<template>
  <div :class="navTabClassName">
    <MDBTabs v-model="activeTabId">
      <MDBTabNav tabsClasses="mb-3">
        <slot name="tabNavs" />
      </MDBTabNav>
      <MDBTabContent>
        <slot name="tabContent" />
      </MDBTabContent>
    </MDBTabs>
  </div>
</template>

<script>
import { MDBTabs, MDBTabNav, MDBTabContent } from "mdb-vue-ui-kit";
export default {
  name: "tegsoftNavTabs",
  props: ["parentData", "navTabClassName", "defaultActiveTab", "modelValue"],
  /** props.navTabClassName:  can be tg-main-tab, tg-primary-tab, and tg-secondary-tab and sub-tab.
   * This vaule is used as class name */
  components: {
    MDBTabs,
    MDBTabNav,
    MDBTabContent,
  },
  data() {
    return {
      activeTabId: "",
      ...this.$props.parentData,
    };
  },
  mounted() {
    this.activeTabId = this.$props.defaultActiveTab;
  },
  watch: {
    modelValue: {
      handler: function (value) {
        this.activeTabId = value;
      },
      immediate: true,
    },
    activeTabId: {
      handler: function (value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
