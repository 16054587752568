<template>
  <div class="signup-sidebar py-4 px-2 h-full flex flex-col">
    <div class="signup-sidebar__inner flex flex-col justify-center my-auto">
      <div
        :class="{
          active: navigation.currentScreen == 'owner-info',
          complete: ownerInfoExists || emailIsVerified,
        }"
        class="sidebar__item"
      >
        <span>1</span>
        <div class="flex-1">
          {{ isBusinessSignup != false ? locale.OWNER_INFO : locale.CONTACT }}
        </div>
      </div>
      <div
        :class="{
          active: navigation.currentScreen == 'verify-email',
          complete: emailIsVerified,
        }"
        class="sidebar__item"
      >
        <span>2</span>
        <div class="flex-1">{{ locale.VERIFY_EMAIL }}</div>
      </div>
      <div
        :class="{
          active: navigation.currentScreen == 'verify-phone',
          complete: phoneIsVerified,
        }"
        class="sidebar__item"
      >
        <span>3</span>
        <div class="flex-1">{{ locale.VERIFYPHONE }}</div>
      </div>
      <div
        v-if="isBusinessSignup != false"
        :class="{
          active: navigation.currentScreen == 'business-info',
          complete: businessInfoExists,
        }"
        class="sidebar__item"
      >
        <span>4</span>
        <div class="flex-1">{{ locale.BUSINESS_INFO }}</div>
      </div>
      <div
        v-if="isBusinessSignup != false"
        :class="{
          active: navigation.currentScreen == 'verify-website',
          complete: websiteIsVerified,
        }"
        class="sidebar__item"
      >
        <span>5</span>
        <div class="flex-1">{{ locale.VERIFYWEBSITE }}</div>
      </div>
      <div
        v-if="isBusinessSignup != false"
        :class="{
          active: navigation.currentScreen == 'region-info',
          complete: websiteIsVerified,
        }"
        class="sidebar__item"
      >
        <span>6</span>
        <div class="flex-1">{{ locale.REGION_INFO }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["parentData"],
  data() {
    return {
      currentScreenItem: "",
      isBusinessSignup: true,
      ...this.$props["parentData"],
    };
  },
  mounted() {
    const localSignupOptions = localStorage.getItem("signupOptions");
    if (global.util.isNotNull(localSignupOptions)) {
      if (localSignupOptions.includes("business=false")) {
        this.isBusinessSignup = false;
      }
    }
  },
  watch: {
    "parentData.currentScreen": function (newValue) {
      this.currentScreenItem = newValue;
    },
  },
  computed: {
    ownerInfoExists: function () {
      if (!!global.util.user) {
        return true;
      }
      return false;
    },
    emailIsVerified: function () {
      if (
        global.util.loginServiceName == "LoginGoogle" ||
        global.util.loginServiceName == "LoginFacebook"
      ) {
        return true;
      }
      if (
        this.navigation.currentScreen !== "owner-info" &&
        this.navigation.currentScreen !== "verify-email"
      ) {
        return true;
      }
      return false;
    },
    phoneIsVerified: function () {
      if (
        !!global.util.user &&
        typeof global.util.user.phoneVerificationStatus !== "undefined" &&
        global.util.user.phoneVerificationStatus == "mobile"
      ) {
        return true;
      }
      return false;
    },
    businessInfoExists: function () {
      if (
        this.navigation.currentScreen === "verify-website" ||
        this.navigation.currentScreen === "region-info"
      ) {
        return true;
      }
      return false;
    },
    websiteIsVerified: function () {
      if (this.navigation.currentScreen === "region-info") {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.signup-sidebar {
  width: 20rem;
  text-align: left;
  margin-right: 1.5rem;
  border-right: 1px solid #e5e5e5;
  font-size: 1.2rem;
  // @media (max-width: 767px) {
  //   margin-right: 2rem;
  // }
  .sidebar__item {
    display: flex;
    align-items: center;
    pointer-events: none;
    line-height: 1.6;
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
    &.active {
      font-weight: bold;
      span {
        background: rgba(#64bf50, 0.3);
        border: 1px solid #64bf50;
        color: #64bf50;
      }
    }
    &.removeActive {
      font-weight: 400 !important;
      span {
        background: var(--color-white) !important;
        border: 1px solid var(--color-gray-2) !important;
        color: var(--color-gray-3) !important;
      }
    }
    &.complete {
      span {
        background: #64bf50;
        border: 1px solid #64bf50;
        color: #ffffff;
      }
    }
    &:hover {
      color: var(--color-gray-3);
    }
  }
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2px;
    margin-right: 1rem;
    height: 2.2rem;
    width: 2.2rem;
    border-radius: 50%;
    border: 1px solid var(--color-gray-2);
  }
}
.signup-sidebar__inner {
  // transform: translate(0px, 10rem);
}
</style>