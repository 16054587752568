<template>
  <form @submit.prevent="onsubmit" class="business-info">
    <h3 class="heading-3">{{ locale.CONGRATES }}</h3>
    <h4 class="heading-4">
      {{ locale.REGISTRY_BUSINESS_SUBTITLE }}
    </h4>
    <p class="paragraph text-center">{{ locale.ENTER_BUSINESS_INFO }}</p>
    <MDBCol col="12" md="10" class="m-auto mb-6">
      <MDBRow>
        <MDBCol col="12" md="6" class="text-left">
          <label for="name" class="form-label"> {{ locale.COMPANY_NAME }} </label>
          <MDBInput
            inputGroup
            :formOutline="false"
            v-model="businessFormGroup.name.value"
            id="name"
            :class="{'invalid': businessFormGroup.name.hasError || businessNameIsTaken}"
            @blur="handleBusinessName"
          />
        </MDBCol>
        <MDBCol col="12" md="6">
          <label for="category" class="form-label"> {{ locale.CATEGORY }} </label>
          <combobox
            v-model="businessFormGroup.category.value"
            :options="businessCategories"
            value-field="name"
            text-field="category_id"
            :clearButton="false"
            :class="{'is-invalid': businessFormGroup.category.hasError}"
          />
          <input-error-message 
            v-if="businessFormGroup.category.hasError
              && !businessFormGroup.name.hasError && !businessNameIsTaken"
            :message="locale.SELECT_CATEGORY"
          />
        </MDBCol>
        <div class="text-left">
          <input-error-message 
            v-if="businessFormGroup.name.hasError"
            :message="locale.NAME_REQUIRED"
          />
          <input-error-message 
            v-if="businessNameIsTaken"
            :message="locale.BUSINESS_NAME_TAKEN"
          />
          <a v-if="businessNameIsTaken" 
            href='mailto:info@tegsoft.com' 
            class="btn btn-light">{{ locale.BUSINESS_CLAIM }}</a>
        </div>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <label for="website" class="form-label"> {{ locale.WEBSITE_URL  }}</label>
          <MDBInput
            inputGroup
            :formOutline="false"
            v-model="businessFormGroup.website.value"
            id="website"
            :class="{'invalid': businessFormGroup.website.hasError || businessDomainNameIsTaken}"
            @blur="handleBusinessDomain"
          >
            <template #prepend>
              <span class="input-group-text">https://</span>
            </template>
          </MDBInput>
          <input-error-message 
            v-if="businessFormGroup.website.hasError"
            :message="locale.INVALID_WEBSITE_URL"
          />
          <input-error-message 
            v-if="businessDomainNameIsTaken"
            :message="locale.BUSINESS_WEBSITE_TAKEN"
          />
          <div class="text-left">
            <a v-if="businessDomainNameIsTaken" 
              href='mailto:info@tegsoft.com' 
              class="btn btn-light">{{ locale.BUSINESS_CLAIM }}</a>
          </div>
          <div v-if="!businessDomainNameIsTaken" class="input-note">
            {{ locale.VERIFY_WEBSITE_NEXT_STEP }}
          </div>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol col="12">
          <label for="tax-number" class="form-label">{{ locale.TAXNUMBER }}</label>
          <MDBInput
            inputGroup
            :formOutline="false"
            v-model="businessFormGroup.tax_number.value"
            id="tax-number"
            :class="{'invalid': businessFormGroup.tax_number.hasError || wrong_tax_number_format}"
            :onkeypress="onlyNumberKey"
          />
          <input-error-message 
            v-if="businessFormGroup.tax_number.hasError || wrong_tax_number_format"
            :message="locale.INVALID_TAXNUMBER"
          />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol class="flex pt-5">
          <div class="ml-auto">
            <MDBBtn :disabled="businessNameIsTaken || businessDomainNameIsTaken" 
            class="btn-primary" type="submit">{{ locale.NEXT }}</MDBBtn>
          </div>
        </MDBCol>
      </MDBRow>
      <!-- </div> -->
    </MDBCol>

  </form>

  <div class="text-left">
    <div class="link p-2 inline-block" 
      @click="logout">
        {{ locale.LOGOUT }}
    </div>
  </div>
</template>

<script>
export default {
  props:["parentData"],
  data(){
    return{
      ...this.$props["parentData"],
      businessNameIsTaken: false,
      businessDomainNameIsTaken:false,
      businessCategories:"",
      wrong_tax_number_format: false,
    }
  },
  mounted(){
    this.getCategories();  
  },
  watch:{
    "businessFormGroup.name.value": function(){
      this.businessFormGroup.name.hasError = false;
      this.businessNameIsTaken = false;
    },
    "businessFormGroup.category.value": function(){
      this.businessFormGroup.category.hasError = false;
    },
    "businessFormGroup.website.value": function(newVlaue, oldValue){
      this.businessFormGroup.website.hasError = false;
      this.businessDomainNameIsTaken = false;
    },
    "businessFormGroup.tax_office.value": function(){
      this.businessFormGroup.tax_office.hasError = false;
    },
    "businessFormGroup.tax_number.value": function(){
      this.businessFormGroup.tax_number.hasError = false;
      this.wrong_tax_number_format = false;
    }
  },
  methods:{
    logout(){
      global.util.touchLogout();
    },
    getCategories(){
      global.util.get(this.$tegsoftConfig.TouchCompanyCategories)
      .then( response => {
        this.businessCategories = response.data.result.categories
      })
    },
    async handleBusinessName(){
      if( this.businessFormGroup.name.value == "" ){
        this.businessFormGroup.name.hasError = true;
        return;
      }
      await global.util.put(this.$tegsoftConfig.TouchCheckBusinessName + this.businessFormGroup.name.value)
      .then(response => {
        if(response.data.success == false && response.data.errorCode == "Company/BUS003"){
          this.businessNameIsTaken = true;
        }
      })
    },
    async handleBusinessDomain(){
      if( this.businessFormGroup.website.value == "" ){
        this.businessFormGroup.website.hasError = true,
        this.website_dont_match = true;
        return;
      }
       //Check website format
      if( this.businessFormGroup.website.value !== "" ){
        let validWebsiteURL = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g
          .test(this.businessFormGroup.website.value);
        if( !validWebsiteURL ){
          this.businessFormGroup.website.hasError = true,
          this.website_dont_match = true;
          return;
        }
      }
      this.businessFormGroup.website.value = this.businessFormGroup.website.value.replace(/^(https?|http):\/\//,'')
      await global.util.put(this.$tegsoftConfig.TouchCheckDomainName + 
        `https://${this.businessFormGroup.website.value}`)
      .then(response => {
        if(response.data.success == false && response.data.errorCode == "Company/BUS013"){
          this.businessDomainNameIsTaken = true;
        }
      })
    },
    onsubmit(){
      let formIsValid = true;
      let businessFormGroupToArray = Object.keys(this.businessFormGroup);
      for( let key in this.businessFormGroup){
        //Check for empty input fields
        if( this.businessFormGroup[key].required && this.businessFormGroup[key].value == "" ){
          this.businessFormGroup[key].hasError = true;
          formIsValid = false;
        }
        if( businessFormGroupToArray.indexOf( key ) + 1 == businessFormGroupToArray.length && formIsValid  ) {
          //Check website format
          if( this.businessFormGroup.website.value !== "" ){
            let validWebsiteURL = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g
              .test(this.businessFormGroup.website.value);
            if( !validWebsiteURL ){
              this.businessFormGroup.website.hasError = true,
              this.website_dont_match = true;
              formIsValid = false;
            }
          }
          //Check tax number format
          if( !global.util.isValidPhoneNumber( this.businessFormGroup.tax_number.value )){
            this.businessFormGroup.tax_number.hasError = true,
            this.wrong_tax_number_format = true;
            formIsValid = false;
          }
          if( formIsValid == false){
            return false;
          }
        }
      }
      if( formIsValid ){
          if(this.businessFormGroup.website.prevValue != this.businessFormGroup.website.value){
            this.businessFormGroup.website.valueChanged = true;
            this.businessFormGroup.website.prevValue = this.businessFormGroup.website.value;
          }
          // this.navigation.currentScreen = "region-info";
          this.navigation.currentScreen = "verify-website";
      }
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },
    onlyNumberKey(event) {
      // Only ASCII character in the range allowed
      return event.charCode >= 48 && event.charCode <= 57;
    }
  }
};
</script>

<style lang="scss" scoped>
.btn-light{
  border: 1px solid #bdbdbd;
  font-size: 1.2rem;
  height: 2.4rem;
  padding: 2px 10px;
  &:hover{
    border-color: var(--color-primary);
  }
}
</style>