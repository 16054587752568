<template>
  <MDBToast
    v-model="showToastLocal"
    autohide
    :delay="delay ?? 3000"
    :position="position ?? 'top-right'"
    appendToBody
    stacking
    :width="width ?? '350px'"
    :toast="toast"
  >
    <template #title>
      <slot name="title"></slot>
    </template>
    <slot></slot>
  </MDBToast>
</template>

<script>
export default {
  props: ["showToast", "toast", "position", "width", "delay"],
  data() {
    return {
      showToastLocal: false,
    };
  },
  watch: {
    showToast(value) {
      this.showToastLocal = value;
    },
  },
};
</script>

<style>
</style>