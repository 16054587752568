<template>
  <MDBSelect
    ref="selectComponet"
    :label="placeholder"
    :preselect="false"
    :clearButton="clearButton"
    v-model:options="mappedOptions"
    :filter="filter"
    :tabindex="tabindex"
    @change="onValueChange()"
    @clear="onClear()"
    :style="style"
    :searchPlaceholder="searchPlaceholder || 'Search'"
  />
</template>

<script>
export default {
  name: "combobox",
  props: {
    placeholder: "",
    modelValue: null,
    options: {},
    valueField: { default: "CODE" },
    textField: { default: "VALUE" },
    style: "",
    tabindex: "",
    filter: { default: true },
    clearButton: { default: true },
    searchPlaceholder: "",
  },
  emits: ["update:modelValue", "change"],
  computed: {
    mappedOptions() {
      let mappedOptions = this.getMappedOptions();
      if (mappedOptions == undefined) {
        return [];
      }
      return mappedOptions;
    },
  },
  methods: {
    getSelectedValue() {
      var selectedOption = undefined;

      if (this.$attrs.multiple == undefined) {
        selectedOption = this.mappedOptions.find((option) => {
          return option.selected === true;
        });

        if (selectedOption) {
          return selectedOption.value;
        }

        return "__NA__";
      }

      selectedOption = this.mappedOptions.filter((option) => {
        return option.selected === true;
      });

      if (selectedOption) {
        let selectedOptions = [];
        for (const selectedOptionElement of selectedOption) {
          selectedOptions.push(selectedOptionElement.value);
        }

        return selectedOptions;
      }

      return "__NA__";
    },

    onValueChange() {
      var selectedValue = this.getSelectedValue();

      if (selectedValue == "__NA__") {
        return false;
      }

      if (selectedValue == this.modelValue) {
        return false;
      }

      if (
        selectedValue == "" ||
        selectedValue == null ||
        typeof selectedValue == "undefined" ||
        selectedValue == "undefined"
      ) {
        if (
          this.modelValue == "" ||
          this.modelValue == null ||
          typeof this.modelValue == "undefined" ||
          this.modelValue == "undefined"
        ) {
          return false;
        }
      }

      this.$emit("update:modelValue", selectedValue);
      this.$emit("change", selectedValue);
    },
    onClear() {
      var selectedValue = "";

      if (
        this.modelValue == "" ||
        this.modelValue == null ||
        typeof this.modelValue == "undefined" ||
        this.modelValue == "undefined"
      ) {
        return false;
      }

      this.$emit("update:modelValue", selectedValue);
    },
    getMappedOptions() {
      return this.$props.options?.map((option) => {
        let textFieldNames = this.$props.textField.split(",");
        let textValue = "";
        let i = 0;
        for (i = 0; i < textFieldNames.length; i++) {
          textValue += option[textFieldNames[i]] + " ";
        }
        return {
          text: textValue,
          value: option[this.$props.valueField],
          disabled: option.disabled,
          optgroup: option.optgroup,
          selected:
            this.$attrs.multiple == undefined
              ? typeof this.$props["modelValue"] != "undefined" &&
                option[this.$props.valueField] == this.$props["modelValue"]
              : typeof this.$props["modelValue"] != "undefined" &&
                Array.isArray(this.$props["modelValue"]) &&
                this.$props["modelValue"].includes(
                  option[this.$props.valueField]
                ),
        };
      });
    },
  },
};
</script>
